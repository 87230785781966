import { CircularProgress } from "@rmwc/circular-progress";
import "@rmwc/circular-progress/styles";
import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import { ThemeProvider } from "@rmwc/theme";
import "@rmwc/theme/styles";
import dayjs from "dayjs";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { Route } from "react-router";
import { useHistory, useRouteMatch } from "react-router-dom";
import { generateTimesheetsCSV } from "../../common";
import { Button } from "../../components/Button";
import { DataCellTools, Table, TableContainer, TableHead, TableTitle } from "../../components/Table";
import { TimesheetReviewDialog } from "../../components/timesheets/TimesheetReviewDialog";
import { TimesheetActivityEditDialog } from "../../components/timesheets/TimesheetActivityEditDialog";
import { useAdminApi } from "../../hooks/useAdminApi";
import useLayout from "../../hooks/useLayout";
//import styled from "styled-components";
import Palette from "../../palette.json";
import { TimeSheetEntryStatus } from "../../types/admin/globalTypes";
import { TimesheetFields } from "../../types/admin/TimesheetFields";
import { TextInput } from "../TextInput";
import { snackbar } from "../../hooks/useNotifications";
import { dialogQueue } from "../../hooks/useDialogQueue";
import { DeleteTimeSheetEntryVariables } from "../../types/admin/DeleteTimeSheetEntry";
import { UpdateTimeSheetEntryVariables } from "../../types/admin/UpdateTimeSheetEntry";

/*
const WeekRange = styled.span`
  margin-left: 0.5rem;
  font-size: 14px;
  font-weight: normal;
`;
 */

export interface Props {
  filterType?: "project" | "task";
  filterId?: string;
}

function getAmountMins(it: TimesheetFields) {
  const date1 = dayjs(it.toDT);
  const diff = date1.diff(it.fromDT, "minute", true);
  return diff;
}

export const TimesheetTable: React.FC<Props> = ({ filterType, filterId }) => {
  const history = useHistory();
  const match = useRouteMatch();

  const { setBreadCrumbs } = useLayout();

  const { projects, tasks, getTimesheets, deleteTimeSheetEntry, updateTimeSheetEntry } = useAdminApi();

  const [timesheets, setTimesheets] = useState<TimesheetFields[]>([]);
  const [merged, setMerged] = useState<TimesheetFields[]>([]);

  const [fromDate, setFromDate] = useState<dayjs.Dayjs>(dayjs().startOf("week"));
  const [toDate, setToDate] = useState<dayjs.Dayjs>(dayjs().endOf("week"));

  const [isLoading, setLoading] = useState<boolean>(true);

  let filter: (it?: TimesheetFields) => boolean = () => true;
  if (filterType === "project") {
    filter = (timesheet?: TimesheetFields) => timesheet?.projectId === filterId;
  } else if (filterType === "task") {
    filter = (timesheet?: TimesheetFields) => timesheet?.taskId === filterId;
  }

  useEffect(() => {
    if (projects.length === 0) return;
    setLoading(true);
    getTimesheets(fromDate, toDate).then((v) => {
      setTimesheets(v.filter(filter));
      setLoading(false);
    });

    if (!setBreadCrumbs) return;
    setBreadCrumbs([
      {
        label: "Timesheets",
        path: "/timesheets",
      },
      {
        label: `${fromDate.format("MMM D")} - ${toDate.format("MMM D")}`,
        path: "/timesheets",
      },
    ]);
  }, [fromDate, toDate, projects]);

  useEffect(() => {
    const results: TimesheetFields[] = [];

    timesheets.forEach((it) => {
      const fIndex = results.findIndex((jt) => jt.userId === it.userId);

      if (fIndex === -1) {
        results.push({...it});
      } else {
        const mergeWith = results[fIndex];
        mergeWith.amountMins = (mergeWith.amountMins || 0) + (it.amountMins || 0);
      }
    });

    setMerged(results);
  }, [timesheets]);

  const onWeekPrev = () => {
    setTimesheets([]);
    setFromDate((p) => p.subtract(1, "week"));
    setToDate((p) => p.subtract(1, "week"));
  };
  const onWeekNext = () => {
    setTimesheets([]);
    setFromDate((p) => p.add(1, "week"));
    setToDate((p) => p.add(1, "week"));
  };
  const gotoThisWeek = () => {
    setTimesheets([]);
    setFromDate(dayjs().startOf("week"));
    setToDate(dayjs().endOf("week"));
  };

  const doApprove = (userId: string) => {
    console.info("Approve timesheet ...");
    history.push(`${match.url}/${userId}/review`);
  };

  const onTimeSheetSave = async (input: UpdateTimeSheetEntryVariables) => {
    const updatedTimesheet = await updateTimeSheetEntry(input);
    // * update ui with latest data
    setTimesheets(timesheets.map((it) => {
      if (updatedTimesheet.id === it.id) {
        console.log('hit: ', it);
        it.fromDT = updatedTimesheet?.fromDT;
        it.toDT = updatedTimesheet?.toDT;
        it.amountMins = updatedTimesheet?.amountMins;
      }
      return it;
    }));
    snackbar.notify({ title: "Time sheet activity updated successfully!" });
  };

  const onTimeSheetDelete = async (sheet: TimesheetFields) => {
    let hasConfirmed = false;

    hasConfirmed = await dialogQueue.confirm({
      title: "Are you sure?",
      body: `Are you sure you want to delete this timesheet entry?`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
      renderToPortal: true,
    });

    if (!hasConfirmed) return;

    await deleteTimeSheetEntry(sheet as DeleteTimeSheetEntryVariables);
    snackbar.notify({ title: "Time sheet activity removed successfully!" });

    setTimesheets(timesheets.filter((t) => t.id !== sheet.id));
  };

  return (
    <>
      <Route exact path={`${match.path}/:userId/review`}>
        <TimesheetReviewDialog {...{ timesheets, isLoading }} onDelete={onTimeSheetDelete} />
      </Route>

      <Route exact path={`${match.path}/:userId/review/:activityId/edit`}>
        <TimesheetActivityEditDialog {...{ timesheets, isLoading }} onSave={onTimeSheetSave} />
      </Route>

      <TableContainer>
        <TableTitle>
          <TextInput
            type="date"
            value={fromDate.format("YYYY-MM-DD")}
            onChange={(ev) => setFromDate(dayjs(ev.target.value, "YYYY-MM-DD"))}
          />
          &nbsp;⟶&nbsp;
          <TextInput
            type="date"
            value={toDate.format("YYYY-MM-DD")}
            onChange={(ev) => setToDate(dayjs(ev.target.value, "YYYY-MM-DD"))}
          />
          {/* <IconButton icon="chevron_left" onClick={onWeekPrev} />
          <TableToolbarTitle style={{ flexGrow: 0 }}>
            Week
            <WeekRange>
              ({fromDate.format("MMM D")} - {toDate.format("MMM D")})
            </WeekRange>
          </TableToolbarTitle>
          <IconButton icon="chevron_right" onClick={onWeekNext} />

          <Button outlined onClick={gotoThisWeek}>
            This Week
          </Button> */}
          <div style={{ flexGrow: 1 }} />
          <ThemeProvider options={{ primary: Palette.Green }}>
            <Button raised icon="cloud_download" onClick={() => generateTimesheetsCSV(timesheets)}>
              Export CSV
            </Button>
          </ThemeProvider>
        </TableTitle>

        <Table>
          <DataTableContent>
            <TableHead cols={["PayChex ID", "Team Member", "Hours / Estd.", "Org. Unit", ""]}></TableHead>

            <DataTableBody data-test-id="timesheets-list">
              {merged.length === 0 && (
                <DataTableRow>
                  <DataTableCell
                    colSpan={5}
                    style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}
                  >
                    {isLoading ? <CircularProgress /> : "- No Results -"}
                  </DataTableCell>
                </DataTableRow>
              )}

              {merged.map((it, i) => (
                <DataTableRow key={i}>
                  <DataTableCell>{it.user?.payChex?.id}</DataTableCell>
                  <DataTableCell>{it.user?.name}</DataTableCell>
                  <DataTableCell>
                    {numeral(it.amountMins ? it.amountMins / 60 : 0).format("0.0")}/
                    {numeral(tasks.find((jt) => jt.id === it.taskId)?.estimateManHrs || 0).format("0.0")}
                  </DataTableCell>
                  <DataTableCell>{it.user?.payChex?.orgUnit}</DataTableCell>
                  <DataTableCell>
                    <DataCellTools>
                      <Button
                        style={{ width: "7rem" }}
                        outlined={it.status !== ("Approved" as TimeSheetEntryStatus)}
                        raised={it.status === ("Approved" as TimeSheetEntryStatus)}
                        onClick={() => it.status !== ("Approved" as TimeSheetEntryStatus) && doApprove(it.userId || "")}
                      >
                        {it.status === ("Approved" as TimeSheetEntryStatus) ? "Approved" : "Approve"}
                      </Button>
                    </DataCellTools>
                  </DataTableCell>
                </DataTableRow>
              ))}
            </DataTableBody>
          </DataTableContent>
        </Table>
      </TableContainer>
    </>
  );
};
