import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router";

import { useFirebase } from "./hooks/useFirebase";

import { Layout } from "./components/layout";
import { SignInLayout } from "./components/layout/signin";

import * as Pages from "./pages";
import { BigScreenPage } from "./pages/bigscreen";

export const Routes: React.FC = () => {
  const location = useLocation();
  const { user, isLoading } = useFirebase();

  if (isLoading) return <></>;

  // Check for logged out condition, and redirect to signin page.
  if (!user && !["/reset", "/signin"].includes(location.pathname)) {
    return <Redirect to="/signin" />;
  }

  // Sign in layout formatted routes.
  if (["/reset", "/signin"].includes(location.pathname)) {
    // We're logged in, so lets redirect to home.
    if (user) return <Redirect to="/" />;

    return (
      <SignInLayout>
        <Switch>
          <Route exact path="/reset" component={Pages.SignInResetPage} />
          <Route exact path="/signin" component={Pages.SignInPage} />
        </Switch>
      </SignInLayout>
    );
  }

  if (location.pathname === "/") return <Redirect to="/projects" />;

  // Global dashboard layout formatted routes.
  return (
    <Layout>
      <Switch>
        <Route path="/locations/:locationId?" component={Pages.LocationsPage} />

        <Route exact path={["/clients", "/clients/new"]} component={Pages.ClientsPage} />

        <Route exact path={["/clients/new", "/clients/:clientId/edit", "/clients"]} component={Pages.ClientsPage} />
        <Route
          exact
          path={[
            "/clients/:clientId/aircraft/new",
            "/clients/:clientId/aircraft/:aircraftId/edit",
            "/clients/:clientId/aircraft",
            "/clients/:clientId/people/new",
            "/clients/:clientId/people",
            "/clients/:clientId/projects/new",
            "/clients/:clientId/projects",
            "/clients/:clientId",
          ]}
          component={Pages.ClientViewPage}
        />

        <Route
          exact
          path={[
            "/projects/new",
            "/projects/:projectId",
            "/projects/:projectId/edit",
            "/projects/:projectId/attachments",
            "/projects/:projectId/messages",
            "/projects/:projectId/people",
            "/projects/:projectId/people/add",
            "/projects/:projectId/people/new",
            "/projects/:projectId/schedule",
            "/projects/:projectId/tasks",
            "/projects/:projectId/tasks/new",
            "/projects/:projectId/timesheets",
            "/projects/:projectId/timesheets/:userId/review",
            "/projects/:projectId/timesheets/:userId/review/:activityId/edit",
          ]}
          component={Pages.ProjectViewPage}
        />

        <Route
          path={[
            "/projects/:projectId/tasks/:taskId/attachments/new",
            "/projects/:projectId/tasks/:taskId/attachments/:attachmentId/edit",
            "/projects/:projectId/tasks/:taskId/attachments",
            "/projects/:projectId/tasks/:taskId",
            "/projects/:projectId/tasks/:taskId/people",
            "/projects/:projectId/tasks/:taskId/people/add",
            "/projects/:projectId/tasks/:taskId/people/new",
            "/projects/:projectId/tasks/:taskId/messages/new",
            "/projects/:projectId/tasks/:taskId/messages",
            "/projects/:projectId/tasks/:taskId/todos/new",
            "/projects/:projectId/tasks/:taskId/todos",
            "/projects/:projectId/tasks/:taskId/edit",
            "/projects/:projectId/tasks/:taskId/timesheets",
            "/projects/:projectId/tasks/:taskId/timesheets/:userId/review",
          ]}
          component={Pages.ProjectTaskViewPage}
        />

        <Route exact path="/projects" component={Pages.DashboardPage} />
        <Route path="/schedule/:projectId?" component={Pages.SchedulePage} />
        <Route path="/timesheets" component={Pages.TimesheetsPage} />
        <Route path="/people" component={Pages.PeoplePage} />
        <Route path="/profile" component={Pages.ProfilePage} />
        <Route path="/bigscreen" component={BigScreenPage} />
        <Route path="/debug" component={Pages.DebugPage} />

        <Route path="/reports" component={Pages.ReportsPage} />
      </Switch>
    </Layout>
  );
};
