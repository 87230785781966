import React from "react";
import { Route } from "react-router";

import { useAdminApi } from "../../../../../hooks/useAdminApi";
import { useTask } from "../../../../../hooks/params/useTask";

import { UserList } from "../../../../../components/users/UserList";
import { UserAssignDialog } from "../../../../../components/users/UserAssignDialog";
import { UserFormDialog } from "../../../../../components/users/UserFormDialog";

export const TaskPeoplePage: React.FC = () => {
  const { users } = useAdminApi();
  const task = useTask();

  return (
    <>
      <Route path="/projects/:projectId/tasks/:taskId/people/add">
        <UserAssignDialog />
      </Route>

      <Route path="/projects/:projectId/tasks/:taskId/people/new">
        <UserFormDialog action="create" />
      </Route>

      <UserList items={users.filter((it) => task?.users?.items.find((jt) => jt.userId === it.id))} />
    </>
  );
};
