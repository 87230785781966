import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useFirebase } from "../hooks/useFirebase";
import { snackbar } from "../hooks/useNotifications";

import { Button } from "../components/Button";
import { Label } from "../components/Label";

import styled from "styled-components";

export const Root = styled.div`
  height: 100%;
  padding: 52px 30px;

  display: flex;
  flex-direction: column;
`;
export const SignInTitle = styled.h2`
  margin: 0 0 25px;

  font-size: 22px;
  font-weight: bold;

  color: #3e3f42;
`;
export const TextField = styled.input`
  margin: 8px 0 20px;
  padding: 8px 16px;
  border: 1px solid #e3e5ed;
  border-radius: 4px;
  background-color: white;
  outline: none;
`;
export const Divider = styled.div`
  margin: 20px 0;
  width: 100%;
  border-bottom: 1px solid #eaedf3;
`;
const SignInMessage = styled.span`
  display: block;

  font-size: 14px;
  text-align: center;
  color: #9ea0a5;

  a {
    color: #4c93fa;
    text-decoration: none;
  }
`;

export const SignInPage: React.FC = () => {
  const { signInWithEmailAndPassword } = useFirebase();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const onSignIn = () => {
    signInWithEmailAndPassword(email, password).catch((e) => {
      snackbar.notify({
        title: e.message,
      });
    });
  };

  return (
    <Root>
      <SignInTitle>Sign In</SignInTitle>

      <Label htmlFor="username">Email Address</Label>
      <TextField autoFocus type="text" name="username" value={email} onChange={(ev) => setEmail(ev.target.value)} />

      <Label htmlFor="password">Password</Label>
      <TextField type="password" name="password" value={password} onChange={(ev) => setPassword(ev.target.value)} />

      <div style={{ marginBottom: "12px" }}></div>

      <Button name="signin" raised label="Sign In" onClick={() => onSignIn()} />

      <Divider />

      <SignInMessage>
        Forgot your password? <Link to="/reset">Get a new password</Link>
      </SignInMessage>
    </Root>
  );
};
