import gql from "graphql-tag";

import { AttachmentFields } from "./attachments";
import { ProgressFields } from "./fragments";
import { MessageFields } from "./messages";

export const TaskUserFields = gql`
  fragment TaskUserFields on TaskUser {
    taskId
    userId
  }
`;

export const PartFields = gql`
  fragment PartFields on part {
    name
    number

    quantity

    leadTimeDays

    isBillable
    cost

    markup
    markupCost
    markupType
  }
`;

export const ToolFields = gql`
  fragment ToolFields on tool {
    name
    number

    quantity

    leadTimeDays

    isBillable
    cost

    markup
    markupCost
    markupType
  }
`;

export const TaskFields = gql`
  ${AttachmentFields}
  ${ProgressFields}
  ${MessageFields}

  ${PartFields}
  ${ToolFields}

  fragment TaskFields on Task {
    id

    parentId
    projectId

    orderIndex

    name
    description

    taskNumber
    taskType

    amount
    estimateManHrs
    hourlyRate

    dueDate
    scheduledDate

    status
    inReview

    requestStatus
    requestingUserId

    parts {
      ...PartFields
    }
    tools {
      ...ToolFields
    }

    partName
    partNumber

    toolName
    toolNumber

    billing

    behindSchedule

    attachments {
      items {
        ...AttachmentFields
      }
    }

    messages {
      items {
        ...MessageFields
      }
    }

    progress {
      ...ProgressFields
    }

    tasks {
      items {
        id
        parentId
        projectId

        orderIndex

        name
        description

        taskNumber
        taskType

        amount
        estimateManHrs

        dueDate
        scheduledDate

        status
        inReview

        requestStatus
        requestingUserId

        partName
        partNumber

        toolName
        toolNumber

        billing

        behindSchedule
      }
    }

    users {
      items {
        userId
      }
    }
  }
`;

export const listTasksQuery = gql`
  ${TaskFields}

  query ListTasks($criteria: ListTasksCriteria!) {
    asAdmin {
      listTasks(criteria: $criteria) {
        items {
          ...TaskFields
        }
      }
    }
  }
`;

export const addTaskMutation = gql`
  ${TaskFields}

  mutation AddTask($input: CreateTaskRequest!) {
    asAdmin {
      addTask(input: $input) {
        ...TaskFields
      }
    }
  }
`;

export const updateTaskMutation = gql`
  ${TaskFields}

  mutation UpdateTask($input: UpdateTaskRequest!, $id: ID!, $parentId: ID!) {
    asAdmin {
      updateTask(id: $id, input: $input, parentId: $parentId) {
        ...TaskFields
      }
    }
  }
`;

export const deleteTaskMutation = gql`
  mutation DeleteTask($parentId: ID!, $id: ID!) {
    asAdmin {
      deleteTask(parentId: $parentId, id: $id) {
        id
      }
    }
  }
`;

export const assignUserToTaskMutation = gql`
  ${TaskUserFields}

  mutation AssignUserToTask($taskId: ID!, $userId: ID!) {
    asAdmin {
      assignUserToTask(taskId: $taskId, userId: $userId) {
        ...TaskUserFields
      }
    }
  }
`;

export const removeTaskUserMutation = gql`
  mutation RemoveTaskUser($id: ID!, $userId: ID!) {
    asAdmin {
      removeTaskUser(id: $id, userId: $userId) {
        taskId
        userId
      }
    }
  }
`;
