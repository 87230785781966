import React from "react";
import { useHistory, useLocation, useParams } from "react-router";

import { ProjectFields } from "../../types/admin/ProjectFields";
import { TaskFields } from "../../types/admin/TaskFields";

import { useAdminApi } from "../../hooks/useAdminApi";

import { Button } from "@rmwc/button";
import { Select } from "../Select";

import { Table, TableContainer as TableContainerBase, TableTitle, TableToolbarTitle } from "../Table";

import { Avatar, AvatarGroup } from "@rmwc/avatar";
import "@rmwc/avatar/styles";

import { CircularProgress } from "@rmwc/circular-progress";
import "@rmwc/circular-progress/styles";

import { DataTableBody, DataTableCell, DataTableContent, DataTableHead, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import dayjs from "dayjs";
import styled, { css } from "styled-components";

import Palette from "../../palette.json";
import useSorted, { sortByProperty } from "../../hooks/useSorted";
import { getUserAvatarColor } from "../../common";

const DAY_WIDTH = "10vw";

const TableContainer = styled(TableContainerBase)`
  thead {
    th {
      padding: 0 !important;
      width: ${DAY_WIDTH};
      max-width: ${DAY_WIDTH};
    }
  }

  tr {
    border: none;

    td:not(:first-child) {
      height: 52px;
      padding: 0 !important;
      border-left: 2px dashed #abb8d7;
      cursor: default;
    }
  }
`;

const WeekRange = styled.span`
  margin-left: 0.5rem;
  font-size: 14px;
  font-weight: normal;
`;

const DateHeadCell = styled.th`
  color: ${Palette.DarkGrey} !important;
  text-transform: none !important;
  font-size: 16px !important;
`;

const DateLabel = styled.div<{ isToday?: boolean }>`
  margin: 8px auto;
  padding: 11px 0;
  width: 38px;
  font-size: 16px;
  line-height: 16px;
  border-radius: 50%;

  ${(p) =>
    p.isToday &&
    css`
      color: white;
      background-color: ${Palette.Blue};
    `}
`;

const ProjectNameCell = styled.th`
  padding-left: 26px;
  text-align: left;
  cursor: pointer;
`;
const TaskNameCell = styled.th`
  padding-left: calc(26px * 1.5);
  text-align: left;
  font-weight: normal;
  cursor: pointer;
`;

const ChartTableCell = styled.td`
  position: relative;
  cursor: pointer !important;
`;

const ChartBar = styled.div<{ duration?: number }>`
  position: absolute;
  left: 0;
  top: 8px;
  padding: 0 8px 0 4px;
  width: calc(((${DAY_WIDTH} / 24) * ${(p) => p.duration || 1}) - (8px + 4px));
  max-width: calc(${DAY_WIDTH} * 7);
  height: calc(52px - 16px);
  background-color: ${Palette.Blue};

  display: flex;
  align-items: center;

  cursor: pointer;

  .mdc-icon-button {
    padding: 2px 0;
    width: 24px;
    height: 24px;
    color: white;
    font-size: 16px;
    line-height: 16px;
    border: 1px solid white;
    border-radius: 50%;
    opacity: 0.75;
  }
`;

interface TaskChartCellProps {
  day: number;

  task: TaskFields;

  fromDate: dayjs.Dayjs;
  toDate: dayjs.Dayjs;
}

const TaskChartCell: React.FC<TaskChartCellProps> = ({ day, task, fromDate }) => {
  const history = useHistory();
  const { users } = useAdminApi();

  const slotFrom = fromDate.add(day, "days");
  const scheduleDate = dayjs(task.scheduledDate).startOf("day");
  const dueDate = dayjs(task.dueDate).endOf("day");
  const duration = dueDate.diff(scheduleDate, "days") - slotFrom.diff(scheduleDate, "days");

  return (
    <ChartTableCell>
      {day === 0 && slotFrom.isAfter(scheduleDate) && slotFrom.isBefore(dueDate) && (
        <ChartBar duration={(duration + 1 > 7 ? 7 : duration + 1) * 24 - 1}>
          <AvatarGroup dense>
            {task.users?.items.map((it: { userId: string }) => (
              <Avatar
                key={it.userId}
                style={{
                  color: "white",
                  backgroundColor: getUserAvatarColor(it.userId),
                }}
                name={users.find((jt) => jt.id === it.userId)?.name || ""}
              />
            ))}
          </AvatarGroup>

          <IconButton
            icon="add"
            onClick={() => history.push(`/projects/${task.projectId}/tasks/${task.id}/people/add`)}
          />

          <div style={{ flexGrow: 1 }} />

          <CircularProgress progress={0.75} />
        </ChartBar>
      )}
      {slotFrom.isSame(scheduleDate, "date") && (
        <ChartBar duration={(duration > 7 - day ? 7 - day : duration) * 24 - 1}>
          <AvatarGroup dense>
            {task.users?.items.map((it: { userId: string }) => (
              <Avatar
                key={it.userId}
                style={{
                  color: "white",
                  backgroundColor: getUserAvatarColor(it.userId),
                }}
                name={users.find((jt) => jt.id === it.userId)?.name || ""}
              />
            ))}
          </AvatarGroup>

          <IconButton
            icon="add"
            onClick={() => history.push(`/projects/${task.projectId}/tasks/${task.id}/people/add`)}
          />

          <div style={{ flexGrow: 1 }} />

          <CircularProgress progress={0.75} />
        </ChartBar>
      )}
    </ChartTableCell>
  );
};

interface ProjectScheduleGroupItemProps {
  project: ProjectFields;

  fromDate: dayjs.Dayjs;
  toDate: dayjs.Dayjs;
}

const ProjectScheduleGroupItem: React.FC<ProjectScheduleGroupItemProps> = ({ project, fromDate, toDate }) => {
  const history = useHistory();
  const { tasks } = useAdminApi();

  /* const filtered = useFiltered(tasks, (it) => {
    return it.status === TaskStatus.InProgress;
  }); */

  return (
    <>
      <DataTableRow key={project.id}>
        <ProjectNameCell onClick={() => history.push(`/projects/${project.id}`)}>{project.name}</ProjectNameCell>
        <DataTableCell colSpan={7} style={{ border: "none" }}>
          <div style={{ borderBottom: `1px solid #eaedf3`, width: "100%" }} />
        </DataTableCell>
      </DataTableRow>

      {tasks
        .filter((jt) => jt.parentId === project.id)
        .map((task) => (
          <DataTableRow key={task.id}>
            <TaskNameCell onClick={() => history.push(`/projects/${project.id}/tasks/${task.id}`)}>
              {task.name}
            </TaskNameCell>

            {dayjs.weekdays().map((jt, i) => (
              <TaskChartCell key={i} {...{ day: i, task, fromDate, toDate }} />
            ))}
          </DataTableRow>
        ))}
    </>
  );
};

export const ScheduleChart: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const { projectId } = useParams<{ projectId: string }>();
  const { projects } = useAdminApi();

  const sorted = useSorted(projects, sortByProperty("name"));

  const week = dayjs(search.get("week") || dayjs());
  const fromDate = week.startOf("week");
  const toDate = week.endOf("week");

  const project = projects.find((it) => it.id === projectId);

  const onWeekPrev = () => {
    history.push(`${location.pathname}?week=${fromDate.subtract(1, "week").format("YYYY-MM-DD")}`);
  };
  const onWeekNext = () => {
    history.push(`${location.pathname}?week=${fromDate.add(1, "week").format("YYYY-MM-DD")}`);
  };
  const gotoThisWeek = () => {
    history.push(`${location.pathname}?week=${dayjs().startOf("week").format("YYYY-MM-DD")}`);
  };
  const gotoProject = (projectId: string) => {
    history.push(
      `/schedule${projectId === "all" ? "" : "/" + projectId}${
        search.toString().length === 0 ? "" : "?" + search.toString()
      }`,
    );
  };

  return (
    <TableContainer>
      <TableTitle>
        <IconButton icon="chevron_left" onClick={onWeekPrev} />
        <TableToolbarTitle style={{ flexGrow: 0 }}>
          Week
          <WeekRange>
            ({fromDate.format("MMM D")} - {toDate.format("MMM D")})
          </WeekRange>
        </TableToolbarTitle>
        <IconButton icon="chevron_right" onClick={onWeekNext} />

        <Button outlined onClick={gotoThisWeek}>
          This Week
        </Button>

        <div style={{ flexGrow: 1 }} />

        {location.pathname.startsWith("/schedule") && (
          <Select
            outlined
            enhanced
            value={project?.id || "all"}
            onChange={(ev: any) => gotoProject(ev.target.value)}
            options={[
              { label: "All Projects", value: "all" },
              ...projects.map((it) => ({ label: it.name, value: it.id })),
            ]}
          />
        )}
      </TableTitle>

      <Table>
        <DataTableContent>
          <DataTableHead>
            <DataTableRow>
              <DataTableCell></DataTableCell>

              {dayjs.weekdays().map((it, i) => (
                <DateHeadCell key={i}>
                  <DateLabel isToday={fromDate.add(i, "days").isSame(dayjs(), "date")}>
                    {fromDate.add(i, "days").date()}
                  </DateLabel>
                  {it}
                </DateHeadCell>
              ))}
            </DataTableRow>
          </DataTableHead>

          <DataTableBody>
            {!project &&
              sorted.map((project) => <ProjectScheduleGroupItem key={project.id} {...{ project, fromDate, toDate }} />)}

            {project && <ProjectScheduleGroupItem {...{ project, fromDate, toDate }} />}
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
