import gql from "graphql-tag";

export const AircraftFields = gql`
  fragment AircraftFields on Aircraft {
    id
    clientId
    type
    serialNumber
    tailNumber
  }
`;

export const listAircraftQuery = gql`
  ${AircraftFields}

  query ListAircraft {
    asAdmin {
      listAircraft {
        items {
          ...AircraftFields
        }
      }
    }
  }
`;

export const addAircraftMutation = gql`
  ${AircraftFields}

  mutation AddAircraft($clientId: String!, $type: String!, $tailNumber: String!, $serialNumber: String!) {
    asAdmin {
      addAircraft(input: { clientId: $clientId, serialNumber: $serialNumber, tailNumber: $tailNumber, type: $type }) {
        ...AircraftFields
      }
    }
  }
`;

export const updateAircraftMutation = gql`
  ${AircraftFields}

  mutation UpdateAircraft($id: ID!, $type: String!, $tailNumber: String!, $serialNumber: String!) {
    asAdmin {
      updateAircraft(id: $id, input: { serialNumber: $serialNumber, tailNumber: $tailNumber, type: $type }) {
        ...AircraftFields
      }
    }
  }
`;

export const deleteAircraftMutation = gql`
  mutation DeleteAircraft($id: ID!) {
    asAdmin {
      deleteAircraft(id: $id) {
        id
      }
    }
  }
`;
