import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { useForm, UseFormMethods } from "react-hook-form";

import { calculateMarkupCost } from "../../common";

import { BillingType, MarkupType, TaskStatus, TaskType, UpdateTaskRequest } from "../../types/admin/globalTypes";

import { TaskFields } from "../../types/admin/TaskFields";

import { useAdminApi } from "../../hooks/useAdminApi";
import { useProject } from "../../hooks/params/useProject";
import { useTask } from "../../hooks/params/useTask";

import { TaskFormInfoContent } from "./TaskFormInfoContent";
import { TaskFormOptionsContent } from "./TaskFormOptionsContent";
import { TaskFormPartsContent } from "./TaskFormPartsContent";
import { TaskFormTodoContent } from "./TaskFormTodoContent";

import { TabContainer } from "../TabContainer";

import { Button } from "../Button";
import { CommonForm } from "../CommonForm";
import { Dialog } from "../Dialog";

import { DialogActions, DialogButton, DialogContent, DialogOnCloseEventT, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import { TabBar, Tab } from "@rmwc/tabs";
import "@rmwc/tabs/styles";

import dayjs from "dayjs";

interface TodoItem {
  origId?: string;
  taskNumber: string;
  name: string;
}

interface PartValuesT {
  name: string;
  number: string;
  cost: string;
  leadTimeDays: string;
  quantity: string;
  isBillable: boolean;
  markupType?: MarkupType;
  markup?: string;
  markupCost?: string;
}

export interface TaskFormValuesT {
  id?: string;

  parentId: string;
  projectId: string;

  name: string;
  description: string;

  amount: number;
  billing: BillingType;

  taskNumber: string;
  taskType: TaskType;

  estimateManHrs: number;
  hourlyRate: number;

  dueDate: string;
  scheduledDate: string;

  status: TaskStatus;
  inReview: boolean;

  // Internally used variables.
  parentName: string;
  projectName: string;

  assignedTo: string[];

  parts: PartValuesT[];
  tools: PartValuesT[];

  todos: TodoItem[];
}

export interface TaskFormContentProps {
  style: React.CSSProperties;
  form: UseFormMethods<TaskFormValuesT>;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
}

interface TaskFormDialogProps {
  action: "create" | "update";
}

const HIDDEN_FIELD_NAMES = ["parentId", "projectId", "description"];

export const TaskFormDialog: React.FC<TaskFormDialogProps> = ({ action }) => {
  const history = useHistory();
  const { state: locationState } = useLocation<{ referrer: string; isNew: boolean }>();

  const { addTask, assignUserToTask, updateTask, tasks: allTasks } = useAdminApi();

  const project = useProject();
  const task = useTask();

  const tasks = task ? task.tasks?.items || [] : allTasks.filter((it) => it.parentId === project?.id);

  const isTodo = (action === "create" && task) || (action === "update" && task?.parentId !== project?.id);

  const title = (action === "create" ? "Create" : "Update") + (isTodo ? " Todo" : " Task");

  const tabs = ["Information"];

  if (!isTodo) {
    ["Cost", "Parts & Tools", "Todos"].map((it) => tabs.push(it));
  }

  const [activeTabIndex, setActiveTabIndex] = useState<number>(locationState && locationState.isNew ? 1 : 0);

  const taskNumberTemplate = isTodo
    ? `${task?.taskNumber}-${tasks.length + 1}`
    : `${("000" + (tasks.length + 1)).substr(-3, 3)}`;

  const form = useForm<TaskFormValuesT>({
    mode: "all",
    criteriaMode: "all",
    defaultValues: {
      id: "",
      parentId: "",
      projectId: "",
      name: "",
      description: "",
      amount: 0,
      billing: BillingType.Fixed,
      taskNumber: taskNumberTemplate,
      taskType: TaskType.Scheduled,
      dueDate: dayjs().tz("UTC", true).add(1, "day").format("YYYY-MM-DD"),
      scheduledDate: dayjs().tz("UTC", true).format("YYYY-MM-DD"),
      status: TaskStatus.Upcoming,
      inReview: false,
      hourlyRate: project?.hourlyRate || 0.0,
      estimateManHrs: 0,

      projectName: "",
      parentName: "",
      assignedTo: [],
      parts: [],
      tools: [],
      todos: [],
    },
  });

  const { formState, register, setValue, watch } = form;
  const { dirtyFields, errors, isValid } = formState;

  const estimatedHours = watch("estimateManHrs");
  const hourlyRate = watch("hourlyRate");

  useEffect(() => {
    if (Object.keys(errors).length === 0) return;

    console.info(errors);
  }, [errors]);

  useEffect(() => {
    setValue("amount", estimatedHours * hourlyRate, { shouldDirty: true, shouldValidate: true });
  }, [estimatedHours, hourlyRate]);

  useEffect(() => {
    if (!project) return;

    setValue("parentId", project.id);
    setValue("projectId", project.id);

    setValue("projectName", project.name);
    setValue("parentName", task ? `${task.taskNumber} - ${task.name}` : project.name);

    if (action === "create") {
      setValue("parentId", task ? task.id : project.id);
      return;
    }

    if (action === "update") {
      if (!task) return;

      setValue("id", task.id);
      setValue("parentId", task.parentId);
      setValue("name", task.name);
      setValue("description", task.description);
      setValue("taskNumber", task.taskNumber);
      setValue("taskType", task.taskType);
      setValue("dueDate", dayjs(task.dueDate).tz("UTC").format("YYYY-MM-DD"));
      setValue("scheduledDate", dayjs(task.scheduledDate).tz("UTC").format("YYYY-MM-DD"));
      setValue("status", task.status);
      setValue("amount", task.amount);
      setValue("billing", task.billing);
      setValue("hourlyRate", task.hourlyRate || project.hourlyRate || 0.0);
      setValue("estimateManHrs", task.estimateManHrs);
      setValue(
        "assignedTo",
        task.users?.items.map((it) => it.userId),
      );
      setValue("parts", task.parts || []);
      setValue("tools", task.tools || []);
      setValue("todos", task.tasks?.items.map((it) => ({ origId: it.id, ...it })) || []);
    }
  }, [project, task]);

  const onSubmit = form.handleSubmit(async (values) => {
    const { assignedTo, parts, tools, todos } = values;

    const submission: any = (({
      parentId,
      projectId,
      name,
      description,
      amount,
      billing,
      taskNumber,
      taskType,
      dueDate,
      scheduledDate,
      status,
      inReview,
      estimateManHrs,
      hourlyRate,
    }) => ({
      parentId,
      projectId,
      name,
      description,
      amount,
      billing,
      estimateManHrs,
      hourlyRate,
      dueDate: dayjs(dueDate, "YYYY/MM/DD").tz("UTC", true).toISOString(),
      scheduledDate: dayjs(scheduledDate, "YYYY/MM/DD").tz("UTC", true).toISOString(),
      taskNumber,
      taskType,
      status,
      inReview,
    }))(values);

    if (action === "create") {
      if (isTodo) {
        submission.orderIndex = tasks?.length || 0;
      }

      const task = await addTask({
        input: {
          ...submission,
        },
      });
      console.info("<<<", task);

      if (!task) return;

      const { id: taskId } = task;

      if (assignedTo) {
        await Promise.all(
          assignedTo.map(async (userId) => {
            await assignUserToTask({ taskId, userId });
          }),
        );
      }

      if (isTodo) {
        history.goBack();
        return;
      }

      history.replace(`/projects/${task.projectId}/tasks/${task.id}/edit`, { ...(locationState || {}), isNew: true });
      return;

      /*if (todos) {
        await Promise.all(
          todos.map((it, i) => {
            const sub: TaskFields = {
              parentId: task.id,
              projectId: task.projectId,
              scheduledDate: task.scheduledDate,
              dueDate: task.dueDate,
              description: "",
              ...it,
              orderIndex: i,
            } as TaskFields;
            return addTask({ input: sub });
          }),
        );
      }

      return; */
    }

    if (action === "update") {
      if (!task) return;

      delete submission.parentId;
      delete submission.projectId;

      await updateTask({
        id: task.id,
        parentId: task.parentId,
        input: {
          ...submission,
          parts: !parts
            ? ([] as PartValuesT[])
            : parts.map((it: PartValuesT) => ({
                ...it,
                markupCost: it.isBillable ? calculateMarkupCost(it) : undefined,
              })),
          tools: !tools
            ? ([] as PartValuesT[])
            : tools.map((it: PartValuesT) => ({
                ...it,
                markupCost: it.isBillable ? calculateMarkupCost(it) : undefined,
              })),
        },
      });

      if ("todos" in dirtyFields) {
        await Promise.all(
          todos.map(async ({ origId, ...it }, i) => {
            if (origId && origId !== "new") {
              // update existing
              const submission: UpdateTaskRequest = {
                ...it,
                orderIndex: i,
              };

              return await updateTask({
                id: origId,
                parentId: task.id,
                input: submission,
              });
            } else if (origId === "new") {
              // create new
              const sub: TaskFields = {
                parentId: task.id,
                projectId: task.projectId,
                scheduledDate: task.scheduledDate,
                dueDate: task.dueDate,
                description: "",
                ...it,
                orderIndex: i,
              } as TaskFields;
              return await addTask({ input: sub });
            }
          }),
        );
      }

      if (locationState?.referrer === "/projects/new") {
        history.push(`/projects/${project?.id}`);
        return;
      }

      history.goBack();
    }
  });

  const onClose = async (ev: DialogOnCloseEventT) => {
    if (ev.detail.action === "accept") {
      await onSubmit();
    }

    if (ev.detail.action !== "destroy") history.goBack();
  };

  const onTabActivated = (ev: any) => {
    setActiveTabIndex(ev.detail.index);
  };

  const onBackClicked = () => {
    setActiveTabIndex((p) => (p !== 0 ? p - 1 : p));
  };

  const onNextClicked = () => {
    setActiveTabIndex((p) => (p < 3 ? p + 1 : p));
  };

  return (
    <Dialog open preventOutsideDismiss onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>

      <TabContainer style={{ paddingLeft: "30px" }}>
        <TabBar {...{ activeTabIndex }} onActivate={onTabActivated}>
          {tabs.map((it, i) => (
            <Tab key={i}>{it}</Tab>
          ))}
        </TabBar>
        {action === "create" && (
          <div
            style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
            onClick={(ev) => ev.stopPropagation()}
          />
        )}
      </TabContainer>

      <DialogContent>
        <CommonForm {...{ onSubmit }}>
          {HIDDEN_FIELD_NAMES.map((it, i) => (
            <input key={i} type="hidden" name={it} ref={register} />
          ))}

          <TaskFormInfoContent
            style={{ display: activeTabIndex === 0 ? "block" : "none" }}
            {...{ form, setActiveTabIndex }}
          />
          <TaskFormOptionsContent
            style={{ display: activeTabIndex === 1 ? "block" : "none" }}
            {...{ form, setActiveTabIndex }}
          />
          <TaskFormPartsContent
            style={{ display: activeTabIndex === 2 ? "block" : "none" }}
            {...{ form, setActiveTabIndex }}
          />
          <TaskFormTodoContent
            style={{ display: activeTabIndex === 3 ? "block" : "none" }}
            {...{ form, setActiveTabIndex }}
          />
        </CommonForm>
      </DialogContent>

      <DialogActions>
        <DialogButton dense danger action="close">
          Cancel
        </DialogButton>

        <div style={{ flexGrow: 1 }} />
        {tabs.length > 1 && (
          <Button disabled={!(activeTabIndex > 0)} onClick={onBackClicked}>
            Back
          </Button>
        )}

        {task && tabs.length > 1 && activeTabIndex !== tabs.length - 1 && (
          <Button raised onClick={onNextClicked}>
            Next
          </Button>
        )}

        {(!task || activeTabIndex === tabs.length - 1) && (
          <Button raised disabled={action === "create" ? !isValid : false} onClick={() => onSubmit()}>
            {title}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
