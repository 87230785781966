import React from "react";

import { TimesheetTable } from "../../../../../components/timesheets/TimesheetTable";

import { useTask } from "../../../../../hooks/params/useTask";

export const TaskTimesheetsPage: React.FC = () => {
  const task = useTask();

  if (!task) return <></>;

  return (
    <>
      <TimesheetTable filterType="task" filterId={task.id} />
    </>
  );
};
