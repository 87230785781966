import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { Controller, useForm } from "react-hook-form";

import { snackbar } from "../../hooks/useNotifications";

import { USER_ROLE_OPTIONS } from "../../common";

import { ProjectRole } from "../../types/admin/globalTypes";

import { useAdminApi } from "../../hooks/useAdminApi";
import { useProject } from "../../hooks/params/useProject";
import { useUser } from "../../hooks/params/useUser";

import { Dialog } from "../Dialog";

import { Label } from "../Label";
import { Select } from "../Select";
import { TextInput } from "../TextInput";

import { CommonForm, FormField, FormRow } from "../CommonForm";

import { Checkbox } from "@rmwc/checkbox";
import "@rmwc/checkbox/styles";

import { DialogActions, DialogButton, DialogContent, DialogOnCloseEventT, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import Palette from "../../palette.json";
import { PhoneInput } from "../PhoneInput";
import { ErrorMessage } from "@hookform/error-message";

interface FormValuesT {
  id?: string;

  clientId: string;

  projectId: string;
  projectRole: ProjectRole;

  firstName: string;
  lastName: string;

  email: string;
  phoneNumber: string;

  isSystemAdministrator: boolean;
  payChex: {
    id: string;
    orgUnit: string;
  };
}

interface UserFormDialogProps {
  action: "create" | "update";
}
export const UserFormDialog: React.FC<UserFormDialogProps> = ({ action }) => {
  const history = useHistory();
  const location = useLocation<{ referrer: string }>();
  const { state: locationState } = location;

  const query = new URLSearchParams(location.search);

  const { addUser, updateUser } = useAdminApi();

  const project = useProject();
  const user = useUser();

  const closeRef = useRef<HTMLButtonElement>(null);
  const [doAnother, setDoAnother] = useState<boolean>(false);

  const form = useForm<FormValuesT>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      clientId: query.get("clientId") || project?.clientId || "",
      projectId: project?.id || "",
      projectRole: (user ? ProjectRole.technician : (query.get("role") as ProjectRole)) || ProjectRole.technician,
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      phoneNumber: user?.phoneNumber || "",
      isSystemAdministrator: user?.isSystemAdministrator || false,
      payChex: {
        id: "",
        orgUnit: "",
      },
    },
  });
  const { control, formState, register, setValue, setError, watch } = form;
  const { errors, isDirty, isValid } = formState;

  const projectRole = watch("projectRole");

  let roleTitle = "Customer";
  if (projectRole === ProjectRole.manager) {
    roleTitle = "User";
  } else if (projectRole === ProjectRole.technician) {
    roleTitle = "User";
  }
  const title = `${action === "create" ? "Create" : "Update"} ${roleTitle}`;

  useEffect(() => {
    if (!setValue || !user) return;

    if (query.get("clientId")) setValue("clientId", query.get("clientId"));

    setValue("firstName", user.firstName);
    setValue("lastName", user.lastName);

    setValue("email", user.email);
    setValue("phoneNumber", user.phoneNumber);

    setValue("isSystemAdministrator", user.isSystemAdministrator);
    setValue("payChex.id", user.payChex?.id);
    setValue("payChex.orgUnit", user.payChex?.orgUnit);
  }, [setValue, user, query]);

  const onSubmit = form.handleSubmit(async (values) => {
    console.info("***", values);

    if (action === "create") {
      try {
        await addUser(values);
        snackbar.notify({
          title: !project ? "User created successfully!" : "User created and added to project successfully!",
        });
      } catch (err) {
        if (err.message.includes("phone number already exists")) {
          setError("phoneNumber", { type: "manual", message: "Number already taken" });
        } else {
          console.log(err);
          snackbar.notify({ title: err.message, timeout: 10 * 1000 });
        }
        return;
      }
    } else if (action === "update") {
      if (!user) return;

      try {
        await updateUser({
          id: user.id,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber || user.phoneNumber,
          payChex: {
            id: values.payChex?.id,
            orgUnit: values.payChex?.orgUnit,
          },
        });
        snackbar.notify({ title: "User updated successfully!" });
      } catch (err) {
        if (err.message.includes("phone number already exists")) {
          setError("phoneNumber", { type: "manual", message: "Number already taken" });
        } else {
          console.log(err);
          snackbar.notify({ title: err.message, timeout: 10 * 1000 });
        }
        return;
      }
    }

    if (doAnother) {
      form.reset();
      return;
    } else {
      closeRef.current?.click();
    }
  });

  const onClose = async (ev: DialogOnCloseEventT) => {
    if (ev.detail.action === "accept") {
      await onSubmit();
    }

    if (locationState?.referrer === "/projects/new") {
      history.replace(`/projects/${project?.id}/people/add`, locationState);
      return;
    }

    history.goBack();
  };

  return (
    <Dialog open preventOutsideDismiss onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <CommonForm onSubmit={onSubmit}>
          <input type="hidden" name="clientId" ref={register} />
          <input type="hidden" name="projectId" ref={register} />

          <input type="hidden" name="isSystemAdministrator" ref={register({ required: true })} />

          {project && (
            <FormRow>
              <FormField>
                <Label htmlFor="projectRole">Job Role</Label>
                <Controller
                  {...{ control }}
                  name="projectRole"
                  as={<Select outlined enhanced options={USER_ROLE_OPTIONS} />}
                />
              </FormField>
            </FormRow>
          )}

          <FormRow>
            <FormField>
              <Label htmlFor="firstName">First Name</Label>
              <TextInput
                name="firstName"
                isInvalid={"firstName" in errors}
                ref={register({ required: "Name required" })}
              />
              <ErrorMessage errors={errors} name="firstName" />
            </FormField>

            <FormField>
              <Label htmlFor="lastName">Last Name</Label>
              <TextInput
                name="lastName"
                isInvalid={"lastName" in errors}
                ref={register({ required: "Name required" })}
              />
              <ErrorMessage errors={errors} name="lastName" />
            </FormField>
          </FormRow>

          <FormRow>
            <FormField>
              <Label htmlFor="email">Email Address</Label>
              <TextInput name="email" isInvalid={"email" in errors} ref={register({ required: "Email required" })} />
              <ErrorMessage errors={errors} name="email" />
            </FormField>

            <FormField>
              <Label htmlFor="phoneNumber">Phone Number</Label>
              <PhoneInput
                name="phoneNumber"
                isInvalid={"phoneNumber" in errors}
                register={register}
                setValue={setValue}
              />
              <ErrorMessage errors={errors} name="phoneNumber" />
            </FormField>
          </FormRow>

          {(!project || projectRole === ProjectRole.technician) && (
            <FormRow>
              <FormField>
                <Label htmlFor="payChex.id">Payroll Identifier</Label>
                <TextInput name="payChex.id" ref={register({ required: false })} isInvalid={"payChex.id" in errors} />
                <ErrorMessage errors={errors} name="payChex.id" />
              </FormField>

              <FormField>
                <Label htmlFor="payChex.orgUnit">Org Unit</Label>
                <TextInput
                  name="payChex.orgUnit"
                  ref={register({ required: false })}
                  isInvalid={"payChex.orgUnit" in errors}
                />
                <ErrorMessage errors={errors} name="payChex.orgUnit" />
              </FormField>
            </FormRow>
          )}

          {!project && (
            <FormRow>
              <FormField>
                <Controller
                  {...{ control }}
                  name="isSystemAdministrator"
                  render={(field) => (
                    <Checkbox
                      label="Administrator"
                      checked={field.value}
                      onChange={(ev) => field.onChange(ev.currentTarget.checked)}
                      inputRef={field.ref}
                    />
                  )}
                />
              </FormField>
            </FormRow>
          )}
        </CommonForm>
      </DialogContent>

      <DialogActions>
        <DialogButton ref={closeRef} action="close" style={{ color: Palette.DarkGrey }}>
          Cancel
        </DialogButton>
        <div style={{ flexGrow: 1 }} />

        {action === "create" && (
          <Checkbox
            style={{ marginRight: "20px", fontSize: "14px" }}
            label="Create Another"
            checked={doAnother}
            onClick={() => setDoAnother((p) => !p)}
          />
        )}
        <DialogButton raised isDefaultAction disabled={!isDirty} onClick={() => onSubmit()}>
          Save
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
