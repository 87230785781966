import gql from "graphql-tag";

export const LocationFields = gql`
  fragment LocationFields on Location {
    id
    name

    address {
      city
      country
      state
      street1
      street2
      zip
    }

    geometry {
      coordinates
      type
    }
  }
`;

export const listLocationsQuery = gql`
  ${LocationFields}

  query ListLocations {
    asAdmin {
      listLocations {
        items {
          ...LocationFields
        }
      }
    }
  }
`;

export const addLocationMutation = gql`
  ${LocationFields}

  mutation AddLocation($input: CreateLocationRequest!) {
    asAdmin {
      addLocation(input: $input) {
        ...LocationFields
      }
    }
  }
`;

export const updateLocationMutation = gql`
  ${LocationFields}

  mutation UpdateLocation($id: ID!, $input: UpdateLocationRequest!) {
    asAdmin {
      updateLocation(id: $id, input: $input) {
        ...LocationFields
      }
    }
  }
`;

export const deleteLocationMutation = gql`
  mutation DeleteLocation($id: ID!) {
    asAdmin {
      deleteLocation(id: $id) {
        id
      }
    }
  }
`;
