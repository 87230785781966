import { useParams } from "react-router";
import { useAdminApi } from "../useAdminApi";

interface ParamsT {
  projectId: string;
}
export const useProject = () => {
  const { projectId } = useParams<ParamsT>();

  const { projects } = useAdminApi();
  const project = projects.find((it) => it.id === projectId);

  return project;
};

export const useProjectTasks = () => {
  const { projectId } = useParams<ParamsT>();
  const { tasks } = useAdminApi();
  return tasks.filter((it) => it.parentId === projectId);
};
