import React, { useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router";

import { Route } from "react-router";

import { TimesheetFields } from "../../types/admin/TimesheetFields";

import { useUser } from "../../hooks/params/useUser";

import { DialogActions, DialogButton, DialogContent, DialogOnClosedEventT, DialogTitle } from "@rmwc/dialog";
import { Dialog } from "../Dialog";

import { Table, TableContainer, TableHead, DataCellTools } from "../Table";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import { CircularProgress } from "@rmwc/circular-progress";
import "@rmwc/circular-progress/styles";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import { ThemeProvider } from "@rmwc/theme";
import "@rmwc/theme/styles";

import dayjs from "dayjs";
import numeral from "numeral";

import Palette from "../../palette.json";

import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";

interface ParamsT {
  userId: string;
}

interface TimesheetReviewDialogProps {
  timesheets: TimesheetFields[];
  isLoading: boolean;
  onDelete: any;
}

export const TimesheetReviewDialog: React.FC<TimesheetReviewDialogProps> = ({ timesheets, isLoading, onDelete }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { userId } = useParams<ParamsT>();

  const user = useUser();
  const filtered = timesheets.filter((it) => it.userId === userId);

  const onClose = async () => {
    history.goBack();
  };

  // todo type param
  const edit = (it: any) => {
    console.log("editing: ", `${match.url}/${it.id}/edit`);
    history.push(`${match.url}/${it.id}/edit`);
  };

  return (
    <Dialog open preventOutsideDismiss>
      <DialogTitle>{user?.name}</DialogTitle>

      <DialogContent>
        <TableContainer>
          <Table>
            <DataTableContent>
              <TableHead cols={["Date", "Project", "Task", "Total Hours"]} />

              <DataTableBody>
                {isLoading && (
                  <DataTableRow>
                    <DataTableCell colSpan={4}>
                      <CircularProgress />
                    </DataTableCell>
                  </DataTableRow>
                )}
                {filtered.map((it, i) => (
                  <DataTableRow key={i}>
                    <DataTableCell>{dayjs(it.fromDT).format("L")}</DataTableCell>
                    <DataTableCell>{it.projectSummary}</DataTableCell>
                    <DataTableCell>{it.taskSummary}</DataTableCell>
                    <DataTableCell>{numeral(it.amountMins ? it.amountMins / 60 : 0).format("0.0")}</DataTableCell>
                    <DataTableCell>
                      <DataCellTools>
                        <IconButton icon="edit" onClick={() => edit(it)} />
                        <IconButton icon="delete" onClick={() => onDelete(it)} />
                      </DataCellTools>
                    </DataTableCell>
                  </DataTableRow>
                ))}
              </DataTableBody>
            </DataTableContent>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <ThemeProvider options={{ primary: Palette.MediumGrey }}>
          <DialogButton danger onClick={onClose}>
            Cancel
          </DialogButton>
        </ThemeProvider>
        <div style={{ flexGrow: 1 }} />

        {/* <Checkbox style={{ marginRight: "20px", fontSize: "14px" }} label="Create Another" /> */}
        <ThemeProvider options={{ primary: Palette.Green }}>
          <DialogButton isDefaultAction raised onClick={onClose}>
            Confirm
          </DialogButton>
        </ThemeProvider>
      </DialogActions>
    </Dialog>
  );
};
