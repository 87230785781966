import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { ProjectStatus } from "../../types/admin/globalTypes";

import { useAdminApi } from "../../hooks/useAdminApi";
import { useClient } from "../../hooks/params/useClient";
import { useProject } from "../../hooks/params/useProject";

import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";

import { Button } from "../Button";
import { Label } from "../Label";
import { Dialog } from "../Dialog";
import { CommonForm, FormField, FormRow } from "../CommonForm";
import { Select } from "../Select";
import { TextInput } from "../TextInput";
import { TextArea } from "../TextArea";

import { ThemeProvider } from "@rmwc/theme";
import "@rmwc/theme/styles";

import { DialogActions, DialogButton, DialogContent, DialogOnCloseEventT, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";

import Palette from "../../palette.json";

interface FormValuesT {
  id?: string;

  aircraftId: string;
  clientId: string;
  cameraId: string;

  name: string;
  description: string;

  hourlyRate: string;
  estimateManHrs: string;

  startDate: string;
  endDate: string;

  status: ProjectStatus;
}

interface ProjectFormDialogProps {
  action: "create" | "update";
}
export const ProjectFormDialog: React.FC<ProjectFormDialogProps> = ({ action }) => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const { aircraft, clients, addProject, updateProject, getCameras } = useAdminApi();
  const client = useClient();
  const project = useProject();

  const form = useForm<FormValuesT>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      clientId: project?.clientId || query.get("clientId") || client?.id || "",
      aircraftId: project?.aircraftId || query.get("aircraftId") || "",
      cameraId: "",
      name: project?.name || "",
      description: project?.description || "",
      hourlyRate: `${project?.hourlyRate}` || "0.0",
      estimateManHrs: `${project?.estimateManHrs}` || "0",
      startDate: project ? dayjs(project.startDate).tz("UTC", true).format("YYYY-MM-DD") : "",
      endDate: project ? dayjs(project.endDate).tz("UTC", true).format("YYYY-MM-DD") : "",
      status: (project && project.status) || ProjectStatus.Estimated,
    },
  });

  const { control, formState, register, watch, setValue } = form;
  const { isDirty, isValid } = formState;

  const title = action === "create" ? "Create Project" : "Update Project";

  const clientId = watch("clientId");

  const clientOpts: { value: string; label: string }[] = [];
  const aircraftOpts: { value: string; label: string }[] = [];
  const statusOpts: { value: string; label: string }[] = [];

  const [cameraOpts, setCameraOpts] = useState<{ value: string; label: string }[]>([]);

  aircraft
    .filter((it) => it.clientId === clientId)
    .forEach((it) => {
      aircraftOpts.push({ value: it.id, label: `${it.type} - ${it.tailNumber || it.serialNumber}` || "" });
    });

  clients.forEach((it) => {
    clientOpts.push({ value: it.id, label: it.name });
  });

  for (const [k, v] of Object.entries(ProjectStatus)) {
    if (v === ProjectStatus.Declined) continue;
    statusOpts.push({ label: k, value: v });
  }

  useEffect(() => {
    if (!getCameras) return;

    getCameras()
      .then((r) => {
        setCameraOpts(r.map(({ id, name }) => ({ value: id || "", label: name || "" })));
      })
      .catch((e) => console.error(e));

    if (!project) return;

    getCameras(project?.id)
      .then((r) => {
        const [camera] = r;
        if (camera) setValue("cameraId", camera.id);
      })
      .catch((e) => console.error(e));
  }, [project, getCameras]);

  const onSubmit = form.handleSubmit(async (values) => {
    console.info("***", values);

    if (action === "create") {
      const project = await addProject({
        ...values,
        hourlyRate: parseFloat(values.hourlyRate),
        estimateManHrs: parseFloat(values.estimateManHrs),
        startDate: dayjs(values.startDate, "YYYY-MM-DD").tz("UTC", true).toISOString(),
        endDate: dayjs(values.endDate, "YYYY-MM-DD").tz("UTC", true).toISOString(),
      });

      if (project) {
        history.replace(`/projects/${project.id}/people/add`, { referrer: "/projects/new" });
      }
    } else if (action === "update") {
      await updateProject({
        id: project?.id || "",
        ...values,
        hourlyRate: parseFloat(values.hourlyRate),
        estimateManHrs: parseFloat(values.estimateManHrs),
        startDate: dayjs(values.startDate, "YYYY-MM-DD").tz("UTC", true).toISOString(),
        endDate: dayjs(values.endDate, "YYYY-MM-DD").tz("UTC", true).toISOString(),
      });

      history.goBack();
    }
  });

  const onClose = async (ev: DialogOnCloseEventT) => {
    if (ev.detail.action === "accept") {
      await onSubmit();
      return;
    }

    if (ev.detail.action === "destroy") return;
    history.goBack();
  };

  return (
    <Dialog open preventOutsideDismiss onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <CommonForm onSubmit={onSubmit}>
          <FormRow>
            <FormField>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Label htmlFor="clientId" style={{ flexGrow: 1 }}>
                  Client
                </Label>
                {action === "create" && !client && (
                  <Button
                    style={{ marginBottom: "0", padding: 0, height: "auto", fontSize: "12px" }}
                    onClick={() => history.replace(`/clients/new`, { referrer: location.pathname })}
                  >
                    Add New
                  </Button>
                )}
              </div>
              <Controller
                name="clientId"
                control={control}
                as={<Select disabled={!!client} outlined enhanced options={clientOpts} />}
              />
            </FormField>

            <FormField>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Label htmlFor="aircraftId" style={{ flexGrow: 1 }}>
                  Aircraft
                </Label>
                {action === "create" && (
                  <Button
                    style={{ marginBottom: "0", padding: 0, height: "auto", fontSize: "12px" }}
                    onClick={() => history.replace(`/clients/${clientId}/aircraft/new`, { referrer: "/projects/new" })}
                  >
                    Add New
                  </Button>
                )}
              </div>
              <Controller
                name="aircraftId"
                control={control}
                disabled={aircraftOpts.length === 0}
                as={<Select outlined enhanced options={aircraftOpts} />}
              />
            </FormField>
          </FormRow>

          <FormRow>
            <FormField>
              <Label htmlFor="status">Status</Label>
              <Controller name="status" control={control} as={<Select outlined enhanced options={statusOpts} />} />
            </FormField>
            <FormField>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Label htmlFor="cameraId" style={{ flexGrow: 1 }}>
                  Camera
                </Label>
              </div>
              <Controller
                name="cameraId"
                control={control}
                disabled={cameraOpts.length === 0}
                as={<Select outlined enhanced options={cameraOpts} />}
              />
            </FormField>
          </FormRow>

          <FormRow>
            <FormField style={{ flexGrow: 1 }}>
              <Label htmlFor="name">Project Name</Label>
              <TextInput name="name" ref={register({ required: true })} />
            </FormField>
          </FormRow>

          <FormRow>
            <FormField style={{ flexGrow: 1 }}>
              <Label htmlFor="description">Description</Label>
              <TextArea name="description" ref={register({ required: true })} />
            </FormField>
          </FormRow>

          <FormRow>
            <FormField>
              <Label htmlFor="estimateManHrs">Estimated Hours</Label>
              <TextInput name="estimateManHrs" type="number" ref={register({ required: true })} />
            </FormField>

            <FormField>
              <Label htmlFor="hourlyRate">Hourly Rate</Label>
              <TextInput name="hourlyRate" type="number" ref={register()} />
            </FormField>
          </FormRow>

          <FormRow>
            <FormField>
              <Label htmlFor="startDate">Start Date</Label>
              <Controller
                name="startDate"
                rules={{ required: true }}
                control={control}
                as={<TextField type="date" />}
              />
            </FormField>

            <FormField>
              <Label htmlFor="endDate">End Date</Label>
              <Controller name="endDate" rules={{ required: true }} control={control} as={<TextField type="date" />} />
            </FormField>
          </FormRow>
        </CommonForm>
      </DialogContent>

      <DialogActions>
        <ThemeProvider options={{ primary: Palette.MediumGrey }}>
          <DialogButton action="close" outlined>
            Cancel
          </DialogButton>
        </ThemeProvider>

        <div style={{ flexGrow: 1 }} />

        <DialogButton action="accept" isDefaultAction raised disabled={!isDirty || !isValid}>
          {action === "create" ? "Create Project" : "Update Project"}
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
