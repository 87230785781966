import React from "react";

import { PartFields } from "../../../../../types/admin/PartFields";
import { ToolFields } from "../../../../../types/admin/ToolFields";

import { useProject } from "../../../../../hooks/params/useProject";
import { useTask } from "../../../../../hooks/params/useTask";

import { PartList } from "../../../../../components/tasks/PartList";
import { ToolList } from "../../../../../components/tasks/ToolList";

export const TaskPartsPage: React.FC = () => {
  const project = useProject();
  const task = useTask();

  if (!project || !task) return <></>;

  return (
    <>
      <PartList items={task.parts || ([] as PartFields[])} />
      <div style={{ margin: "2rem" }} />
      <ToolList items={task.tools || ([] as ToolFields[])} />
    </>
  );
};
