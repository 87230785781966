import React, { useState } from "react";
import { useHistory } from "react-router";

import { useFirebase } from "../hooks/useFirebase";
import { snackbar } from "../hooks/useNotifications";

import { Button } from "../components/Button";
import { Label } from "../components/Label";
import { Divider, Root, SignInTitle, TextField } from "./signin";

import styled, { css } from "styled-components";

import Palette from "../palette.json";

const ResetMessage = styled.p<{ warn?: boolean }>`
  max-width: 290px;
  margin: -0.5rem 0 1rem;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.69;
  color: #3e3f42;

  ${(p) =>
    p.warn &&
    css`
      padding: 0.5rem 1rem;
      color: ${Palette.Red};
      background-color: ${Palette.Orange3};
      border-radius: 0.5rem;
    `}
`;

export const SignInResetPage: React.FC = () => {
  const history = useHistory();
  const { sendPasswordResetEmail } = useFirebase();

  const [email, setEmail] = useState<string>("");
  const [hasSent, setSent] = useState<boolean>(false);

  const onSubmit = async () => {
    try {
      await sendPasswordResetEmail(email);
      setSent(true);

      snackbar.notify({
        title: "Recovery email has been sent.",
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Root>
      {!hasSent && (
        <>
          <SignInTitle>Password Reset</SignInTitle>

          <ResetMessage>
            Enter your email address that you used to register. We’ll send an email with a link to reset your password.
          </ResetMessage>

          <Label>E-Mail Address</Label>
          <TextField type="text" name="email" value={email} onChange={(ev) => setEmail(ev.target.value)} />

          <Button raised disabled={email.length < 3} onClick={() => onSubmit()}>
            Submit
          </Button>

          <div style={{ height: "38px" }} />

          <Divider style={{ marginBottom: "0.5rem" }} />

          <Button onClick={() => history.replace("/")}>Cancel</Button>
        </>
      )}

      {hasSent && (
        <>
          <SignInTitle>Recovery Email Sent</SignInTitle>

          <ResetMessage>Please check your email for your password recovery link.</ResetMessage>
          <ResetMessage warn>
            Don't forget to check your Junk email if it hasn't appeared after a few minutes.
          </ResetMessage>

          <Button raised onClick={() => history.goBack()}>
            Go Back
          </Button>
        </>
      )}
    </Root>
  );
};
