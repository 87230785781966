import gql from "graphql-tag";

import { ProgressFields } from "./fragments";

export const ProjectFields = gql`
  ${ProgressFields}

  fragment ProjectFields on Project {
    id

    aircraftId
    clientId

    name
    description

    startDate
    endDate

    hourlyRate
    estimateManHrs

    status

    progress {
      ...ProgressFields
    }

    users {
      items {
        role
        userId
      }
    }
  }
`;

export const listProjectsQuery = gql`
  ${ProjectFields}

  query ListProjects($criteria: ListProjectsCriteria = {}) {
    asAdmin {
      listProjects(criteria: $criteria) {
        items {
          ...ProjectFields
        }
      }
    }
  }
`;

export const addProjectMutation = gql`
  ${ProjectFields}

  mutation AddProject(
    $aircraftId: String!
    $clientId: String!
    $cameraId: String
    $description: String!
    $endDate: AWSDateTime!
    $hourlyRate: Float!
    $estimateManHrs: Float!
    $name: String!
    $startDate: AWSDateTime!
    $status: ProjectStatus!
  ) {
    asAdmin {
      addProject(
        input: {
          clientId: $clientId
          cameraId: $cameraId
          description: $description
          endDate: $endDate
          startDate: $startDate
          hourlyRate: $hourlyRate
          estimateManHrs: $estimateManHrs
          name: $name
          aircraftId: $aircraftId
          status: $status
        }
      ) {
        ...ProjectFields
      }
    }
  }
`;

export const updateProjectMutation = gql`
  ${ProjectFields}

  mutation UpdateProject(
    $id: ID!
    $clientId: ID!
    $aircraftId: String
    $cameraId: String
    $description: String
    $endDate: AWSDateTime
    $hourlyRate: Float
    $estimateManHrs: Float
    $name: String
    $startDate: AWSDateTime
    $status: ProjectStatus
  ) {
    asAdmin {
      updateProject(
        clientId: $clientId
        id: $id
        input: {
          name: $name
          startDate: $startDate
          hourlyRate: $hourlyRate
          estimateManHrs: $estimateManHrs
          endDate: $endDate
          description: $description
          aircraftId: $aircraftId
          cameraId: $cameraId
          status: $status
        }
      ) {
        ...ProjectFields
      }
    }
  }
`;

export const deleteProjectMutation = gql`
  mutation DeleteProject($clientId: ID!, $id: ID!) {
    asAdmin {
      deleteProject(clientId: $clientId, id: $id) {
        id
      }
    }
  }
`;

export const addProjectUserMutation = gql`
  mutation AddProjectUser($input: CreateProjectUserRequest!) {
    asAdmin {
      addProjectUser(input: $input) {
        projectId
        userId
        role
        clientId
      }
    }
  }
`;

export const removeProjectUserMutation = gql`
  mutation RemoveProjectUser($projectId: ID!, $userId: ID!) {
    asAdmin {
      removeProjectUser(projectId: $projectId, userId: $userId) {
        projectId
        userId
      }
    }
  }
`;
