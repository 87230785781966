import styled from "styled-components";

export const PageHeading = styled.h2`
  margin: 64px 64px;
  font-size: 34px;
  font-weight: bold;
  color: black;
`;

export const PageMain = styled.div`
  margin: 0;
  width: 100%;

  flex-grow: 1;

  display: flex;
  flex-direction: column;

  overflow-y: auto;
`;

export const PageContent = styled.div`
  padding: 32px 64px;
`;
