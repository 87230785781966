import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";

import { ProjectRole } from "../../types/admin/globalTypes";
import { UserFields } from "../../types/admin/UserFields";

//import { USER_ROLE_OPTIONS } from "../../common";

import { snackbar } from "../../hooks/useNotifications";

import { useAdminApi } from "../../hooks/useAdminApi";
import { useProject } from "../../hooks/params/useProject";
import { useTask } from "../../hooks/params/useTask";

import useFiltered from "../../hooks/useFiltered";
import useSorted, { sortByProperty } from "../../hooks/useSorted";

import { Button } from "../Button";
import { Dialog } from "../Dialog";
//import { Select } from "../Select";
import { TextInput } from "../TextInput";

import { ProjectUserAssignList } from "./ProjectUserAssignList";
import { TaskUserAssignList } from "./TaskUserAssignList";

import { Checkbox } from "@rmwc/checkbox";
import "@rmwc/checkbox/styles";

import {
  DialogActions,
  DialogButton,
  DialogContent,
  DialogOnCloseEventT,
  DialogTitle as DialogTitleBase,
} from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import styled from "styled-components";

const DialogTitle = styled(DialogTitleBase)`
  display: flex;
  align-items: center;

  border: none;

  > span {
    margin: 20px 3rem 0 0;
    flex-grow: 1;
  }

  > button {
    margin: 20px 0 0;
  }
`;

const FilterControls = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    margin-right: 1rem;
    :last-child {
      margin-right: 0;
    }
  }
`;

export const UserAssignDialog: React.FC = () => {
  const history = useHistory();
  const { state: locationState } = useLocation<{ referrer: string }>();

  const { users, addProjectUser, removeProjectUser, assignUserToTask } = useAdminApi();

  const project = useProject();
  const task = useTask();

  const sorted = useSorted(users, sortByProperty("name"));

  const [allowAll, setAllowAll] = useState<boolean>(false);
  /* const [filterByRole, setFilterByRole] = useState<ProjectRole | "all">("all"); */

  const [searchString, setSearchString] = useState<string>("");

  const isTask = !!task;

  const filtered = useFiltered(sorted, (it) => {
    if (!allowAll && task && !project?.users?.items.find((jt) => jt.userId === it.id)) return false;

    return (
      it.name?.toLowerCase().includes(searchString.toLowerCase()) ||
      it.email.toLowerCase().includes(searchString.toLowerCase())
    );
  });

  const onAssignUser = async (role: ProjectRole | null, user: UserFields) => {
    if (!project) return;

    if (task) {
      await assignUserToTask({ taskId: task.id, userId: user.id });
      snackbar.notify({ title: "User assigned to task successfully." });
      return;
    }

    if (!role) {
      snackbar.notify({ icon: "warning", title: "Please select a project role!" });
      return;
    }

    await addProjectUser({ input: { projectId: project.id, userId: user.id, clientId: "", role } });
    snackbar.notify({ title: "User assigned to project successfully." });
  };

  const onRemoveUser = async (user: UserFields) => {
    if (!project) return;

    if (task) {
      snackbar.notify({ title: "NOT IMPLEMENTED YET!" });
      return;
    }

    await removeProjectUser(project.id, user.id);
    snackbar.notify({ title: "User removed from project successfully." });
  };

  const onClose = (ev: DialogOnCloseEventT) => {
    if (ev.detail.action === "destroy") return;

    if (ev.detail.action === "accept" && locationState?.referrer === "/projects/new") {
      history.replace(`/projects/${project?.id}/tasks/new`, { referrer: "/projects/new" });
      return;
    }

    history.goBack();
  };

  if (!project) return <></>;

  return (
    <Dialog preventOutsideDismiss open onClose={onClose}>
      <DialogTitle>
        <span>Manage {isTask ? "Task" : "Project"} Users</span>
        <Button raised onClick={() => history.replace(`/projects/${project.id}/people/new`, locationState)}>
          Create New
        </Button>
      </DialogTitle>

      <DialogContent>
        <FilterControls>
          <TextInput
            placeholder="Search by name or email."
            value={searchString}
            onChange={(ev) => setSearchString(ev.target.value)}
          />
          {/* <Select
            outlined
            enhanced
            options={[{ label: "All", value: "all" }, ...USER_ROLE_OPTIONS]}
            value={filterByRole}
            onChange={(ev: any) => setFilterByRole(ev.detail.value)}
          /> */}
          <Checkbox
            label="Project Only"
            checked={!allowAll}
            onChange={(ev) => setAllowAll(!ev.currentTarget.checked)}
          />
        </FilterControls>

        {!task ? (
          <ProjectUserAssignList
            items={filtered}
            assigned={project.users?.items || []}
            onAssignUser={onAssignUser}
            onRemoveUser={onRemoveUser}
          />
        ) : (
          <TaskUserAssignList
            items={filtered}
            assigned={task.users?.items || []}
            onAssignUser={onAssignUser}
            onRemoveUser={onRemoveUser}
          />
        )}
      </DialogContent>

      <DialogActions>
        <DialogButton action="close">Close</DialogButton>
        <DialogButton raised isDefaultAction action="accept">
          Done
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
