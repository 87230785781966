import React, { useEffect } from "react";

import { Controller, UseFormMethods, useFieldArray } from "react-hook-form";

import { MarkupType } from "../../types/admin/globalTypes";

import { TaskFormContentProps, TaskFormValuesT } from "./TaskFormDialog";

import { Button } from "../Button";
import { Label } from "../Label";
import { Select } from "../Select";
import { TextInput } from "../TextInput";
import { FormField, FormRow, MultiItemSection, SectionTitle, AddItemContainer } from "../CommonForm";

import { Switch } from "@rmwc/switch";
import "@rmwc/switch/styles";

import styled from "styled-components";

const PartForm = styled.div`
  margin-bottom: 2rem;

  ${FormRow} {
    margin: 0;
  }
`;

export const PartFormFields: React.FC<{
  title: string;
  keyPrefix: "parts" | "tools";
  labelPrefix: string;
  form: UseFormMethods<TaskFormValuesT>;
}> = ({ title, keyPrefix, labelPrefix, form }) => {
  const { control, errors, register, setValue, watch } = form;
  const { fields, append, remove } = useFieldArray({ name: keyPrefix, control });

  const values = watch(keyPrefix);

  const markupTypeOpts: { label: string; value: string }[] = [];

  for (const [k, v] of Object.entries(MarkupType)) {
    markupTypeOpts.push({ label: k[0].toUpperCase() + k.slice(1), value: v });
  }

  const onAddClick = () => {
    append({ name: "", number: "", quantity: "1", cost: "0.00", isBillable: false, leadTimeDays: "1" });
  };

  const onRemoveClick = (index: number) => {
    remove(index);
  };

  const onBillableChanged = (n: string, v: boolean, i: number) => {
    setValue(
      keyPrefix,
      fields.map(({ id, ...it }, j) => (i === j ? { ...(values?.[i] || it), isBillable: v } : it)),
    );
    fields[i].isBillable = v;
  };

  return (
    <MultiItemSection>
      <SectionTitle>{title}</SectionTitle>
      {fields.map((field, i) => (
        <PartForm key={field.id}>
          <FormRow>
            <FormField>
              <Label htmlFor={`${keyPrefix}.${i}.name`}>{labelPrefix} Name</Label>
              <TextInput
                name={`${keyPrefix}.${i}.name`}
                isInvalid={`${keyPrefix}.${i}.name` in errors}
                defaultValue={field.name}
                ref={register({ required: true })}
              />
            </FormField>

            <FormField>
              <Label htmlFor={`${keyPrefix}.${i}.number`}>{labelPrefix} Number</Label>
              <TextInput
                name={`${keyPrefix}.${i}.number`}
                isInvalid={`${keyPrefix}.${i}.number` in errors}
                defaultValue={field.number}
                ref={register}
              />
            </FormField>
          </FormRow>

          <FormRow>
            <FormField>
              <Label htmlFor={`${keyPrefix}.${i}.quantity`}>{labelPrefix} Quantity</Label>
              <TextInput
                type="number"
                name={`${keyPrefix}.${i}.quantity`}
                ref={register({ required: true })}
                isInvalid={`${keyPrefix}.${i}.quantity` in errors}
                defaultValue={field.quantity}
              />
            </FormField>

            <FormField>
              <Label htmlFor={`${keyPrefix}.${i}.cost`}>{labelPrefix} Cost</Label>
              <TextInput
                type="number"
                name={`${keyPrefix}.${i}.cost`}
                defaultValue={field.cost}
                ref={register({ required: true })}
                isInvalid={`${keyPrefix}.${i}.cost` in errors}
              />
            </FormField>
          </FormRow>

          <FormRow>
            <FormField>
              <Label htmlFor={`${keyPrefix}.${i}.leadTimeDays`}>Lead Time (Days)</Label>
              <TextInput
                type="number"
                name={`${keyPrefix}.${i}.leadTimeDays`}
                defaultValue={field.leadTimeDays}
                isInvalid={`${keyPrefix}.${i}.leadTimeDays` in errors}
                ref={register({ required: true })}
              />
            </FormField>

            <FormField>
              <Label htmlFor={`${keyPrefix}.${i}.isBillable`}>Billable?</Label>
              <Controller
                {...{ control }}
                name={`${keyPrefix}.${i}.isBillable`}
                defaultValue={field.isBillable}
                render={(f) => (
                  <Switch
                    defaultChecked={f.value}
                    onChange={() => onBillableChanged(`${keyPrefix}.${i}.isBillable`, !f.value, i)}
                  />
                )}
              />
            </FormField>
          </FormRow>

          <FormRow>
            <FormField>
              <Label htmlFor={`${keyPrefix}.${i}.markupType`}>Markup Type</Label>
              <Controller
                {...{ control }}
                name={`${keyPrefix}.${i}.markupType`}
                defaultValue={MarkupType.percentage}
                disabled={!values?.[i]?.isBillable}
                as={<Select outlined enhanced options={markupTypeOpts} />}
              />
            </FormField>

            <FormField>
              <Label htmlFor={`${keyPrefix}.${i}.markup`}>Markup {values?.[i]?.markupType}</Label>
              <TextInput
                type="number"
                name={`${keyPrefix}.${i}.markup`}
                defaultValue={field.markup}
                disabled={!values?.[i]?.isBillable}
                isInvalid={`${keyPrefix}.${i}.markup` in errors}
                ref={register}
              />
            </FormField>
          </FormRow>

          {/* <FormRow>
                <FormField>
                  <Label htmlFor={`${keyPrefix}.${i}.markupCost`}>Total Cost</Label>
                  <TextInput disabled type="number" name={`${keyPrefix}.${i}.markupCost`} value={field.markupCost} />
                </FormField>
              </FormRow> */}

          <Button
            style={{ float: "right", color: "red" }}
            icon="remove_circle"
            type="button"
            onClick={() => onRemoveClick(i)}
          >
            Remove
          </Button>
        </PartForm>
      ))}

      <AddItemContainer>
        <Button icon="add_circle" type="button" onClick={onAddClick}>
          Add {labelPrefix}
        </Button>
      </AddItemContainer>
    </MultiItemSection>
  );
};

export const TaskFormPartsContent: React.FC<TaskFormContentProps> = ({ form, style }) => {
  return (
    <div {...{ style }}>
      <PartFormFields {...{ form }} title="Parts" keyPrefix="parts" labelPrefix="Part" />
      <PartFormFields {...{ form }} title="Tools" keyPrefix="tools" labelPrefix="Tool" />
    </div>
  );
};
