const dev = {
  firebase: {
    apiKey: "AIzaSyByyqjxC6ZEaOOicuTRTYFGU-BtBR_tfXw",
    authDomain: "foxtrot-dev-383b3.firebaseapp.com",
    databaseURL: "https://foxtrot-dev-383b3.firebaseio.com",
    projectId: "foxtrot-dev-383b3",
    storageBucket: "foxtrot-dev-383b3.appspot.com",
  },
  aws: {
    API: {
      apiKey: process.env.REACT_APP_LOOKUP_GRAPHQL_API_KEY,
      endpoints: [
        {
          name: "FoxtrotAPI",
          endpoint: process.env.REACT_APP_LOOKUP_API_URL,
        },
      ],
    },
  },
};

const prod = {
  firebase: {
    apiKey: "AIzaSyAEZBT9Nwb-sXJhLwQ4qF1ggcuhyqvRrJE",
    authDomain: "foxtrot-prod.firebaseapp.com",
    databaseURL: "https://foxtrot-prod.firebaseio.com",
    projectId: "foxtrot-prod",
    storageBucket: "foxtrot-prod.appspot.com",
    messagingSenderId: "898806114282",
    appId: "1:898806114282:web:a853c42c4c2abefd897493",
    measurementId: "G-QP0HDKGPRV"
  },
  aws: {
    API: {
      apiKey: process.env.REACT_APP_LOOKUP_GRAPHQL_API_KEY,
      endpoints: [
        {
          name: "FoxtrotAPI",
          endpoint: process.env.REACT_APP_LOOKUP_API_URL,
        },
      ],
    },
  },
};

export default process.env.REACT_APP_STAGE === "prod" ? prod : dev;
