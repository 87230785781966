import React, { useEffect, useState } from "react";
import { Route, useHistory, useLocation, useParams } from "react-router";
import { Switch } from "react-router-dom";

import useLayout from "../../../hooks/useLayout";
import { dialogQueue } from "../../../hooks/useDialogQueue";
import { snackbar } from "../../../hooks/useNotifications";

import { useAdminApi } from "../../../hooks/useAdminApi";
import { useClient } from "../../../hooks/params/useClient";

import { PageHeader } from "../../../components/PageHeader";
import { PageContent, PageMain } from "../../../components/PageStyles";

import { TabContainer } from "../../../components/TabContainer";

import { AircraftList } from "../../../components/aircraft/AircraftList";
import { AircraftFormDialog } from "../../../components/aircraft/AircraftFormDialog";

import { ProjectList } from "../../../components/projects/ProjectList";
import { ProjectFormDialog } from "../../../components/projects/ProjectFormDialog";

import { UserList } from "../../../components/users/UserList";

import { ClientFormDialog } from "../../../components/clients/ClientFormDialog";

import { Tab, TabBar } from "@rmwc/tabs";
import "@rmwc/tabs/styles";

interface ParamsT {
  clientId: string;
}

export const ClientViewPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { clientId } = useParams<ParamsT>();

  const { setBreadCrumbs } = useLayout();

  const { aircraft: allAircraft, projects, users, deleteClient } = useAdminApi();

  const client = useClient();
  const aircraft = allAircraft.filter((it) => it.clientId === client?.id);

  const PAGE_TABS = [
    {
      icon: "",
      label: "Projects",
      path: `/clients/${clientId}/projects`,
    },
    {
      icon: "",
      label: "Aircraft",
      path: `/clients/${clientId}/aircraft`,
    },
    {
      icon: "",
      label: "People",
      path: `/clients/${clientId}/people`,
    },
  ];

  const [activeTabIndex, setTabIndex] = useState<number>(
    PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path)),
  );

  useEffect(() => {
    if (location.pathname.endsWith("/edit")) return;
    const tabIndex = PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path));
    if (tabIndex === activeTabIndex) return;
    setTabIndex(tabIndex === -1 ? 0 : tabIndex);
  }, [location, PAGE_TABS]);

  useEffect(() => {
    if (!setBreadCrumbs || !client) return;
    setBreadCrumbs([
      {
        label: "Customers",
        path: "/clients",
      },
      {
        label: client.name,
        path: `/clients/${client.id}`,
      },
    ]);
  }, [setBreadCrumbs, client]);

  const onTabActivated = (ev: CustomEvent) => {
    const { path } = PAGE_TABS[ev.detail.index];
    if (location.pathname.startsWith(path) || location.pathname.endsWith("/new")) return;
    history.replace(path);
  };

  const onDelete = async () => {
    if (!client) return;

    const hasConfirmed = await dialogQueue.confirm({
      title: "Are you sure?",
      body: `Are you sure you want to delete customer: ${client?.name}`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;

    await deleteClient({ id: client?.id });
    snackbar.notify({ title: "Customer removed successfully!" });
    history.goBack();
  };

  if (!client)
    return (
      <>
        <Route exact path="/clients/new">
          <ClientFormDialog action="create" />
        </Route>
      </>
    );

  return (
    <>
      <Route exact path="/clients/:clientId/edit">
        <ClientFormDialog action="update" />
      </Route>

      <Route exact path="/clients/:clientId/aircraft/new">
        <AircraftFormDialog action="create" />
      </Route>

      <Route exact path="/clients/:clientId/aircraft/:aircraftId/edit">
        <AircraftFormDialog action="update" />
      </Route>

      <Route exact path="/clients/:clientId/projects/new">
        <ProjectFormDialog action="create" />
      </Route>

      <PageHeader
        editable
        title={client?.name || ""}
        subtitle={client?.clientType ? client.clientType : "Client"}
        onEditClick={() => history.push(`/clients/${client?.id}/edit`)}
        onDeleteClick={() => onDelete()}
      />

      <TabContainer>
        <TabBar {...{ activeTabIndex }} onActivate={onTabActivated}>
          {PAGE_TABS.map((it, i) => (
            <Tab key={i}>{it.label}</Tab>
          ))}
        </TabBar>
      </TabContainer>

      <PageMain>
        <PageContent>
          <Switch>
            <Route path="/clients/:clientId/aircraft">
              <AircraftList key="aircraft" items={aircraft} />,
            </Route>
            <Route path="/clients/:clientId/people">
              <UserList key="people" items={users.filter((it) => it.clientId === client?.id)} />
            </Route>
            <Route path={["/clients/:clientId", "/clients/:clientId/projects"]}>
              <ProjectList
                key="projects"
                hiddenFields={["Customer"]}
                items={projects.filter((it) => it.clientId === client?.id)}
              />
            </Route>
          </Switch>
        </PageContent>
      </PageMain>
    </>
  );
};
