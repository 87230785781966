import React from "react";

import { TimesheetTable } from "../../../components/timesheets/TimesheetTable";

import { useProject } from "../../../hooks/params/useProject";

export const ProjectTimesheetsPage: React.FC = () => {
  const project = useProject();

  if (!project) return <></>;

  return (
    <>
      <TimesheetTable filterType="project" filterId={project.id} />
    </>
  );
};
