/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BillingType {
  Fixed = "Fixed",
  TANDM = "TANDM",
  TBD = "TBD",
}

export enum ClientType {
  Company = "Company",
  Individual = "Individual",
}

export enum EntityType {
  AIRCRAFT = "AIRCRAFT",
  MESSAGE = "MESSAGE",
  TASK = "TASK",
}

export enum FileType {
  Document = "Document",
  Image = "Image",
  Video = "Video",
}

export enum MarkupType {
  dollar = "dollar",
  percentage = "percentage",
}

export enum NotificationEntityType {
  PROJECT = "PROJECT",
  PROJECTUSER = "PROJECTUSER",
  TASK = "TASK",
  TASKATTACHMENT = "TASKATTACHMENT",
  TASKMESSAGE = "TASKMESSAGE",
  TASKUSER = "TASKUSER",
}

export enum NotificationEvent {
  INSERT = "INSERT",
  MODIFY = "MODIFY",
  REMOVE = "REMOVE",
}

export enum ProjectRole {
  customer = "customer",
  manager = "manager",
  technician = "technician",
}

export enum ProjectStatus {
  Accepted = "Accepted",
  Declined = "Declined",
  Estimated = "Estimated",
}

export enum RequestTaskStatus {
  Grounding = "Grounding",
  Recommended = "Recommended",
  Regulatory = "Regulatory",
}

export enum TaskFlag {
  OnHold = "OnHold",
  Warning = "Warning",
}

export enum TaskStatus {
  Completed = "Completed",
  Declined = "Declined",
  InProgress = "InProgress",
  OnHold = "OnHold",
  Requested = "Requested",
  Upcoming = "Upcoming",
}

export enum TaskType {
  Scheduled = "Scheduled",
  Squat = "Squat",
}

export enum TimeSheetEntryStatus {
  Completed = "Completed",
  InProgress = "InProgress",
}

export interface AddressInput {
  street1: string;
  street2?: string | null;
  city: string;
  state: string;
  country: string;
  zip: string;
}

export interface CreateAttachmentRequest {
  id: string;
  file: S3ObjectInput;
  fileType?: FileType | null;
  fileName?: string | null;
  mimeType?: string | null;
  entityType: EntityType;
  entityId: string;
}

export interface CreateLocationRequest {
  name: string;
  address: AddressInput;
  geometry: GeometryInput;
}

export interface CreateProjectUserRequest {
  clientId: string;
  projectId: string;
  userId: string;
  role: ProjectRole;
}

export interface CreateTaskRequest {
  name: string;
  parentId: string;
  projectId: string;
  description?: string | null;
  taskNumber?: string | null;
  behindSchedule?: boolean | null;
  inReview?: boolean | null;
  estimateManHrs?: number | null;
  hourlyRate?: number | null;
  status?: TaskStatus | null;
  taskType?: TaskType | null;
  billing?: BillingType | null;
  amount?: number | null;
  dueDate?: any | null;
  scheduledDate?: any | null;
  partName?: string | null;
  partNumber?: string | null;
  toolName?: string | null;
  toolNumber?: string | null;
  orderIndex?: number | null;
  issues?: string[] | null;
  flag?: TaskFlag | null;
}

export interface CreateTimeEntrySheetRequest {
  fromDT: any;
  toDT: any;
  projectId?: string | null;
  taskId?: string | null;
  userId?: string | null;
}

export interface GeometryInput {
  type: string;
  coordinates: (number | null)[];
}

export interface ListProjectsCriteria {
  clientId?: string | null;
}

export interface ListTasksCriteria {
  parentId?: string | null;
  status?: (TaskStatus | null)[] | null;
}

export interface MessageCreateRequest {
  message?: string | null;
  taskId: string;
  projectId: string;
  isClientVisible?: boolean | null;
  isTechnicianVisible?: boolean | null;
}

export interface PayChexInput {
  id?: string | null;
  orgUnit?: string | null;
}

export interface S3ObjectInput {
  bucket: string;
  region: string;
  key: string;
}

export interface UpdateLocationRequest {
  name?: string | null;
  address?: AddressInput | null;
  geometry?: GeometryInput | null;
}

export interface UpdateTaskRequest {
  name?: string | null;
  taskNumber?: string | null;
  behindSchedule?: boolean | null;
  description?: string | null;
  inReview?: boolean | null;
  estimateManHrs?: number | null;
  hourlyRate?: number | null;
  status?: TaskStatus | null;
  taskType?: TaskType | null;
  billing?: BillingType | null;
  amount?: number | null;
  dueDate?: any | null;
  scheduledDate?: any | null;
  partName?: string | null;
  partNumber?: string | null;
  toolName?: string | null;
  toolNumber?: string | null;
  orderIndex?: number | null;
  flag?: TaskFlag | null;
  issues?: string[] | null;
  parts?: partInput[] | null;
  tools?: toolInput[] | null;
}

export interface UpdateTimeSheetEntryRequest {
  userId?: string | null;
  projectId?: string | null;
  taskId?: string | null;
  fromDT?: any | null;
  toDT?: any | null;
}

export interface partInput {
  name: string;
  number?: string | null;
  cost?: number | null;
  leadTimeDays?: number | null;
  quantity: number;
  isBillable: boolean;
  markupType?: MarkupType | null;
  markup?: number | null;
  markupCost?: number | null;
}

export interface toolInput {
  name: string;
  number?: string | null;
  cost?: number | null;
  leadTimeDays?: number | null;
  quantity: number;
  isBillable: boolean;
  markupType?: MarkupType | null;
  markup?: number | null;
  markupCost?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
