import { useMemo } from "react";

export interface SortFunction<T> {
  (a: T, b: T): number;
}

export const sortByProperty = <T>(prop: string) => (a: T, b: T) => {
  if ((a as any)[prop] < (b as any)[prop]) return -1;
  if ((a as any)[prop] > (b as any)[prop]) return 1;
  return 0;
};

const useSorted = <T>(source: T[], sortBy: SortFunction<T>) => {
  return useMemo(() => {
    return source.sort(sortBy);
  }, [source, sortBy]);
};

export default useSorted;
