import React, { useState } from "react";

import { useHistory } from "react-router";

import { ClientFields } from "../../types/admin/ClientFields";

import { useAdminApi } from "../../hooks/useAdminApi";

import { dialogQueue } from "../../hooks/useDialogQueue";
import { snackbar } from "../../hooks/useNotifications";

import useFiltered from "../../hooks/useFiltered";
import { usePagenator } from "../../hooks/usePagenator";

import { Button } from "../../components/Button";
import { SearchInput } from "../TextInput";

import {
  DataCellTools,
  Table,
  TableContainer,
  TableHead,
  TableTitle,
  TableToolbarTitle,
  TitleCount,
} from "../../components/Table";

import { PagenatorControls } from "../PagenatorControls";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import Palette from "../../palette.json";

interface ClientListProps {
  items: ClientFields[];
}

export const ClientList: React.FC<ClientListProps> = ({ items }) => {
  const history = useHistory();

  const { deleteClient } = useAdminApi();

  const [search, setSearch] = useState<string>("");
  const filtered = useFiltered(items, (it) => !!it.name?.toLowerCase().includes(search.toLowerCase()));

  const { pageRange, ...controls } = usePagenator(8, filtered.length);

  const onDelete = async (client: ClientFields) => {
    const hasConfirmed = await dialogQueue.confirm({
      title: "Are you sure?",
      body: `Are you sure you want to delete customer: ${client.name}`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;

    await deleteClient({ id: client.id });
    snackbar.notify({ title: "Customer removed successfully!" });
  };

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          Active Customers <TitleCount>({items.length})</TitleCount>
        </TableToolbarTitle>

        <SearchInput placeholder="Search by Name" value={search} onChange={(ev) => setSearch(ev.currentTarget.value)} />

        <Button data-test-id="btn-create" raised onClick={() => history.push(`/clients/new`)}>
          Add Client
        </Button>
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead cols={["Name", "Type", "Address", ""]} />

          <DataTableBody data-test-id="clients-list">
            {items.length === 0 && (
              <DataTableRow>
                <DataTableCell colSpan={7} style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}>
                  - No Results -
                </DataTableCell>
              </DataTableRow>
            )}
            {filtered.slice(...pageRange).map((it, i) => (
              <DataTableRow key={i} onClick={() => history.push(`/clients/${it.id}`)}>
                <DataTableCell>{it.name}</DataTableCell>
                <DataTableCell>{it.clientType}</DataTableCell>
                <DataTableCell>{it.address}</DataTableCell>
                <DataTableCell>
                  <DataCellTools>
                    <IconButton
                      data-test-id="btn-edit"
                      icon="edit"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        history.push(`/clients/${it.id}/edit`);
                      }}
                    />
                    <IconButton
                      data-test-id="btn-delete"
                      icon="delete"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        onDelete(it);
                      }}
                    />
                  </DataCellTools>
                </DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </Table>

      <PagenatorControls {...controls} />
    </TableContainer>
  );
};
