import React, { createContext, useContext, useEffect, useState } from "react";

import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";

import { getTenantByPrefixQuery } from "../queries/lookup/lookup";
import { GetTenantByPrefix } from "../types/lookup/GetTenantByPrefix";
import { TenantInfo } from "../types/lookup/TenantInfo";

import styled from "styled-components";

interface ThemeOptionsT {
  [key: string]: string;

  primary: string;
  secondary: string;
  background: string;
}

interface MultiTenantContextT {
  tenantId?: string | null;
  tenantInfo?: TenantInfo | null;
  theme: ThemeOptionsT;
}

const LoadingScreen = styled.div`
  width: 100vw;
  height: 100vh;
  background: #042459;
`;

export const MultiTenantContext = createContext<MultiTenantContextT>({
  theme: {
    primary: "#1665d8",
    secondary: "#00a1ff",
    background: "#042459",
  },
});

// XXX - Store tenant information into LocalStorage to decrease page loading time.
export const MultiTenantProvider: React.FC<{ prefix: string }> = ({ prefix, children }) => {
  const [tenantId, setTenantId] = useState<string | undefined | null>(undefined);
  const [tenantInfo, setTenantInfo] = useState<TenantInfo | null>(null);

  const [theme, setTheme] = useState<ThemeOptionsT>({
    primary: "#1665d8",
    secondary: "#00a1ff",
    background: "#042459",
  });

  console.info("TENANT_PREFIX:", prefix);
  console.info("LOOKUP_API_URL:", process.env.REACT_APP_LOOKUP_API_URL);

  useEffect(() => {
    if (tenantId !== undefined) return;

    const client = new AWSAppSyncClient({
      url: process.env.REACT_APP_LOOKUP_API_URL,
      region: "us-east-1",
      auth: {
        type: AUTH_TYPE.API_KEY, // or type: awsconfig.aws_appsync_authenticationType,
        apiKey: process.env.REACT_APP_LOOKUP_GRAPHQL_API_KEY,
      },
      offlineConfig: {
        keyPrefix: "lookupApiPersist",
      },
      disableOffline: false,
    });

    client
      .query<GetTenantByPrefix>({
        query: getTenantByPrefixQuery,
        variables: { prefix },
      })
      .then((result) => {
        const ret = result?.data.asAnon?.getTenantByPrefix;

        if (!ret) {
          console.warn("Failed to get multi-tenant info.");
          setTenantId(null);
          return;
        }

        const { id, theme } = ret;

        setTenantId(id || null);
        setTenantInfo(ret);
        console.info("TENANT_INFO:", ret);

        if (theme) {
          setTheme({
            primary: theme.primaryColor || "#1665d8",
            secondary: theme.secondaryColor || "#00a1ff",
            background: "#042459",
          });
        }
      })
      .catch((e) => console.log(e));
  }, [tenantId, prefix]);

  if (tenantId === undefined) return <LoadingScreen />;
  if (tenantId === null) return <>Invalid Tenant</>;
  return <MultiTenantContext.Provider value={{ tenantId, tenantInfo, theme }}>{children}</MultiTenantContext.Provider>;
};

export default (): MultiTenantContextT => useContext(MultiTenantContext);
