import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";

import { Controller } from "react-hook-form";

import { TimesheetFields } from "../../types/admin/TimesheetFields";

import { useUser } from "../../hooks/params/useUser";

import { DialogActions, DialogButton, DialogContent, DialogOnClosedEventT, DialogTitle } from "@rmwc/dialog";
import { Dialog } from "../Dialog";

import { Table, TableContainer, TableHead, DataCellTools } from "../Table";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import { CircularProgress } from "@rmwc/circular-progress";
import "@rmwc/circular-progress/styles";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import { ThemeProvider } from "@rmwc/theme";
import "@rmwc/theme/styles";

import dayjs from "dayjs";
import numeral from "numeral";

import Palette from "../../palette.json";

import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";

import styled from "styled-components";

interface ParamsT {
  userId: string;
  activityId: string;
}

interface ActionItemPickerProps {
  type: string;
  defaultValue: any;
  label: string;
  onChange: any;
}

interface TimesheetActivityEditDialogProps {
  timesheets: TimesheetFields[];
  isLoading: boolean;
  onSave: any;
}

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionItemPicker = styled(TextField)<React.PropsWithChildren<ActionItemPickerProps>>`
  margin-bottom: 16px;
`;

export const TimesheetActivityEditDialog: React.FC<TimesheetActivityEditDialogProps> = ({
  timesheets,
  isLoading,
  onSave,
}) => {
  const history = useHistory();
  const { userId, activityId } = useParams<ParamsT>();
  const [timesheet] = timesheets.filter((activity) => activity.id === activityId);

  const user = useUser();

  const [clockInDateTime, setClockInDateTime] = useState(timesheet?.fromDT);
  const [clockOutDateTime, setClockOutDateTime] = useState(timesheet?.toDT);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const onClose = async () => {
    history.goBack();
  };

  const save = () => {
    if (!timesheet) return;

    const updates = {
      id: timesheet.id,
      userId: timesheet?.user?.id,
      input: {
        userId: timesheet?.user?.id,
        projectId: timesheet.projectId,
        taskId: timesheet.taskId,
        fromDT: new Date(clockInDateTime),
        toDT: new Date(clockOutDateTime),
      },
    };

    onSave(updates);
  };

  const onClockInUpdate = (value: any) => {
    const datetime = value.currentTarget.value;
    if (datetime) {
      setClockInDateTime(datetime);
    }
  };

  const onClockOutUpdate = (value: any) => {
    const datetime = value.currentTarget.value;
    if (datetime) {
      setClockOutDateTime(datetime);
    }
  };

  useEffect(() => {
    // * if clock in and clock out datetimes have been changed, save button is enabled
    if (clockInDateTime !== timesheet?.fromDT || clockOutDateTime !== timesheet?.toDT) {
      setSaveButtonDisabled(false);
    }

    // * if clock in time is greater than clock out time, save button is disabled
    if (new Date(clockInDateTime) > new Date(clockOutDateTime)) {
      setSaveButtonDisabled(true);
    }
  }, [clockInDateTime, clockOutDateTime]);

  return (
    <Dialog open preventOutsideDismiss onClose={onClose}>
      <DialogTitle>Editing timesheet hours</DialogTitle>

      <DialogContent>
        {typeof timesheet !== "undefined" && (
          <ActionsWrapper>
            <ActionItemPicker
              type="datetime-local"
              defaultValue={dayjs(clockInDateTime).format("YYYY-MM-DDTHH:mm:ss")}
              label={"Clock in"}
              onChange={onClockInUpdate}
            />

            <ActionItemPicker
              type="datetime-local"
              defaultValue={dayjs(clockOutDateTime).format("YYYY-MM-DDTHH:mm:ss")}
              label={"Clock out"}
              onChange={onClockOutUpdate}
            />
          </ActionsWrapper>
        )}
      </DialogContent>

      <DialogActions>
        <ThemeProvider options={{ primary: Palette.MediumGrey }}>
          <DialogButton danger action="cancel">
            Cancel
          </DialogButton>
        </ThemeProvider>
        <div style={{ flexGrow: 1 }} />

        <ThemeProvider options={{ primary: Palette.Green }}>
          <DialogButton isDefaultAction raised action="accept" onClick={save} disabled={saveButtonDisabled}>
            Save
          </DialogButton>
        </ThemeProvider>
      </DialogActions>
    </Dialog>
  );
};
