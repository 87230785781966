import styled from "styled-components";

export const Label = styled.label`
  margin: 0;
  padding: 0;

  font-size: 12px;
  font-weight: 500;

  text-transform: uppercase;

  color: #9ea0a5;

  display: flex;
`;
