import gql from "graphql-tag";

import { UserFields } from "./users";

export const TimesheetFields = gql`
  ${UserFields}

  fragment TimesheetFields on TimeSheetEntry {
    id

    projectId
    taskId
    userId

    projectSummary
    taskSummary

    status

    amountMins

    fromDT
    toDT

    date

    startLat
    startLng

    stopLat
    stopLng

    userFromDT
    userToDT

    user {
      ...UserFields
    }
  }
`;

export const addTimeSheetEntryMutation = gql`
  ${TimesheetFields}

  mutation AddTimeSheetEntry($input: CreateTimeEntrySheetRequest!) {
    asAdmin {
      addTimeSheetEntry(input: $input) {
        ...TimesheetFields
      }
    }
  }
`;

export const listTimesheetsQuery = gql`
  ${TimesheetFields}

  query ListTimesheets($fromDT: AWSDateTime!, $toDT: AWSDateTime!) {
    asAdmin {
      listTimeSheets(fromDT: $fromDT, toDT: $toDT) {
        items {
          ...TimesheetFields
        }
      }
    }
  }
`;

export const updateTimeSheetEntryMutation = gql`
  ${TimesheetFields}

  mutation UpdateTimeSheetEntry($id: ID!, $userId: ID!, $input: UpdateTimeSheetEntryRequest!) {
    asAdmin {
      updateTimeSheetEntry(id: $id, userId: $userId, input: $input) {
        ...TimesheetFields
      }
    }
  }
`;

export const deleteTimeSheetEntryMutation = gql`
  mutation DeleteTimeSheetEntry($id: ID!, $userId: ID!) {
    asAdmin {
      deleteTimeSheetEntry(id: $id, userId: $userId) {
        id
      }
    }
  }
`;
