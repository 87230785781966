import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { appendErrors, Controller, useForm } from "react-hook-form";

import { CreateLocationRequest } from "../../types/admin/globalTypes";

import { useAdminApi } from "../../hooks/useAdminApi";
import { useLocationItem } from "../../hooks/params/useLocationItem";

import { snackbar } from "../../hooks/useNotifications";

import { Label } from "../Label";
import { Select } from "../Select";
import { TextInput } from "../TextInput";
import { Dialog } from "../Dialog";
import { CommonForm, FormField, FormRow } from "../CommonForm";

import { DialogActions, DialogButton, DialogContent, DialogOnClosedEventT, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import { ThemeProvider } from "@rmwc/theme";
import "@rmwc/theme/styles";

import Palette from "../../palette.json";
import { LocationFields } from "../../types/admin/LocationFields";
import { MapView } from "../MapView";

interface LocationFormDialogProps {
  action: "create" | "update";
}

const HIDDEN_FIELDS = ["geometry.type", "geometry.coordinates[0]", "geometry.coordinates[1]"];

export const LocationFormDialog: React.FC<LocationFormDialogProps> = ({ action }) => {
  const history = useHistory();
  const location = useLocation();

  const { addLocation, updateLocation } = useAdminApi();
  const item = useLocationItem();

  const form = useForm<CreateLocationRequest>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      name: item?.name || "",
      address: {
        street1: item?.address?.street1 || "",
        street2: item?.address?.street2 || "",
        city: item?.address?.city || "",
        state: item?.address?.state || "",
        country: item?.address?.country || "",
        zip: item?.address?.zip || "",
      },
      geometry: item?.geometry || {
        type: "Point",
        coordinates: [0.1218, 52.2053],
      },
    },
  });

  const { formState, register, setValue, watch, getValues } = form;
  const { errors, isDirty } = formState;

  const geometry = watch("geometry.coordinates") as [number, number];

  const searchRef = useRef<HTMLInputElement>(null);
  const [center, setCenter] = useState<{ lat: number; lng: number }>({ lat: geometry[1], lng: geometry[0] });
  const [marker, setMarker] = useState<LocationFields | null>(null);

  const title = action === "create" ? "Create New Location" : "Update Location";

  useEffect(() => {
    if (action !== "create") return;

    const search = new URLSearchParams(location.search);
    setValue("geometry", {
      type: "Point",
      coordinates: [search.get("lng"), search.get("lat")],
    });

    if (!location.state) return;

    const addresses = JSON.parse(location.state as string);
    if (addresses.length > 0) {
      const addr = addresses[0].address_components;

      setValue(
        "address.street1",
        addr.find((it: any) => it.types.includes("street_number")).long_name +
          " " +
          addr.find((it: any) => it.types.includes("route")).long_name,
      );
      setValue(
        "address.city",
        addr.find((it: any) => it.types.includes("postal_town"))?.long_name ||
          addr.find((it: any) => it.types.includes("locality"))?.long_name,
      );
      setValue("address.state", addr.find((it: any) => it.types.includes("administrative_area_level_1")).short_name);
      setValue("address.country", addr.find((it: any) => it.types.includes("country")).long_name);
      setValue("address.zip", addr.find((it: any) => it.types.includes("postal_code")).long_name);
    }
  }, [action, location]);

  useEffect(() => {
    if (!setValue || !item) return;
    setValue("name", item.name || "");
    setValue("address", item.address || {});
    setValue("geometry", item.geometry || {});
  }, [setValue, item]);

  useEffect(() => {
    setValue("geometry", {
      type: "Point",
      coordinates: [center.lng, center.lat],
    });
    setMarker({
      id: "",
      __typename: "Location",
      ...getValues(),
      geometry: {
        __typename: "Geometry",
        type: "Point",
        coordinates: [center.lng, center.lat],
      },
    } as LocationFields);
  }, [center]);

  const onClick = (lat: number, lng: number, results: any[]) => {
    const [loc] = results;
    if (!loc || !loc.types.includes("street_address")) return;

    const addr = loc.address_components;
    if (!addr) return;

    setValue(
      "address.street1",
      addr.find((it: any) => it.types.includes("street_number"))?.long_name +
        " " +
        addr.find((it: any) => it.types.includes("route"))?.long_name,
    );
    setValue(
      "address.city",
      addr.find((it: any) => it.types.includes("postal_town"))?.long_name ||
        addr.find((it: any) => it.types.includes("locality"))?.long_name,
    );
    setValue("address.state", addr.find((it: any) => it.types.includes("administrative_area_level_1"))?.short_name);
    setValue("address.country", addr.find((it: any) => it.types.includes("country"))?.long_name);
    setValue("address.zip", addr.find((it: any) => it.types.includes("postal_code"))?.long_name);

    setValue("geometry", {
      type: "Point",
      coordinates: [lng, lat],
    });

    setMarker({
      id: "",
      __typename: "Location",
      ...getValues(),
      geometry: {
        __typename: "Geometry",
        type: "Point",
        coordinates: [lng, lat],
      },
    } as LocationFields);
  };

  const onSubmit = form.handleSubmit(async (values) => {
    console.info(values);

    if (action === "create") {
      const result = await addLocation({
        input: values,
      });

      snackbar.notify({ title: "Location created successfully!" });
      if (result) history.replace(`/locations/${result.id}`);
    } else if (action === "update") {
      if (!item) return;

      await updateLocation({
        id: item.id,
        input: values,
      });
      snackbar.notify({ title: "Location updated successfully!" });
      history.goBack();
    }
  });

  const onClose = async (ev: DialogOnClosedEventT) => {
    if (ev.detail.action === "accept") {
      await onSubmit();
      return;
    }

    history.goBack();
  };

  return (
    <Dialog open preventOutsideDismiss onClose={onClose}>
      <DialogTitle style={{ width: "30rem" }}>{title}</DialogTitle>

      <DialogContent>
        <CommonForm {...{ onSubmit }}>
          {HIDDEN_FIELDS.map((it, i) => (
            <input key={i} name={it} type="hidden" ref={register({ required: true })} />
          ))}
          <FormRow>
            <FormField>
              <Label htmlFor="name">Name</Label>
              <TextInput name="name" ref={register({ required: true, minLength: 3 })} />
            </FormField>
          </FormRow>

          <FormRow>
            <FormField>
              <Label htmlFor="addressSearch">Search</Label>
              <TextInput name="addressSearch" ref={searchRef} />
            </FormField>
          </FormRow>

          <div style={{ height: "15rem", display: "flex", flexDirection: "column" }}>
            <MapView
              {...{ searchRef, center, setCenter }}
              items={marker ? [marker] : []}
              zoom={14}
              onClick={onClick}
              onItemClick={() => false}
            />
          </div>

          <FormRow style={{ marginTop: "1rem" }}>
            <FormField>
              <Label htmlFor="address.street1">Street 1</Label>
              <TextInput name="address.street1" ref={register} />
            </FormField>

            <FormField>
              <Label htmlFor="address.street2">Street 2</Label>
              <TextInput name="address.street2" ref={register} />
            </FormField>
          </FormRow>

          <FormRow>
            <FormField>
              <Label htmlFor="address.city">City</Label>
              <TextInput name="address.city" ref={register} />
            </FormField>

            <FormField>
              <Label htmlFor="address.state">State</Label>
              <TextInput name="address.state" ref={register} />
            </FormField>
          </FormRow>

          <FormRow>
            <FormField>
              <Label htmlFor="address.zip">Zipcode</Label>
              <TextInput name="address.zip" ref={register} />
            </FormField>

            <FormField>
              <Label htmlFor="address.country">Country</Label>
              <TextInput name="address.country" ref={register} />
            </FormField>
          </FormRow>
        </CommonForm>
      </DialogContent>

      <DialogActions>
        <ThemeProvider options={{ primary: Palette.MediumGrey }}>
          <DialogButton action="close" outlined>
            Cancel
          </DialogButton>
        </ThemeProvider>
        <div style={{ flexGrow: 1 }} />

        {/* <Checkbox style={{ marginRight: "20px", fontSize: "14px" }} label="Create Another" /> */}
        <DialogButton isDefaultAction raised disabled={!isDirty && Object.keys(errors).length !== 0} action="accept">
          Save
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
