import React, { useEffect } from "react";

import { useForm } from "react-hook-form";
import { useAdminApi } from "../../hooks/useAdminApi";

import { Button } from "../../components/Button";
import { Label } from "../../components/Label";
import { CommonForm, FormField, FormRow } from "../../components/CommonForm";
import { PasswordInput } from "../../components/TextInput";
import { PageHeading } from "../../components/PageStyles";

import styled from "styled-components";
import { snackbar } from "../../hooks/useNotifications";
import { useFirebase } from "../../hooks/useFirebase";
import { useHistory } from "react-router";
import useLayout from "../../hooks/useLayout";

const HiddenField = styled.input``;
HiddenField.defaultProps = { type: "hidden" };

interface FormValuesT {
  id: string;

  currentPassword: string;

  password: string;
  passwordConfirmation: string;
}

export const ProfilePage: React.FC = () => {
  const history = useHistory();

  const { user, signInWithEmailAndPassword } = useFirebase();
  const { me } = useAdminApi();
  const { setBreadCrumbs } = useLayout();

  const form = useForm<FormValuesT>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
  });
  const { formState, handleSubmit, register } = form;
  const { isValid } = formState;

  useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([
      {
        label: "Profile",
        path: "/profile",
      },
    ]);
  }, [setBreadCrumbs]);

  const doSubmit = handleSubmit(async (values) => {
    if (!user) return;

    if (values.password !== values.passwordConfirmation) {
      snackbar.notify({ title: "Passwords do not match!" });
      return;
    }

    try {
      await signInWithEmailAndPassword(user.email || "", values.currentPassword);
      await user.updatePassword(values.password);
      snackbar.notify({ title: "Password changed successfully!" });
      history.replace("/");
    } catch (e) {
      snackbar.notify({ title: e.message });
    }
  });

  return (
    <>
      <PageHeading>Hello, {me?.firstName}</PageHeading>
      <CommonForm onSubmit={doSubmit} style={{ margin: "0 auto", maxWidth: "320px" }}>
        <HiddenField name="id" value={me?.id} ref={register({ required: true })} />

        <FormRow>
          <FormField>
            <Label htmlFor="currentPassword">Current Password</Label>
            <PasswordInput name="currentPassword" ref={register({ required: true })} />
          </FormField>
        </FormRow>

        <FormRow>
          <FormField>
            <Label htmlFor="password">Password</Label>
            <PasswordInput name="password" ref={register({ required: true })} />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <Label htmlFor="passwordConfirmation">Confirm</Label>
            <PasswordInput name="passwordConfirmation" ref={register({ required: true })} />
          </FormField>
        </FormRow>

        <Button raised disabled={!isValid}>
          Update Password
        </Button>
      </CommonForm>
    </>
  );
};
