import gql from "graphql-tag";

export const TenantInfo = gql`
  fragment TenantInfo on Tenant {
    id
    name
    prefix
    logoImageUrl
    theme {
      primaryColor
      secondaryColor
    }
  }
`;

export const getTenantByPrefixQuery = gql`
  ${TenantInfo}

  query GetTenantByPrefix($prefix: String!) {
    asAnon {
      getTenantByPrefix(prefix: $prefix) {
        ...TenantInfo
      }
    }
  }
`;
