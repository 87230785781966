import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { Controller, useForm } from "react-hook-form";

import { ClientType } from "../../types/admin/globalTypes";

import { useAdminApi } from "../../hooks/useAdminApi";
import { useClient } from "../../hooks/params/useClient";

import { snackbar } from "../../hooks/useNotifications";

import { TextInput } from "../TextInput";
import { Label } from "../Label";
import { Select } from "../Select";
import { DialogActions, DialogButton, DialogContent, DialogOnClosedEventT, DialogTitle } from "@rmwc/dialog";
import { Dialog } from "../Dialog";

import { CommonForm, FormField, FormRow } from "../CommonForm";

import { ThemeProvider } from "@rmwc/theme";

import Palette from "../../palette.json";

interface FormValuesT {
  id?: string;
  clientType: ClientType;

  name: string;
  address: string;
}

interface ClientFormDialogProps {
  action: "create" | "update";
}

export const ClientFormDialog: React.FC<ClientFormDialogProps> = ({ action }) => {
  const history = useHistory();
  const { state: locationState } = useLocation<{ referrer: string }>();

  const { addClient, updateClient } = useAdminApi();
  const client = useClient();

  const clientTypeOpts = [
    { label: "Company", value: ClientType.Company },
    { label: "Individual", value: ClientType.Individual },
  ];

  const form = useForm<FormValuesT>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      clientType: client ? client.clientType || ClientType.Company : ClientType.Company,
      name: client ? client.name : "",
      address: client ? client.address || "" : "",
    },
  });
  const { control, formState, register, setValue } = form;
  const { isDirty, isValid } = formState;

  const title = action === "create" ? "Create New Customer" : "Update Customer";

  useEffect(() => {
    if (!setValue || !client) return;
    setValue("clientType", client.clientType || ClientType.Company);
    setValue("name", client.name || "");
    setValue("address", client.address || "");
  }, [setValue, client]);

  const onSubmit = form.handleSubmit(async (values) => {
    if (action === "create") {
      const client = await addClient({
        ...values,
      });
      snackbar.notify({ title: "Customer created successfully!" });

      if (client) {
        history.replace(`/clients/${client.id}/projects/new`);
      }
    } else if (action === "update") {
      if (!client) return;

      await updateClient({
        id: client.id,
        ...values,
      });
      snackbar.notify({ title: "Customer updated successfully!" });
    }
  });

  const onClose = async (ev: DialogOnClosedEventT) => {
    if (ev.detail.action === "accept") {
      await onSubmit();
      return;
    }

    if (ev.detail.action === "close") {
      if (locationState?.referrer === "/projects/new") {
        history.replace(`/projects/new`);
        return;
      }
    }

    if (ev.detail.action !== "destroy") {
      history.goBack();
    }
  };

  return (
    <Dialog open preventOutsideDismiss onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <CommonForm {...{ onSubmit }}>
          <FormRow>
            <FormField>
              <Label htmlFor="clientType">Client Type</Label>
              <Controller
                name="clientType"
                control={control}
                as={<Select outlined enhanced options={clientTypeOpts} />}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <Label htmlFor="name">Name</Label>
              <TextInput name="name" ref={register({ required: true })} />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <Label htmlFor="address">Address</Label>
              <TextInput name="address" ref={register({ required: true })} />
            </FormField>
          </FormRow>
        </CommonForm>
      </DialogContent>

      <DialogActions>
        <ThemeProvider options={{ primary: Palette.MediumGrey }}>
          <DialogButton action="close" outlined>
            Cancel
          </DialogButton>
        </ThemeProvider>
        <div style={{ flexGrow: 1 }} />

        {/* <Checkbox style={{ marginRight: "20px", fontSize: "14px" }} label="Create Another" /> */}
        <DialogButton isDefaultAction raised disabled={!isDirty || !isValid} action="accept">
          Save
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
