import React, { useEffect, useState } from "react";
import { Route, useHistory } from "react-router";

import { LocationFields } from "../../types/admin/LocationFields";

import { snackbar } from "../../hooks/useNotifications";
import { dialogQueue } from "../../hooks/useDialogQueue";

import useLayout from "../../hooks/useLayout";

import { useAdminApi } from "../../hooks/useAdminApi";
import { useLocationItem } from "../../hooks/params/useLocationItem";

import { Button } from "../../components/Button";

import { PageHeader } from "../../components/PageHeader";
import { PageMain } from "../../components/PageStyles";

import { LocationList } from "../../components/locations/LocationList";
import { LocationFormDialog } from "../../components/locations/LocationFormDialog";

import { MapView } from "../../components/MapView";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import styled from "styled-components";

import Palette from "../../palette.json";

const MainArea = styled.div`
  height: 100%;

  flex-grow: 1;

  display: flex;
  flex-direction: column;
`;

const ListContainer = styled.div`
  max-height: 100%;
  overflow-y: auto;
`;

const LocationTools = styled.div`
  padding: 8px 16px 9px;

  display: flex;
  flex-direction: row;

  justify-content: flex-end;

  > * {
    margin-left: 1rem;
  }
`;

const LocationInfo = styled.div`
  position: relative;

  padding: 16px 52px;
  border-top: 1px solid #dfdfdf;

  > .rmwc-icon {
    position: absolute;
    top: 32px;
    right: 16px;
  }
`;
const LocationName = styled.h4`
  margin: 16px 0;
  font-size: 26px;

  > button {
    font-size: 20px;
    color: ${Palette.Blue};
  }
`;

const LocationAddressItem = styled.p`
  margin: 0 0 8px;
  font-size: 14px;
`;

export const LocationsPage: React.FC = () => {
  const history = useHistory();

  const { setBreadCrumbs } = useLayout();

  const { locations, deleteLocation } = useAdminApi();
  const item = useLocationItem();

  const [center, setCenter] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
  const [zoom, setZoom] = useState<number>(8);

  useEffect(() => {
    if (!setBreadCrumbs) return;

    if (!item || !item.geometry) {
      setBreadCrumbs([{ label: "Locations", path: "/locations" }]);
      setCenter({ lat: 0, lng: 0 });
      setZoom(10);
      return;
    }

    const [lng, lat] = item.geometry?.coordinates;

    setBreadCrumbs([
      { label: "Locations", path: "/locations" },
      { label: item.name || `Location: ${item.id}`, path: `/locations/${item.id}` },
    ]);

    if (!lat || !lng) return;
    setCenter({ lat, lng });
    setZoom(15);
  }, [setBreadCrumbs, item]);

  const onItemClick = (item?: LocationFields) => {
    if (!item) {
      history.push("/locations");
      return;
    }

    history.push(`/locations/${item.id}`);
  };

  const onNewLocation = (lat?: number, lng?: number, results?: any[]) => {
    let path = `/locations/new`;
    if (lat && lng) {
      path += `?lat=${lat}&lng=${lng}`;
    }
    history.push(path, JSON.stringify(results));
  };

  const onDelete = async () => {
    if (!item) return;

    const hasConfirmed = await dialogQueue.confirm({
      title: "Are you sure?",
      body: `Are you sure you want to delete location: ${item.name || item.id}`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;

    await deleteLocation({ id: item.id });
    snackbar.notify({ title: "Location removed successfully!" });
    history.replace("/locations");
  };

  return (
    <>
      <Route exact path="/locations/new">
        <LocationFormDialog action="create" />
      </Route>

      <Route exact path="/locations/:locationId/edit">
        <LocationFormDialog action="update" />
      </Route>

      <PageHeader title={`Locations (${locations.length})`} />

      <PageMain style={{ flexDirection: "row-reverse" }}>
        <MainArea>
          <LocationTools>
            <Button data-test-id="btn-create" raised onClick={() => onNewLocation()}>
              Create New
            </Button>

            {item && (
              <Button data-test-id="btn-delete" raised danger onClick={() => onDelete()}>
                Remove Location
              </Button>
            )}
          </LocationTools>

          {item && (
            <LocationInfo data-test-id="item-info">
              <LocationName>
                {item.name}
                <IconButton
                  data-test-id="btn-update"
                  icon="edit"
                  onClick={() => history.push(`/locations/${item.id}/edit`)}
                />
              </LocationName>
              <LocationAddressItem>{item.address?.street1}</LocationAddressItem>
              <LocationAddressItem>{item.address?.street2}</LocationAddressItem>
              <LocationAddressItem>{item.address?.city}</LocationAddressItem>
              <LocationAddressItem>{item.address?.state}</LocationAddressItem>
              <LocationAddressItem>{item.address?.zip}</LocationAddressItem>
              <LocationAddressItem>{item.address?.country}</LocationAddressItem>
            </LocationInfo>
          )}

          <MapView center={center} zoom={zoom} items={locations} onClick={onNewLocation} onItemClick={onItemClick} />
        </MainArea>

        <ListContainer>
          <LocationList items={locations} onItemClick={onItemClick} />
        </ListContainer>
      </PageMain>
    </>
  );
};
