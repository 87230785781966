import styled, { css } from "styled-components";

import { TaskStatus } from "../types/admin/globalTypes";

import Palette from "../palette.json";

export const TASK_STATUS_BACKGROUNDS = {
  [TaskStatus.InProgress]: Palette.Cyan,
  [TaskStatus.Completed]: Palette.Green,
  [TaskStatus.Declined]: Palette.Red,
  [TaskStatus.OnHold]: Palette.Blue,
  [TaskStatus.Requested]: Palette.Blue,
  [TaskStatus.Upcoming]: Palette.MediumGrey,
};

export const TASK_STATUS_DISPLAY_STRINGS = {
  [TaskStatus.InProgress]: "In Progress",
  [TaskStatus.Completed]: "Complete",
  [TaskStatus.Declined]: "Declined",
  [TaskStatus.OnHold]: "Paused",
  [TaskStatus.Requested]: "Requested",
  [TaskStatus.Upcoming]: "Upcoming",
};

export const StatusLabel = styled.span<{ warning?: boolean; done?: boolean }>`
  padding: 4px 8px;

  border-radius: 4px;

  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;

  color: white;
  background-color: ${Palette.LightGrey};

  ${(p) =>
    p.warning &&
    css`
      background-color: ${Palette.Red};
    `}

  ${(p) =>
    p.done &&
    css`
      background-color: ${Palette.Green};
    `}
`;

export const TaskStatusLabel = styled(StatusLabel)<{ status: TaskStatus }>`
  background-color: ${({ status }) => TASK_STATUS_BACKGROUNDS[status]};
`;
