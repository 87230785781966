import React from "react";

import { useProject } from "../../../../hooks/params/useProject";

import { TaskList } from "../../../../components/tasks/TaskList";

import { useAdminApi } from "../../../../hooks/useAdminApi";

export const ProjectTasksPage: React.FC = () => {
  const { tasks: allTasks } = useAdminApi();

  const project = useProject();

  const tasks = allTasks.filter((it) => it.projectId === project?.id && it.parentId === project.id);

  if (!project) return <></>;

  return (
    <>
      <TaskList project={project} items={tasks} />
    </>
  );
};
