import React from "react";

import { PageContent, PageHeading, PageMain } from "../../components/PageStyles";

import { TimesheetTable } from "../../components/timesheets/TimesheetTable";

export const TimesheetsPage: React.FC = () => {
  return (
    <>
      <PageHeading>Timesheets</PageHeading>

      <PageMain style={{ marginTop: "-54px" }}>
        <PageContent>
          <TimesheetTable />
        </PageContent>
      </PageMain>
    </>
  );
};
