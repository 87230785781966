import React, { createContext, useContext, useEffect, useState } from "react";

import firebase from "firebase/app";
import "firebase/auth";

import config from "../config";

export interface FirebaseContextT {
  user: firebase.User | null;
  isLoading: boolean;

  signInWithEmailAndPassword(email: string, password: string): Promise<void>;
  signInAnonymously(): Promise<void>;

  signInWithApple(): Promise<void>;
  signInWithGoogle(): Promise<void>;

  signOut(): Promise<void>;

  sendPasswordResetEmail(email: string): Promise<void>;
  confirmPasswordReset(code: string, password: string): Promise<void>;
}

export const Context = createContext({ isLoading: true } as FirebaseContextT);
export const useFirebase = (): FirebaseContextT => useContext(Context);

interface InitFirebaseOptsT {
  tenantId?: string;
}
const initFirebaseApi = (options: InitFirebaseOptsT) => {
  if (!firebase.apps.length) firebase.initializeApp(config.firebase);
  const auth = firebase.auth();
  auth.tenantId = options.tenantId || null;

  return { auth };
};

interface FirebaseProviderPropsT {
  tenantId?: string;
}
export const FirebaseProvider: React.FC<FirebaseProviderPropsT> = ({ tenantId, children }) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<firebase.User | null>(null);

  useEffect(() => {
    initFirebaseApi({ tenantId });

    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });
  }, [tenantId]);

  const signInWithEmailAndPassword = async (email: string, password: string) => {
    await firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const signInAnonymously = async () => {
    await firebase.auth().signInAnonymously();
  };

  const signInWithApple = async () => {
    const provider = new firebase.auth.OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    await firebase.auth().signInWithPopup(provider);
  };

  const signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    await firebase.auth().signInWithPopup(provider);
  };

  const signOut = async () => {
    setLoading(true);
    await firebase.auth().signOut();
    window.location.reload(); // XXX - Force reload to clean up appsync persist state mess.
  };

  const sendPasswordResetEmail = async (email: string) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const confirmPasswordReset = async (code: string, password: string) => {
    await firebase.auth().confirmPasswordReset(code, password);
  };

  return (
    <Context.Provider
      value={{
        user,
        isLoading,
        signInWithEmailAndPassword,
        signInAnonymously,
        signInWithApple,
        signInWithGoogle,
        signOut,
        sendPasswordResetEmail,
        confirmPasswordReset,
      }}
    >
      {children}
    </Context.Provider>
  );
};
