import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation, useParams } from "react-router";

import { CameraFields } from "../../../types/admin/CameraFields";

import useLayout from "../../../hooks/useLayout";

import { useAdminApi } from "../../../hooks/useAdminApi";
import { useProject } from "../../../hooks/params/useProject";

import { StatusLabel as StatusLabelBase } from "../../../components/StatusLabel";

import { PageHeader } from "../../../components/PageHeader";
import { PageContent, PageMain } from "../../../components/PageStyles";
import { TabContainer } from "../../../components/TabContainer";

import { ProjectFormDialog } from "../../../components/projects/ProjectFormDialog";
import { TaskFormDialog } from "../../../components/tasks/TaskFormDialog";

import { ProjectTasksPage } from "./tasks";
import { ProjectPeoplePage } from "./people";
import { ProjectSchedulePage } from "./schedule";
import { ProjectTimesheetsPage } from "./timesheets";

import { TabBar, Tab } from "@rmwc/tabs";
import "@rmwc/tabs/styles";

import dayjs from "dayjs";
import styled from "styled-components";

import Palette from "../../../palette.json";

const StatusBlock = styled.div`
  margin: 0 6px;
  padding: 12px 30px;

  width: 152px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border: 1px solid #eaedf3;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);

  :first-of-type {
    margin-left: 0px;
  }

  :last-of-type {
    margin-right: 0px;
  }

  .mdc-linear-progress {
    margin-bottom: 16px;
  }

  a {
    flex-grow: 1;
    display: flex;
    margin: 8px;
    border: 1px solid ${Palette.DarkGrey};
  }

  img {
    flex-grow: 1;
    width: 100%;
    background-color: ${Palette.LightGrey};
  }
`;
const StatusBlockTitle = styled.h5`
  margin: 4px 0 16px;
  font-size: 16px;
  font-weight: 500;
  color: ${Palette.DarkGrey};
`;
const StatusBlockValue = styled.h6`
  margin: 0 0 16px;
  font-size: 30px;
  font-weight: normal;
  color: ${Palette.DarkGrey};
`;
const StatusLabel = styled(StatusLabelBase)`
  padding: 8px 16px;
  font-size: 12px;
  background-color: ${Palette.Red};
  text-align: center;
`;

interface ParamsT {
  projectId: string;
}
export const ProjectViewPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { projectId } = useParams<ParamsT>();

  const { setBreadCrumbs } = useLayout();

  const { clients, getCameras } = useAdminApi();
  const project = useProject();
  const client = clients.find((it) => it.id === project?.clientId);

  const [camera, setCamera] = useState<CameraFields | null>(null);

  const PAGE_TABS = [
    {
      icon: "checklist",
      label: "Tasks",
      path: `/projects/${projectId}/tasks`,
    },
    {
      icon: "person",
      label: "People",
      path: `/projects/${projectId}/people`,
    },
    /* {
      icon: "comment",
      label: "Messages",
      path: `/projects/${projectId}/messages`,
    }, */
    {
      icon: "group",
      label: "Schedule",
      path: `/projects/${projectId}/schedule`,
    },
    {
      icon: "today",
      label: "Timesheets",
      path: `/projects/${projectId}/timesheets`,
    },
  ];

  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path)),
  );

  /* 
  const tasksCompleted = project?.tasks?.items.filter((it) => it.status === TaskStatus.Completed).length || 0;
  const tasksTotal = project?.tasks?.items.length || 0;
 */
  useEffect(() => {
    if (location.pathname.endsWith("/edit")) return;
    const tabIndex = PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path));
    if (tabIndex === activeTabIndex) return;
    setActiveTabIndex(tabIndex === -1 ? 0 : tabIndex);
  }, [location, PAGE_TABS]);

  useEffect(() => {
    if (!setBreadCrumbs) return;
    if (!project) return;

    setBreadCrumbs([
      {
        label: "Dashboard",
        path: "/",
      },
      {
        label: project?.name,
        path: `/projects/${projectId}`,
      },
    ]);

    if (!getCameras) return;
    getCameras(project.id).then((r) => {
      const [camera] = r;
      if (camera) setCamera(camera);
    });
  }, [setBreadCrumbs, getCameras, project, projectId]);

  const onTabActivated = (ev: CustomEvent) => {
    const { path } = PAGE_TABS[ev.detail.index];
    if (location.pathname.startsWith(path) || location.pathname.endsWith("/edit") || location.pathname.endsWith("/new"))
      return;
    history.replace(path);
  };

  if (!project)
    return (
      <>
        <Route exact path="/projects/new">
          <ProjectFormDialog action="create" />
        </Route>
      </>
    );

  return (
    <>
      <Route exact path="/projects/:projectId/tasks/new">
        <TaskFormDialog action="create" />
      </Route>

      <Route exact path="/projects/:projectId/edit">
        <ProjectFormDialog action="update" />
      </Route>

      <PageHeader
        compact
        editable
        title={project.name}
        subtitle={client?.name}
        onEditClick={() => history.push(`/projects/${projectId}/edit`)}
        onSubtitleClick={() => history.push(`/clients/${client?.id}`)}
      >
        <StatusBlock>
          <StatusBlockTitle>Due Date</StatusBlockTitle>
          <StatusBlockValue>{dayjs(project.endDate).format("MM/DD")}</StatusBlockValue>
          <StatusLabel style={{ backgroundColor: Palette.Orange }}>Behind Schedule</StatusLabel>
        </StatusBlock>

        {camera && (
          <StatusBlock>
            <StatusLabel>Live Video</StatusLabel>
            <a href={(camera.streams ? camera.streams[0]?.url : "") || ""} target="_blank" rel="noopener noreferrer">
              {camera.live_snapshot && <img src={camera.live_snapshot} />}
            </a>
          </StatusBlock>
        )}

        {/* <StatusBlock>
          <StatusBlockTitle>Progress</StatusBlockTitle>
          <CircularProgress
            size={64}
            progress={tasksCompleted / tasksTotal}
            style={{ display: "block", margin: "8px auto 16px" }}
          />
        </StatusBlock>

        <StatusBlock>
          <StatusBlockTitle>Tasks</StatusBlockTitle>
          <StatusBlockValue>
            {tasksCompleted}/{tasksTotal}
          </StatusBlockValue>
          <LinearProgress progress={tasksCompleted / tasksTotal} buffer={1} />
        </StatusBlock>

        <StatusBlock>
          <StatusBlockTitle>Labour Overview</StatusBlockTitle>
        </StatusBlock> */}
      </PageHeader>

      <TabContainer>
        <TabBar {...{ activeTabIndex: activeTabIndex === -1 ? 0 : activeTabIndex }} onActivate={onTabActivated}>
          {PAGE_TABS.map((it, i) => (
            <Tab key={i} icon={it.icon}>
              {it.label}
            </Tab>
          ))}
        </TabBar>
      </TabContainer>

      <PageMain>
        <PageContent>
          <Switch>
            <Route path="/projects/:projectId/people" component={ProjectPeoplePage} />
            <Route path="/projects/:projectId/schedule" component={ProjectSchedulePage} />
            <Route path="/projects/:projectId/timesheets" component={ProjectTimesheetsPage} />
            <Route path={["/projects/:projectId", "/projects/:projectId/tasks"]} component={ProjectTasksPage} />
          </Switch>
        </PageContent>
      </PageMain>
    </>
  );
};
