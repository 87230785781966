import React, { useState } from "react";
import { useHistory } from "react-router";

import { ProjectFields } from "../../types/admin/ProjectFields";
import { TaskStatus } from "../../types/admin/globalTypes";
import { TaskFields } from "../../types/admin/TaskFields";
import { DeleteTaskVariables } from "../../types/admin/DeleteTask";

import { useAdminApi } from "../../hooks/useAdminApi";
import { useTask } from "../../hooks/params/useTask";

import { dialogQueue } from "../../hooks/useDialogQueue";
import { snackbar } from "../../hooks/useNotifications";

import useSorted, { sortByProperty } from "../../hooks/useSorted";
import useFiltered from "../../hooks/useFiltered";

import { Button } from "../../components/Button";

import { TaskStatusLabel, TASK_STATUS_DISPLAY_STRINGS } from "../StatusLabel";
import {
  DataCellTools,
  Table,
  TableContainer,
  TableHead,
  TableTitle,
  TableToolbarTitle,
  TitleCount,
} from "../../components/Table";

import { Avatar, AvatarGroup } from "@rmwc/avatar";
import "@rmwc/avatar/styles";

import { Badge, BadgeAnchor } from "@rmwc/badge";
import "@rmwc/badge/styles";

import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import { MenuItem, SimpleMenu } from "@rmwc/menu";
import "@rmwc/menu/styles";

import dayjs from "dayjs";

import styled from "styled-components";

import Palette from "../../palette.json";
import { getUserAvatarColor } from "../../common";

const TaskNameCell = styled.td`
  padding: 0 16px 0 8px !important;

  > div {
    display: flex;
    align-items: center;
    vertical-align: middle;

    .rmwc-icon {
      :first-of-type {
        margin-left: 16px;
      }
      color: ${Palette.LightGrey};
    }

    .rmwc-badge {
      padding: 0 4px;
      min-width: 1.2rem;
      height: 1.2rem;
      line-height: 1.2rem;
      font-size: 10px;
      background-color: ${Palette.MediumGrey};
    }

    .drag-handle {
      margin: 0px 8px 0px 0px !important;
    }
  }

  span {
    font-size: 14px;
    font-weight: 500;
  }
`;

const AssignmentCell = styled.td`
  > div {
    display: flex;
    align-items: center;

    .mdc-icon-button {
      width: 24px;
      height: 24px;
      padding: 3px;
      color: ${Palette.LightGrey};
      font-size: 16px;
      border: 1px solid ${Palette.LightGrey};
      border-radius: 50%;
    }
  }
`;

const ScheduledDateCell = styled.td<{ date: dayjs.Dayjs }>`
  color: ${(p) => (p.date.isBefore(dayjs()) ? Palette.Red : Palette.MediumGrey)};
`;

interface TaskListProps {
  project: ProjectFields;
  items: TaskFields[];
}

export const TaskList: React.FC<TaskListProps> = ({ project, items }) => {
  const history = useHistory();

  const { users, deleteTask } = useAdminApi();
  const parentTask = useTask();

  const sorted = useSorted(items, sortByProperty("orderIndex"));

  const filterByStatusItems = [
    "All",
    TaskStatus.Requested,
    TaskStatus.Upcoming,
    TaskStatus.InProgress,
    TaskStatus.OnHold,
    TaskStatus.Declined,
    TaskStatus.Completed,
  ];
  const [filterByStatus, setFilterByStatus] = useState<number>(0);

  const filtered = useFiltered(sorted, (it) => {
    if (filterByStatus === 0) return true;
    return it.status === filterByStatusItems[filterByStatus];
  });

  /* const [userSearchString, setUserSearchString] = useState<string>("");
  const filteredUsers = useFiltered(users, (it) => {
    return !!(userSearchString.length > 0 && it.name?.includes(userSearchString));
  }); */

  const cols = ["Name"];
  if (!parentTask) {
    ["Assigned To", "Hours", "Progress", "Status"].map((it) => cols.push(it));
  }
  ["Due Date", ""].map((it) => cols.push(it));

  /* const onAssignUser = (task: TaskFields) => async (ev: MenuOnSelectEventT) => {
    const { id: taskId } = task;
    const { id: userId } = filteredUsers[ev.detail.index];

    console.info("Assign User:", taskId, userId);

    const response = await assignUserToTask({ taskId, userId });
    console.info(response);

    setUserSearchString("");
  };
 */
  const onEdit = (task: TaskFields) => {
    history.push(`/projects/${task.projectId}/tasks/${task.id}/edit`);
  };

  const onDelete = async (task: TaskFields) => {
    let hasConfirmed = false;

    if (parentTask) {
      hasConfirmed = await dialogQueue.confirm({
        title: "Are you sure?",
        body: `Are you sure you want to delete todo: ${task.taskNumber} - ${task.name}`,
        acceptLabel: "Confirm",
        cancelLabel: "Cancel",
      });
    } else {
      hasConfirmed = await dialogQueue.confirm({
        title: "Are you sure?",
        body: `Are you sure you want to delete task: ${task.taskNumber} - ${task.name}`,
        acceptLabel: "Confirm",
        cancelLabel: "Cancel",
      });
    }

    if (!hasConfirmed) return;

    await deleteTask(task as DeleteTaskVariables);

    if (parentTask) {
      snackbar.notify({ title: "Todo removed successfully!" });
    } else {
      snackbar.notify({ title: "Task removed successfully!" });
    }
  };

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          {parentTask ? "Todo's" : "In Progress"} <TitleCount>({items.length})</TitleCount>
        </TableToolbarTitle>

        <SimpleMenu
          anchorCorner="bottomStart"
          focusOnOpen={false}
          handle={
            <Button outlined trailingIcon="unfold_more">
              {filterByStatus === 0
                ? "All"
                : TASK_STATUS_DISPLAY_STRINGS[filterByStatusItems[filterByStatus] as TaskStatus]}
            </Button>
          }
          onSelect={(ev) => setFilterByStatus(ev.detail.index)}
        >
          {filterByStatusItems.map((it, i) => (
            <MenuItem key={i}>{i === 0 ? "All" : TASK_STATUS_DISPLAY_STRINGS[it as TaskStatus]}</MenuItem>
          ))}
        </SimpleMenu>
        <Button
          raised
          onClick={() =>
            history.push(`/projects/${project.id}/tasks/${parentTask ? parentTask.id + "/todos/" : ""}new`)
          }
        >
          New {parentTask ? "Todo" : "Task"}
        </Button>
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead {...{ cols }} />

          <DataTableBody>
            {filtered.length === 0 && (
              <DataTableRow>
                <DataTableCell colSpan={7} style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}>
                  - No Tasks -
                </DataTableCell>
              </DataTableRow>
            )}
            {filtered.map((it, i) => (
              <DataTableRow key={i}>
                <TaskNameCell onClick={() => !parentTask && history.push(`/projects/${it.projectId}/tasks/${it.id}`)}>
                  <div>
                    <Icon icon="drag_indicator" className="drag-handle" />
                    <div>
                      <span>{it.taskNumber}</span> - {it.name}
                    </div>
                    {!parentTask && (
                      <>
                        {(it.messages?.items || []).length > 0 && (
                          <BadgeAnchor>
                            <IconButton
                              icon="message"
                              onClick={(ev) => {
                                ev.stopPropagation();
                                history.push(`/projects/${it.projectId}/tasks/${it.id}/messages`);
                              }}
                            />
                            <Badge inset="12px" label={it.messages?.items.length} />
                          </BadgeAnchor>
                        )}
                        {(it.attachments?.items || []).length > 0 && (
                          <BadgeAnchor>
                            <IconButton
                              icon="attachment"
                              onClick={(ev) => {
                                ev.stopPropagation();
                                history.push(`/projects/${it.projectId}/tasks/${it.id}/attachments`);
                              }}
                            />
                            <Badge inset="12px" label={it.attachments?.items.length} />
                          </BadgeAnchor>
                        )}
                      </>
                    )}
                  </div>
                </TaskNameCell>

                {!parentTask && (
                  <>
                    <AssignmentCell>
                      <div>
                        <AvatarGroup dense>
                          {it.users?.items.map((it: { userId: string }) => (
                            <Avatar
                              key={it.userId}
                              style={{
                                color: "white",
                                backgroundColor: getUserAvatarColor(it.userId),
                              }}
                              name={users.find((jt) => jt.id === it.userId)?.name || ""}
                            />
                          ))}
                        </AvatarGroup>

                        <IconButton
                          icon="add"
                          onClick={() => history.push(`/projects/${project.id}/tasks/${it.id}/people/add`)}
                        />
                      </div>
                    </AssignmentCell>

                    <DataTableCell>
                      {Math.floor(it.progress?.completedHours || 0)}/{it.estimateManHrs}
                    </DataTableCell>
                    <DataTableCell>{it.progress?.progressTimePercentage?.toFixed(2)}%</DataTableCell>
                    <DataTableCell>
                      <TaskStatusLabel status={it.status || TaskStatus.OnHold}>
                        {TASK_STATUS_DISPLAY_STRINGS[it.status || TaskStatus.OnHold]}
                      </TaskStatusLabel>
                    </DataTableCell>
                  </>
                )}
                <ScheduledDateCell date={dayjs(it.dueDate)}>
                  {it.dueDate ? dayjs(it.dueDate).tz("UTC").format("MM/DD/YYYY") : "-"}
                </ScheduledDateCell>
                <DataTableCell>
                  <DataCellTools>
                    {it.parentId === it.projectId && <IconButton icon="edit" onClick={() => onEdit(it)} />}
                    <IconButton icon="delete" onClick={() => onDelete(it)} />
                  </DataCellTools>
                </DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
