import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import dayjs from "dayjs";
import { DocumentNode } from "graphql";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useRef } from "react";
import {
  addAircraftMutation,
  deleteAircraftMutation,
  listAircraftQuery,
  updateAircraftMutation,
} from "../queries/admin/aircraft";
import { addAttachmentMutation, deleteAttachmentMutation, getUploadUrlQuery } from "../queries/admin/attachments";
import { getCamerasQuery } from "../queries/admin/cameras";
import {
  addClientMutation,
  deleteClientMutation,
  listClientsQuery,
  updateClientMutation,
} from "../queries/admin/clients";
import {
  addLocationMutation,
  deleteLocationMutation,
  listLocationsQuery,
  updateLocationMutation,
} from "../queries/admin/locations";
import { addMessageMutation, onAddedMessageSubscription } from "../queries/admin/messages";
import {
  addProjectMutation,
  addProjectUserMutation,
  deleteProjectMutation,
  listProjectsQuery,
  removeProjectUserMutation,
  updateProjectMutation,
} from "../queries/admin/projects";
import {
  addTaskMutation,
  assignUserToTaskMutation,
  deleteTaskMutation,
  listTasksQuery,
  removeTaskUserMutation,
  updateTaskMutation,
} from "../queries/admin/tasks";
import { deleteTimeSheetEntryMutation, updateTimeSheetEntryMutation } from "../queries/admin/timesheets";
import { addTimeSheetEntryMutation, listTimesheetsQuery } from "../queries/admin/timesheets";
import {
  addUserMutation,
  deleteUserMutation,
  getMeQuery,
  listUsersQuery,
  updateUserMutation,
} from "../queries/admin/users";
import { AddAircraft, AddAircraftVariables } from "../types/admin/AddAircraft";
import { AddAttachment, AddAttachmentVariables } from "../types/admin/AddAttachment";
import { AddClient, AddClientVariables } from "../types/admin/AddClient";
import { AddLocation, AddLocationVariables } from "../types/admin/AddLocation";
import { AddMessage } from "../types/admin/AddMessage";
import { AddProject, AddProjectVariables } from "../types/admin/AddProject";
import { AddProjectUser, AddProjectUserVariables } from "../types/admin/AddProjectUser";
import { AddTask, AddTaskVariables } from "../types/admin/AddTask";
import { AddTimeSheetEntry } from "../types/admin/AddTimeSheetEntry";
import { AddUser, AddUserVariables } from "../types/admin/AddUser";
import { AircraftFields } from "../types/admin/AircraftFields";
import { AssignUserToTask, AssignUserToTaskVariables } from "../types/admin/AssignUserToTask";
import { AttachmentFields } from "../types/admin/AttachmentFields";
import { CameraFields } from "../types/admin/CameraFields";
import { ClientFields } from "../types/admin/ClientFields";
import { DeleteAircraft, DeleteAircraftVariables } from "../types/admin/DeleteAircraft";
import { DeleteAttachment } from "../types/admin/DeleteAttachment";
import { DeleteClient, DeleteClientVariables } from "../types/admin/DeleteClient";
import { DeleteLocation, DeleteLocationVariables } from "../types/admin/DeleteLocation";
import { DeleteProject, DeleteProjectVariables } from "../types/admin/DeleteProject";
import { DeleteTask, DeleteTaskVariables } from "../types/admin/DeleteTask";
import { DeleteTimeSheetEntry, DeleteTimeSheetEntryVariables } from "../types/admin/DeleteTimeSheetEntry";
import { UpdateTimeSheetEntry, UpdateTimeSheetEntryVariables } from "../types/admin/UpdateTimeSheetEntry";
import { DeleteUser, DeleteUserVariables } from "../types/admin/DeleteUser";
import { GetCameras } from "../types/admin/GetCameras";
import { GetMeQuery } from "../types/admin/GetMeQuery";
// eslint-disable-next-line @typescript-eslint/camelcase
import { GetUploadUrl, GetUploadUrl_asAdmin_getUploadUrl } from "../types/admin/GetUploadUrl";
import { CreateTimeEntrySheetRequest, EntityType, MessageCreateRequest, ProjectRole } from "../types/admin/globalTypes";
import { ListAircraft } from "../types/admin/ListAircraft";
import { ListClients } from "../types/admin/ListClients";
import { ListLocations } from "../types/admin/ListLocations";
import { ListProjects } from "../types/admin/ListProjects";
import { ListTasks } from "../types/admin/ListTasks";
import { ListTimesheets } from "../types/admin/ListTimesheets";
import { ListUsers } from "../types/admin/ListUsers";
import { LocationFields } from "../types/admin/LocationFields";
import { MessageFields } from "../types/admin/MessageFields";
import { OnAddedMessage } from "../types/admin/OnAddedMessage";
import { ProjectFields } from "../types/admin/ProjectFields";
import { RemoveProjectUser } from "../types/admin/RemoveProjectUser";
import { RemoveTaskUser, RemoveTaskUserVariables } from "../types/admin/RemoveTaskUser";
import { TaskFields } from "../types/admin/TaskFields";
import { TaskUserFields } from "../types/admin/TaskUserFields";
import { TimesheetFields } from "../types/admin/TimesheetFields";
import { UpdateAircraft, UpdateAircraftVariables } from "../types/admin/UpdateAircraft";
import { UpdateClient, UpdateClientVariables } from "../types/admin/UpdateClient";
import { UpdateLocation, UpdateLocationVariables } from "../types/admin/UpdateLocation";
import { UpdateProject, UpdateProjectVariables } from "../types/admin/UpdateProject";
import { UpdateTask, UpdateTaskVariables } from "../types/admin/UpdateTask";
import { UpdateUser, UpdateUserVariables } from "../types/admin/UpdateUser";
import { UserFields } from "../types/admin/UserFields";
import { useFirebase } from "./useFirebase";
import useMultiTenant from "./useMultiTenant";
import { sortByProperty } from "./useSorted";

export interface AdminApiContextT {
  isLoading: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: AWSAppSyncClient<any> | null;

  me: UserFields | null;

  aircraft: AircraftFields[];
  clients: ClientFields[];
  locations: LocationFields[];
  projects: ProjectFields[];
  tasks: TaskFields[];
  users: UserFields[];

  addAircraft(input: AddAircraftVariables): Promise<AircraftFields | void>;
  updateAircraft(input: UpdateAircraftVariables): Promise<AircraftFields | void>;
  deleteAircraft(input: DeleteAircraftVariables): Promise<void>;

  // eslint-disable-next-line @typescript-eslint/camelcase
  getUploadUrl(mimeType: string): Promise<GetUploadUrl_asAdmin_getUploadUrl>;
  addAttachment(input: AddAttachmentVariables): Promise<AttachmentFields | void>;
  deleteAttachment(id: AttachmentFields): Promise<void>;

  addUser(input: AddUserVariables): Promise<UserFields | void>;
  updateUser(input: UpdateUserVariables): Promise<UserFields | void>;
  deleteUser(input: DeleteUserVariables): Promise<void>;

  addClient(input: AddClientVariables): Promise<ClientFields | void>;
  updateClient(input: UpdateClientVariables): Promise<ClientFields | void>;
  deleteClient(input: DeleteClientVariables): Promise<void>;

  addLocation(input: AddLocationVariables): Promise<LocationFields | void>;
  updateLocation(input: UpdateLocationVariables): Promise<LocationFields | void>;
  deleteLocation(input: DeleteLocationVariables): Promise<void>;

  addMessage(input: MessageCreateRequest): Promise<MessageFields | void>;

  addProject(input: AddProjectVariables): Promise<ProjectFields | void>;
  addProjectUser(input: AddProjectUserVariables): Promise<void>;
  removeProjectUser(projectId: string, userId: string): Promise<void>;
  updateProject(input: UpdateProjectVariables): Promise<ProjectFields | void>;
  deleteProject(input: DeleteProjectVariables): Promise<void>;

  addTask(input: AddTaskVariables): Promise<TaskFields | void>;
  assignUserToTask(input: AssignUserToTaskVariables): Promise<TaskUserFields | void>;
  removeTaskUser(input: RemoveTaskUserVariables): Promise<void>;
  updateTask(input: UpdateTaskVariables): Promise<TaskFields | void>;
  deleteTask(input: DeleteTaskVariables): Promise<void>;

  getCameras(projectId?: string): Promise<CameraFields[]>;
  addTimesheet(input: CreateTimeEntrySheetRequest): Promise<TimesheetFields | null>;
  getTimesheets(from: dayjs.Dayjs, to: dayjs.Dayjs): Promise<TimesheetFields[]>;
  updateTimeSheetEntry(input: UpdateTimeSheetEntryVariables): Promise<any>;
  deleteTimeSheetEntry(input: DeleteTimeSheetEntryVariables): Promise<string | void>;
}

export const Context = createContext({ isLoading: true } as AdminApiContextT);
export const useAdminApi = (): AdminApiContextT => useContext(Context);

export const AdminApiProvider: React.FC = ({ children }) => {
  const { user } = useFirebase();
  const { tenantId } = useMultiTenant();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [client, setClient] = useState<AWSAppSyncClient<any> | null>(null);

  const [me, setMe] = useState<UserFields | null>(null);

  const [aircraft, setAircraft] = useState<AircraftFields[]>([]);
  const [clients, setClients] = useState<ClientFields[]>([]);
  const [locations, setLocations] = useState<LocationFields[]>([]);
  const [projects, setProjects] = useState<ProjectFields[]>([]);
  const [tasks, setTasksState] = useState<TaskFields[]>([]);
  const [users, setUsers] = useState<UserFields[]>([]);

  const tasksRef = useRef<TaskFields[]>([]);

  const [isLoading, setLoading] = useState<boolean>(true);

  const setTasks = (f: (p: TaskFields[]) => TaskFields[]) => {
    tasksRef.current = f(tasksRef.current);
    setTasksState(tasksRef.current);
  };

  const addMessageToTask = (mesg: MessageFields) => {
    const task = tasksRef.current.find((it) => it.id === mesg.taskId);
    if (!task) return;

    if (!task.messages) task.messages = { __typename: "MessageList", items: [] };
    task.messages.items.push(mesg);

    // We filter against ID (as datetime) and userId as it's a composite ID.
    const found = !!task.messages.items.find((it) => it.id === mesg.id && it.userId === mesg.userId);
    if (found) return;

    setTasks((p) => p.map((it) => (it.id === task.id ? task : it)));
  };

  const addOperation =
    <T, V, R>(mutation: DocumentNode, unpack: (v: T) => R, setState: React.Dispatch<React.SetStateAction<R[]>>) =>
    async (input: V): Promise<R | undefined> => {
      const res = await client?.mutate<T>({
        mutation,
        variables: { ...input },
      });

      if (res?.data) {
        const item = unpack(res.data);
        setState((p) => [...p, item]);
        return item;
      }
    };

  const updateOperation =
    <T, V, R extends { id: string }>(
      mutation: DocumentNode,
      unpack: (v: T) => R,
      setState: React.Dispatch<React.SetStateAction<R[]>>,
    ) =>
    async (input: V): Promise<R | undefined> => {
      const res = await client?.mutate<T>({
        mutation,
        variables: { ...input },
      });

      if (res?.data) {
        const item = unpack(res.data);
        setState((p) => p.map((it) => (it.id === item.id ? item : it)));
        return item;
      }
      return;
    };

  const deleteOperation =
    <T, V extends { id: string }, R extends { id: string }>(
      mutation: DocumentNode,
      unpack: (v: T) => R,
      setState: React.Dispatch<React.SetStateAction<R[]>>,
    ) =>
    async (input: V) => {
      const res = await client?.mutate<T>({
        mutation,
        variables: { ...input },
      });

      if (res?.data) {
        setState((p) => p.filter((it) => it.id !== input.id));
      }
      return;
    };

  useEffect(() => {
    if (!user) return;

    const client = new AWSAppSyncClient({
      url: process.env.REACT_APP_ADMIN_API_URL,
      region: "us-east-1",
      auth: {
        type: AUTH_TYPE.OPENID_CONNECT,
        jwtToken: () => user.getIdToken(),
      },
      offlineConfig: {
        keyPrefix: "adminApiPersist",
      },
      disableOffline: true,
    });

    setClient(client);
  }, [user]);

  useEffect(() => {
    if (client === null || me === null) return;

    console.info("PROFILE:", me);
  }, [client, me]);

  useEffect(() => {
    if (client === null) return;

    Promise.all([
      client.query<GetMeQuery>({ query: getMeQuery, errorPolicy: "ignore" }).then((response) => {
        setMe(response.data.asAdmin?.getMe || null);
      }),
      client.query<ListAircraft>({ query: listAircraftQuery, errorPolicy: "ignore" }).then((response) => {
        setAircraft(response.data.asAdmin?.listAircraft.items || []);
      }),
      client.query<ListClients>({ query: listClientsQuery, errorPolicy: "ignore" }).then((response) => {
        setClients(response.data.asAdmin?.listClients.items || []);
      }),
      client.query<ListLocations>({ query: listLocationsQuery, errorPolicy: "ignore" }).then((response) => {
        setLocations(response.data.asAdmin?.listLocations.items || []);
      }),
      client.query<ListProjects>({ query: listProjectsQuery, errorPolicy: "ignore" }).then((response) => {
        setProjects(response.data.asAdmin?.listProjects.items || []);
      }),
      client.query<ListUsers>({ query: listUsersQuery, errorPolicy: "ignore" }).then((response) => {
        setUsers(response.data.asAdmin?.listUsers.items || []);
      }),
    ])
      .then(() => setLoading(false))
      .catch((e) => console.error(e));

    client
      .subscribe<{ data?: OnAddedMessage }>({
        query: onAddedMessageSubscription,
        variables: { tenantId },
      })
      .forEach((it) => {
        const mesg = it?.data?.onAddedMessage;
        if (!mesg) return;
        addMessageToTask(mesg);
      });
  }, [client]);

  useEffect(() => {
    if (!client) return;

    // XXX - Implement pre-emptive caching nicely, and have proper data fetch requesting.
    projects.forEach(async (it) => {
      client
        ?.query<ListTasks>({
          query: listTasksQuery,
          variables: { criteria: { parentId: it.id, status: null } },
          errorPolicy: "ignore",
        })
        .then((response) => {
          setTasks((p) => [...p, ...(response.data.asAdmin?.listTasks.items || [])]);
        });
    });
  }, [client, projects]);

  useEffect(() => {
    setTasksState(tasksRef.current);
  }, [tasksRef.current]);

  const addAircraft = addOperation<AddAircraft, AddAircraftVariables, AircraftFields>(
    addAircraftMutation,
    (v: AddAircraft) => v.asAdmin?.addAircraft as AircraftFields,
    setAircraft,
  );

  const updateAircraft = updateOperation<UpdateAircraft, UpdateAircraftVariables, AircraftFields>(
    updateAircraftMutation,
    (v: UpdateAircraft) => v.asAdmin?.updateAircraft as AircraftFields,
    setAircraft,
  );

  const deleteAircraft = deleteOperation<DeleteAircraft, DeleteAircraftVariables, AircraftFields>(
    deleteAircraftMutation,
    (v: DeleteAircraft) => v.asAdmin?.deleteAircraft as AircraftFields,
    setAircraft,
  );

  const getUploadUrl = async (mimeType: string) => {
    const response = await client?.query<GetUploadUrl>({
      query: getUploadUrlQuery,
      variables: { mimeType },
    });

    if (!response?.data.asAdmin?.getUploadUrl) {
      throw new Error("UPLOAD_FAILED");
    }

    return response.data.asAdmin.getUploadUrl;
  };

  const addAttachment = async (input: AddAttachmentVariables) => {
    const response = await client?.mutate<AddAttachment>({
      mutation: addAttachmentMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.addAttachment) {
      throw new Error("FAILED");
    }

    const item = response.data.asAdmin.addAttachment;

    if (item.entityType === EntityType.AIRCRAFT) {
      console.warn("NOT IMPLEMENTED YET");
    } else if (item.entityType === EntityType.TASK) {
      setTasks((p) => {
        const task = p.find((it) => it.id === item.entityId);
        if (!task) return p;
        if (!task?.attachments) task.attachments = { __typename: "AttachmentList", items: [] };
        task.attachments.items.push(item);
        return p.map((it) => (it.id === item.entityId ? task : it));
      });
    }

    return response.data.asAdmin.addAttachment;
  };

  const deleteAttachment = async (item: AttachmentFields) => {
    const response = await client?.mutate<DeleteAttachment>({
      mutation: deleteAttachmentMutation,
      variables: { id: item.id },
    });

    if (!response?.data?.asAdmin?.deleteAttachment) return;

    if (item.entityType === EntityType.AIRCRAFT) {
      console.warn("NOT IMPLEMENTED YET");
    } else if (item.entityType === EntityType.TASK) {
      setTasks((p) => {
        const task = p.find((it) => it.id === item.entityId);
        if (!task || !task.attachments) return p;
        task.attachments.items = task.attachments.items.filter((it) => it.id === item.id);
        return p.map((it) => (it.id === task.id ? task : it));
      });
    }
  };

  const addClient = addOperation<AddClient, AddClientVariables, ClientFields>(
    addClientMutation,
    (v: AddClient) => v.asAdmin?.addClient as ClientFields,
    setClients,
  );

  const updateClient = updateOperation<UpdateClient, UpdateClientVariables, ClientFields>(
    updateClientMutation,
    (v: UpdateClient) => v.asAdmin?.updateClient as ClientFields,
    setClients,
  );

  const deleteClient = deleteOperation<DeleteClient, DeleteClientVariables, ClientFields>(
    deleteClientMutation,
    (v: DeleteClient) => v.asAdmin?.deleteClient as ClientFields,
    setClients,
  );

  const addLocation = addOperation<AddLocation, AddLocationVariables, LocationFields>(
    addLocationMutation,
    (v: AddLocation) => v.asAdmin?.addLocation as LocationFields,
    setLocations,
  );

  const updateLocation = updateOperation<UpdateLocation, UpdateLocationVariables, LocationFields>(
    updateLocationMutation,
    (v: UpdateLocation) => v.asAdmin?.updateLocation as LocationFields,
    setLocations,
  );

  const deleteLocation = deleteOperation<DeleteLocation, DeleteLocationVariables, LocationFields>(
    deleteLocationMutation,
    (v: DeleteLocation) => v.asAdmin?.deleteLocation as LocationFields,
    setLocations,
  );

  const addMessage = async (input: MessageCreateRequest) => {
    const response = await client?.mutate<AddMessage>({
      mutation: addMessageMutation,
      variables: { input },
    });

    if (!response?.data?.addMessage) return;

    const mesg = response.data.addMessage;
    const task = tasks.find((it) => it.id === mesg.taskId);
    if (!task) return;

    if (!task.messages) task.messages = { __typename: "MessageList", items: [] };
    task.messages.items.push(mesg);

    setTasks((p) => p.map((it) => (it.id === task.id ? task : it)));
  };

  const addProject = addOperation<AddProject, AddProjectVariables, ProjectFields>(
    addProjectMutation,
    (v: AddProject) => v.asAdmin?.addProject as ProjectFields,
    setProjects,
  );

  const addProjectUser = async ({ input }: AddProjectUserVariables) => {
    const response = await client?.mutate<AddProjectUser>({
      mutation: addProjectUserMutation,
      variables: { input },
    });

    if (!response?.data?.asAdmin?.addProjectUser) return;

    const assignment = response?.data?.asAdmin?.addProjectUser;
    const project = projects.find((it) => it.id === input.projectId);

    if (!assignment || !project) return;
    if (!project.users) project.users = { __typename: "ProjectUserList", items: [] };

    project.users?.items.push({ ...assignment });
    setProjects((p) => p.map((proj) => (proj.id === project.id ? project : proj)));
  };

  const removeProjectUser = async (projectId: string, userId: string) => {
    const response = await client?.mutate<RemoveProjectUser>({
      mutation: removeProjectUserMutation,
      variables: { projectId, userId },
    });

    if (!response?.data?.asAdmin?.removeProjectUser) return;

    const project = projects.find((it) => it.id === projectId);

    if (!project) return;
    if (!project?.users) project.users = { __typename: "ProjectUserList", items: [] };

    project.users.items = project.users.items.filter((it) => it.userId !== userId);
    setProjects((p) => p.map((proj) => (proj.id === project.id ? project : proj)));
  };

  const updateProject = updateOperation<UpdateProject, UpdateProjectVariables, ProjectFields>(
    updateProjectMutation,
    (v: UpdateProject) => v.asAdmin?.updateProject as ProjectFields,
    setProjects,
  );

  const deleteProject = deleteOperation<DeleteProject, DeleteProjectVariables, ProjectFields>(
    deleteProjectMutation,
    (v: DeleteProject) => v.asAdmin?.deleteProject as ProjectFields,
    setProjects,
  );

  /* const addTask = addOperation<AddTask, AddTaskVariables, TaskFields>(
    addTaskMutation,
    (v: AddTask) => v.asAdmin?.addTask as TaskFields,
    setTasks,
  ); */
  const addTask = async (input: AddTaskVariables) => {
    const response = await client?.mutate<AddTask>({
      mutation: addTaskMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.addTask) return;

    const task = response?.data?.asAdmin?.addTask as TaskFields;
    if (task.parentId === task.projectId) {
      setTasks((p) => [...p, task]);
    } else {
      const parent = tasks.find((it) => it.id === task.parentId);
      if (!parent) return;

      if (!parent.tasks) parent.tasks = { __typename: "TaskList", items: [] };
      parent.tasks.items = [...parent.tasks.items, task].sort(sortByProperty("orderIndex"));
      setTasks((p) => p.map((it) => (it.id === parent.id ? parent : it)));
    }

    return task;
  };

  const updateTask = async (input: UpdateTaskVariables) => {
    const response = await client?.mutate<UpdateTask>({
      mutation: updateTaskMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.updateTask) return;

    const task = response?.data?.asAdmin?.updateTask as TaskFields;
    if (task.parentId === task.projectId) {
      setTasks((p) => p.map((it) => (it.id === task.id ? task : it)));
    } else {
      const parent = tasks.find((it) => it.id === task.parentId);
      if (!parent) return;

      if (!parent.tasks) parent.tasks = { __typename: "TaskList", items: [] };
      parent.tasks.items = parent.tasks?.items
        .map((it) => (it.id === task.id ? task : it))
        .sort(sortByProperty("orderIndex"));
      setTasks((p) => p.map((it) => (it.id === parent.id ? parent : it)));
    }

    return task;
  };

  const assignUserToTask = async (input: AssignUserToTaskVariables) => {
    const response = await client?.mutate<AssignUserToTask>({
      mutation: assignUserToTaskMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.assignUserToTask) return;

    const { taskId, userId } = response.data.asAdmin.assignUserToTask;

    const task = tasks.find((it) => it.id === taskId);
    if (!task) return;

    const user = users.find((it) => it.id === userId);

    const project = projects.find((it) => it.id === task.projectId);
    if (!project) return;

    const { id: projectId } = project;

    // Check to see if user is already a project user, if not, also add them to project.
    if (!project?.users?.items.find((it) => it.userId === userId)) {
      await addProjectUser({
        input: { clientId: user?.clientId || "", projectId, userId, role: ProjectRole.technician },
      });
    }

    setTasks((p) =>
      p.map((task) => {
        if (taskId !== task.id) return task;

        if (!task.users) task.users = { __typename: "TaskUserList", items: [] };
        task.users.items.push({ __typename: "TaskUser", userId });
        return task;
      }),
    );
  };

  const removeTaskUser = async (input: RemoveTaskUserVariables) => {
    const response = await client?.mutate<RemoveTaskUser>({
      mutation: removeTaskUserMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.removeTaskUser) return;

    setTasks((p) =>
      p.map((task) => {
        if (input.id !== task.id) return task;
        if (!task.users) task.users = { __typename: "TaskUserList", items: [] };
        task.users.items = task.users.items.filter((it) => it.userId !== input.userId);
        return task;
      }),
    );
  };

  const deleteTask = async (input: DeleteTaskVariables) => {
    const response = await client?.mutate<DeleteTask>({
      mutation: deleteTaskMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.deleteTask) return;
    tasksRef.current = tasksRef.current.filter((p) => p.id !== input.id);
  };

  const addUser = async (input: AddUserVariables) => {
    const response = await client?.mutate<AddUser>({
      mutation: addUserMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.addUser) return;
    const user = response.data.asAdmin.addUser;

    setUsers((p) => [...p, user]);

    if (input.projectId && input.projectRole) {
      const project = projects.find((it) => it.id === input.projectId);

      if (!project) return;

      project?.users?.items.push({ __typename: "ProjectUser", userId: user.id, role: input.projectRole });
      setProjects((p) => p.map((it) => (it.id === project.id ? project : it)));
    }
  };

  const deleteUser = deleteOperation<DeleteUser, DeleteUserVariables, UserFields>(
    deleteUserMutation,
    (v: DeleteUser) => v.asAdmin?.deleteUser as UserFields,
    setUsers,
  );

  const updateUser = updateOperation<UpdateUser, UpdateUserVariables, UserFields>(
    updateUserMutation,
    (v: UpdateUser) => v.asAdmin?.updateUser as UserFields,
    setUsers,
  );

  const getCameras = async (projectId?: string): Promise<CameraFields[]> => {
    const response = await client?.query<GetCameras>({
      query: getCamerasQuery,
      variables: { projectId },
    });
    return (response?.data.asAdmin?.getCameras as CameraFields[]) || [];
  };

  const addTimesheet = async (input: CreateTimeEntrySheetRequest): Promise<TimesheetFields | null> => {
    const response = await client?.mutate<AddTimeSheetEntry>({
      mutation: addTimeSheetEntryMutation,
      variables: { input },
    });
    return (response?.data?.asAdmin?.addTimeSheetEntry as TimesheetFields) || null;
  };

  const getTimesheets = async (from: dayjs.Dayjs, to: dayjs.Dayjs): Promise<TimesheetFields[]> => {
    const response = await client?.query<ListTimesheets>({
      query: listTimesheetsQuery,
      variables: { fromDT: from.toISOString(), toDT: to.toISOString() },
    });

    return response?.data.asAdmin?.listTimeSheets.items || [];
  };

  // todo: type param and return
  const updateTimeSheetEntry = async (input: UpdateTimeSheetEntryVariables) => {
    console.log(input);

    const response = await client?.mutate<UpdateTimeSheetEntry>({
      mutation: updateTimeSheetEntryMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.updateTimeSheetEntry) return;
    return response?.data?.asAdmin?.updateTimeSheetEntry;
  };

  const deleteTimeSheetEntry = async (input: DeleteTimeSheetEntryVariables) => {
    const response = await client?.mutate<DeleteTimeSheetEntry>({
      mutation: deleteTimeSheetEntryMutation,
      variables: input,
    });

    if (!response?.data?.asAdmin?.deleteTimeSheetEntry) return;
    console.log("successfully deleted time sheet entry");
  };

  return (
    <Context.Provider
      value={{
        isLoading,
        client,
        me,
        aircraft,
        clients,
        locations,
        projects,
        tasks,
        users,
        addAircraft,
        updateAircraft,
        deleteAircraft,
        getUploadUrl,
        addAttachment,
        deleteAttachment,
        addClient,
        updateClient,
        deleteClient,
        addLocation,
        updateLocation,
        deleteLocation,
        addMessage,
        addProject,
        addProjectUser,
        removeProjectUser,
        updateProject,
        deleteProject,
        addTask,
        assignUserToTask,
        removeTaskUser,
        updateTask,
        deleteTask,
        addUser,
        updateUser,
        deleteUser,
        getCameras,
        addTimesheet,
        getTimesheets,
        deleteTimeSheetEntry,
        updateTimeSheetEntry,
      }}
    >
      {children}
    </Context.Provider>
  );
};
