import React from "react";

import styled from "styled-components";

import BannerImage from "../../assets/images/signin-banner.jpg";

import RbrAviationLogo from "../../assets/images/rbr_aviation_logo.png";
import useMultiTenant from "../../hooks/useMultiTenant";

const Root = styled.div<{ logoUrl: string }>`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #042459;

  background-image: url(${(p) => p.logoUrl});
  background-size: 200px;
  background-repeat: no-repeat;
  background-position-x: 48px;
  background-position-y: 48px;
`;
const Container = styled.div`
  padding-right: 400px;

  height: 400px;

  border-radius: 0.25rem;

  background: white url(${BannerImage});
  background-position-x: calc(100% + 43px);
  background-repeat: no-repeat;
  background-size: contain;
`;
const Content = styled.div`
  margin-right: 40px;
  width: 100%;
`;

export const SignInLayout: React.FC = ({ children }) => {
  const { tenantInfo } = useMultiTenant();

  return (
    <Root logoUrl={tenantInfo?.logoImageUrl || RbrAviationLogo}>
      <Container>
        <Content>{children}</Content>
      </Container>
    </Root>
  );
};
