import { useParams } from "react-router";

import { TaskFields } from "../../types/admin/TaskFields";
import { useAdminApi } from "../useAdminApi";

interface ParamsT {
  projectId: string;
  taskId: string;
}
export const useTask = (): TaskFields | undefined => {
  const { projectId, taskId } = useParams<ParamsT>();

  const { tasks } = useAdminApi();
  return tasks.find((it: TaskFields) => it.parentId === projectId && it.id === taskId);
};
