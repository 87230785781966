import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router";

import { TaskFields } from "../../../../../types/admin/TaskFields";

import useLayout from "../../../../../hooks/useLayout";

import { useProject } from "../../../../../hooks/params/useProject";
import { useTask } from "../../../../../hooks/params/useTask";

import { PageHeader } from "../../../../../components/PageHeader";
import { PageContent, PageMain } from "../../../../../components/PageStyles";

import { TabContainer } from "../../../../../components/TabContainer";

import { TaskFormDialog } from "../../../../../components/tasks/TaskFormDialog";
import { TaskList } from "../../../../../components/tasks/TaskList";

import { TaskAttachmentsPage } from "./attachments";
import { TaskMessagesPage } from "./messages";
import { TaskPeoplePage } from "./people";
import { TaskPartsPage } from "./parts";
import { TaskTimesheetsPage } from './timesheets'

import { AttachmentFormDialog } from "../../../../../components/attachments/AttachmentFormDialog";

import { TabBar, Tab } from "@rmwc/tabs";
import "@rmwc/tabs/styles";

export const ProjectTaskViewPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const { setBreadCrumbs } = useLayout();

  const project = useProject();
  const task = useTask();

  const resourcePrefix = `/projects/${project?.id}/tasks/${task?.id}`;

  const PAGE_TABS = [
    {
      icon: "checklist",
      label: "Todos",
      path: `${resourcePrefix}/todos`,
    },
    {
      icon: "handyman",
      label: "Parts & Tools",
      path: `${resourcePrefix}/parts`,
    },
    {
      icon: "people",
      label: "People",
      path: `${resourcePrefix}/people`,
    },
    {
      icon: "message",
      label: "Messages",
      path: `${resourcePrefix}/messages`,
    },
    {
      icon: "upload_file",
      label: "Attachments",
      path: `${resourcePrefix}/attachments`,
    },
    {
      icon: "today",
      label: "Timesheets",
      path: `${resourcePrefix}/timesheets`,
    },
  ];

  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path)),
  );

  useEffect(() => {
    if (location.pathname.endsWith("/edit")) return;
    const tabIndex = PAGE_TABS.findIndex((it) => location.pathname.startsWith(it.path));
    if (tabIndex === activeTabIndex) return;
    setActiveTabIndex(tabIndex === -1 ? 0 : tabIndex);
  }, [location, PAGE_TABS]);

  useEffect(() => {
    if (!setBreadCrumbs) return;
    if (!project || !task) return;

    setBreadCrumbs([
      {
        label: "Projects",
        path: "/dashboard",
      },
      {
        label: project?.name,
        path: `/projects/${project?.id}`,
      },
      {
        label: `${task.taskNumber} - ${task.name}`,
        path: `${resourcePrefix}`,
      },
    ]);
  }, [setBreadCrumbs, project, task]);

  const onTabActivated = (ev: CustomEvent) => {
    const { path } = PAGE_TABS[ev.detail.index];
    if (location.pathname.startsWith(path) || location.pathname.endsWith("/edit")) return;
    history.replace(path);
  };

  if (!project || !task) return <></>;

  return (
    <>
      <Route path="/projects/:projectId/tasks/:taskId/attachments/new">
        <AttachmentFormDialog action="create" />
      </Route>

      <Route exact path={`/projects/:projectId/tasks/:taskId/todos/new`}>
        <TaskFormDialog action="create" />
      </Route>

      <Route exact path={`/projects/:projectId/tasks/:taskId/edit`}>
        <TaskFormDialog action="update" />
      </Route>

      <PageHeader
        compact
        editable
        title={`${task.taskNumber} - ${task.name}`}
        subtitle={project.name}
        onEditClick={() => history.push(`${resourcePrefix}/edit`)}
        onSubtitleClick={() => history.push(`/projects/${project.id}`)}
      />

      <TabContainer>
        <TabBar {...{ activeTabIndex: activeTabIndex === -1 ? 0 : activeTabIndex }} onActivate={onTabActivated}>
          {PAGE_TABS.map((it, i) => (
            <Tab key={i} icon={it.icon}>
              {it.label}
            </Tab>
          ))}
        </TabBar>
      </TabContainer>

      <PageMain style={{ paddingTop: "32px" }}>
        <PageContent>
          <Switch>
            <Route path="/projects/:projectId/tasks/:taskId/parts">
              <TaskPartsPage />
            </Route>

            <Route path="/projects/:projectId/tasks/:taskId/attachments">
              <TaskAttachmentsPage />
            </Route>

            <Route path="/projects/:projectId/tasks/:taskId/messages">
              <TaskMessagesPage />
            </Route>

            <Route path="/projects/:projectId/tasks/:taskId/people">
              <TaskPeoplePage />
            </Route>

            <Route path="/projects/:projectId/tasks/:taskId/timesheets" component={TaskTimesheetsPage} />

            <Route path={["/projects/:projectId/tasks/:taskId", "/projects/:projectId/tasks/:taskId/todos"]}>
              <TaskList project={project} items={task.tasks?.items as TaskFields[]} />
            </Route>
          </Switch>
        </PageContent>
      </PageMain>
    </>
  );
};
