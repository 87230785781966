import React from "react";

import { formatPrice } from "../../common";

import { PartFields } from "../../types/admin/PartFields";

import { Button } from "../../components/Button";

import {
  DataCellTools,
  Table,
  TableContainer,
  TableHead,
  TableTitle,
  TableToolbarTitle,
  TitleCount,
} from "../../components/Table";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import { Checkbox } from "@rmwc/checkbox";
import "@rmwc/checkbox/styles";

import Palette from "../../palette.json";

interface PartListProps {
  items: (PartFields | null)[];
}

export const PartList: React.FC<PartListProps> = ({ items }) => {
  const cols: string[] = ["Part Number", "Name", "Qty.", "Unit Price", "Lead Time", "Billable?", "Total"];

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          Parts <TitleCount>({items.length})</TitleCount>
        </TableToolbarTitle>

        {/* <Button raised onClick={() => console.info("TBD")}>
          Manage Parts
        </Button> */}
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead {...{ cols }} />

          <DataTableBody>
            {items.length === 0 && (
              <DataTableRow>
                <DataTableCell
                  colSpan={cols.length}
                  style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}
                >
                  - No Results -
                </DataTableCell>
              </DataTableRow>
            )}

            {items.map((it, i) => (
              <DataTableRow key={i}>
                <DataTableCell>{it?.number}</DataTableCell>
                <DataTableCell>{it?.name}</DataTableCell>
                <DataTableCell>{it?.quantity}</DataTableCell>
                <DataTableCell>{formatPrice(it?.cost as number)}</DataTableCell>
                <DataTableCell>{it?.leadTimeDays}</DataTableCell>
                <DataTableCell>
                  <Checkbox disabled checked={it?.isBillable || false} />
                </DataTableCell>
                <DataTableCell>{it?.isBillable ? formatPrice(it.markupCost as number) : "-"}</DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
