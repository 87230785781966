import React, { useEffect, useState } from "react";

import { UserFields } from "../../types/admin/UserFields";
import { ProjectFields_users_items } from "../../types/admin/ProjectFields";

import { Button } from "../Button";

import { Table, TableContainer } from "../Table";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import { ListItemPrimaryText, ListItemSecondaryText, ListItemText } from "@rmwc/list";
import "@rmwc/list/styles";

import { MenuItem, SimpleMenu } from "@rmwc/menu";
import "@rmwc/menu/styles";

import Palette from "../../palette.json";
import { ProjectRole } from "../../types/admin/globalTypes";
import { USER_ROLE_TO_LABEL } from "../../common";

interface ProjectUserListPropsT {
  items: UserFields[];

  assigned: ProjectFields_users_items[];

  onAssignUser(role: ProjectRole | null, user: UserFields): void;
  onRemoveUser(user: UserFields): void;
}

export const ProjectUserAssignList: React.FC<ProjectUserListPropsT> = ({
  items,
  assigned,
  onAssignUser,
  onRemoveUser,
}) => {
  const [userRoles, setUserRoles] = useState<(ProjectRole | null)[]>([]);

  useEffect(() => {
    const roles: (ProjectRole | null)[] = items.map((user) => {
      const assignment = assigned.find((it) => it.userId === user.id);
      if (!assignment) return null;
      return assignment.role;
    });

    setUserRoles(roles);
  }, [items]);

  return (
    <TableContainer>
      <Table>
        <DataTableContent>
          <DataTableBody>
            {items.length === 0 && (
              <DataTableRow>
                <DataTableCell colSpan={3} style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}>
                  - No People -
                </DataTableCell>
              </DataTableRow>
            )}
            {items.map((it, i) => (
              <DataTableRow key={i}>
                <DataTableCell>
                  <ListItemText>
                    <ListItemPrimaryText style={{ marginTop: "-20px" }}>{it.name}</ListItemPrimaryText>
                    <ListItemSecondaryText>{it.email}</ListItemSecondaryText>
                  </ListItemText>
                </DataTableCell>
                <DataTableCell>
                  <SimpleMenu
                    anchorCorner="bottomStart"
                    focusOnOpen={false}
                    onSelect={(ev) => {
                      const roles: (ProjectRole | null)[] = userRoles.slice();
                      roles[i] = [ProjectRole.manager, ProjectRole.technician, ProjectRole.customer][ev.detail.index];
                      setUserRoles(roles);
                    }}
                    handle={
                      <Button outlined trailingIcon="unfold_more">
                        {userRoles[i]
                          ? USER_ROLE_TO_LABEL[userRoles[i] || ProjectRole.technician]
                          : "-- Select Role --"}
                      </Button>
                    }
                  >
                    <MenuItem>Manager</MenuItem>
                    <MenuItem>Technician</MenuItem>
                    <MenuItem>Customer</MenuItem>
                  </SimpleMenu>
                </DataTableCell>
                <DataTableCell>
                  <Button
                    style={{ width: "7rem" }}
                    outlined={!assigned.find((jt) => jt.userId === it.id)}
                    raised={!!assigned.find((jt) => jt.userId === it.id)}
                    onClick={() =>
                      assigned.find((jt) => jt.userId === it.id) ? onRemoveUser(it) : onAssignUser(userRoles[i], it)
                    }
                  >
                    {!!assigned.find((jt) => jt.userId === it.id) ? "Assigned" : "Assign"}
                  </Button>
                </DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
