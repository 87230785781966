import React, { useState } from "react";
import { useHistory } from "react-router";

import { USER_ROLE_TO_LABEL } from "../../common";

import { ProjectRole } from "../../types/admin/globalTypes";
import { UserFields } from "../../types/admin/UserFields";

import { useAdminApi } from "../../hooks/useAdminApi";
import { useClient } from "../../hooks/params/useClient";
import { useProject } from "../../hooks/params/useProject";

import useSorted, { sortByProperty } from "../../hooks/useSorted";
import useFiltered from "../../hooks/useFiltered";

import { usePagenator } from "../../hooks/usePagenator";

import { dialogQueue } from "../../hooks/useDialogQueue";
import { snackbar } from "../../hooks/useNotifications";

import { Button } from "../../components/Button";
import { SearchInput } from "../TextInput";

import {
  DataCellTools,
  Table,
  TableContainer,
  TableHead,
  TableTitle,
  TableToolbarTitle,
  TitleCount,
} from "../../components/Table";

import { PagenatorControls } from "../PagenatorControls";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import { MenuItem, SimpleMenu } from "@rmwc/menu";
import "@rmwc/menu/styles";

import Palette from "../../palette.json";
import { useTask } from "../../hooks/params/useTask";

interface UserListProps {
  items: UserFields[];

  hiddenFields?: string[];
}

const COLUMN_LABELS = ["Name", "Role", "Email", "Phone Number", ""];

export const UserList: React.FC<UserListProps> = ({ items, hiddenFields = [] }) => {
  const history = useHistory();

  const { deleteUser, removeProjectUser, removeTaskUser } = useAdminApi();
  const client = useClient();
  const project = useProject();
  const parentTask = useTask();

  const [search, setSearch] = useState<string>("");

  const sorted = useSorted(items, sortByProperty("name"));

  const filterByRoleItems = ["All", "Staff", "Customer", "Administrator"];
  const [filterByRole, setFilterByRole] = useState<number>(0);

  const filtered = useFiltered(sorted, (it) => {
    let result = false;
    if (filterByRole === 0) result = true;
    else if (filterByRole === 1) result = !it.clientId && !it.isSystemAdministrator;
    else if (filterByRole === 2) result = !!it.clientId;
    else if (filterByRole === 3) result = !!it.isSystemAdministrator;

    return result && !!it?.name?.toLowerCase().includes(search.toLowerCase());
  });

  const { pageRange, ...controls } = usePagenator(8, filtered.length);

  const onDelete = async (user: UserFields) => {
    const hasConfirmed = await dialogQueue.confirm({
      title: "Are you sure?",
      body: project
        ? parentTask
          ? `Are you sure you want to unassign task user ${user.name}`
          : `Are you sure you want to remove project user ${user.name}`
        : `Are you sure you want to delete user: ${user.name}`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;

    if (parentTask) {
      await removeTaskUser({ id: parentTask.id, userId: user.id });
      snackbar.notify({ title: "User removed from task successfully!" });
      return;
    }

    if (project) {
      await removeProjectUser(project.id, user.id);
    } else {
      await deleteUser({ id: user.id });
    }

    snackbar.notify({ title: "User removed successfully!" });
  };

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          People <TitleCount>({filtered.length})</TitleCount>
        </TableToolbarTitle>

        <SearchInput placeholder="Search by Name" value={search} onChange={(ev) => setSearch(ev.currentTarget.value)} />

        <SimpleMenu
          anchorCorner="bottomStart"
          focusOnOpen={false}
          handle={
            <Button outlined trailingIcon="unfold_more">
              {filterByRoleItems[filterByRole]}
            </Button>
          }
          onSelect={(ev) => setFilterByRole(ev.detail.index)}
        >
          {filterByRoleItems.map((it, i) => (
            <MenuItem key={i}>{it}</MenuItem>
          ))}
        </SimpleMenu>

        <Button
          raised
          onClick={() => {
            if (project) {
              if (parentTask) {
                history.push(`/projects/${project.id}/tasks/${parentTask.id}/people/add`);
                return;
              }

              history.push(`/projects/${project.id}/people/add`);
              return;
            }

            history.push(`/people/new${client ? "?clientId=" + client.id : ""}`);
          }}
        >
          {project ? "Manage" : "Add Staff"}
        </Button>
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead cols={COLUMN_LABELS.filter((it) => !hiddenFields.includes(it))} />

          <DataTableBody>
            {items.length === 0 && (
              <DataTableRow>
                <DataTableCell
                  colSpan={COLUMN_LABELS.length - hiddenFields.length}
                  style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}
                >
                  - No People -
                </DataTableCell>
              </DataTableRow>
            )}
            {filtered.slice(...pageRange).map((it, i) => (
              <DataTableRow key={i}>
                {!hiddenFields.includes("Name") && <DataTableCell>{it.name}</DataTableCell>}
                {!hiddenFields.includes("Role") && (
                  <DataTableCell>
                    {!project && (it.isSystemAdministrator ? "Administrator" : it.clientId ? "Customer" : "Staff")}
                    {project &&
                      USER_ROLE_TO_LABEL[
                        project.users?.items.find((jt) => jt.userId === it.id)?.role || ProjectRole.technician
                      ]}
                  </DataTableCell>
                )}
                {!hiddenFields.includes("Email") && <DataTableCell>{it.email}</DataTableCell>}
                {!hiddenFields.includes("Phone Number") && <DataTableCell>{it.phoneNumber}</DataTableCell>}
                <DataTableCell>
                  <DataCellTools>
                    {!project && <IconButton icon="edit" onClick={() => history.push(`/people/${it.id}/edit`)} />}
                    <IconButton icon={project ? "person_remove" : "delete"} onClick={() => onDelete(it)} />
                  </DataCellTools>
                </DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </Table>

      <PagenatorControls {...controls} />
    </TableContainer>
  );
};
