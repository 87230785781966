import React from "react";

import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";

import styled, { css } from "styled-components";

import Palette from "../palette.json";

interface PageHeaderPropsT {
  title: string;
  subtitle?: string;
  compact?: boolean;
  editable?: boolean;

  onEditClick?(): void;
  onDeleteClick?(): void;
  onSubtitleClick?(): void;
}

const Root = styled.div<{ compact?: boolean }>`
  padding: 0 64px;

  display: flex;
  flex-direction: ${(p) => (!p.compact ? "column" : "row")};

  ${(p) =>
    p.compact &&
    css`
      align-items: center;
    `}

  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 0 0 #eaedf3;
`;
const Headings = styled.div`
  margin: 58px 0 46px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
const PageTitle = styled.h2`
  position: relative;

  margin-bottom: 20px;
  font-size: 34px;
  font-weight: bold;
  color: black;
  opacity: 0.8;

  .rmwc-icon {
    margin: 12px 16px;
    font-size: 20px;
    color: ${Palette.Blue};
    cursor: pointer;
  }

  .item-delete {
    position: absolute;
    top: -42px;
    right: -52px;
    color: #9ea0a5;
  }
`;
const PageSubtitle = styled.h4`
  font-size: 18px;
  font-weight: 500;
  color: #9ea0a5;
`;

const Content = styled.div`
  display: flex;
`;

export const PageHeader: React.FC<PageHeaderPropsT> = ({
  title,
  subtitle,
  compact,
  editable,
  onEditClick,
  onDeleteClick,
  onSubtitleClick,
  children,
}) => {
  return (
    <Root {...{ compact }}>
      <Headings>
        <PageTitle>
          {title}
          {editable && <Icon data-test-id="btn-edit-head" icon="edit" onClick={onEditClick} />}
          {onDeleteClick && (
            <Icon data-test-id="btn-delete-head" className="item-delete" icon="delete" onClick={onDeleteClick} />
          )}
        </PageTitle>

        {subtitle && (
          <PageSubtitle style={onSubtitleClick ? { cursor: "pointer" } : {}} onClick={onSubtitleClick}>
            {subtitle}
          </PageSubtitle>
        )}
      </Headings>

      <Content>{children}</Content>
    </Root>
  );
};
