import React from "react";
import { Route } from "react-router";

import { UserFields } from "../../../types/admin/UserFields";

import { useAdminApi } from "../../../hooks/useAdminApi";
import { useProject } from "../../../hooks/params/useProject";

import { UserList } from "../../../components/users/UserList";
import { UserFormDialog } from "../../../components/users/UserFormDialog";
import { UserAssignDialog } from "../../../components/users/UserAssignDialog";

export const ProjectPeoplePage: React.FC = () => {
  const { users } = useAdminApi();

  const project = useProject();

  const items = (project?.users?.items || []).map((it: { userId: string }) =>
    users.find((jt) => jt.id === it.userId),
  ) as UserFields[];

  if (!project) return <></>;

  return (
    <>
      <Route exact path="/projects/:projectId/people/add">
        <UserAssignDialog />
      </Route>

      <Route exact path="/projects/:projectId/people/new">
        <UserFormDialog action="create" />
      </Route>

      <UserList {...{ items }} />
    </>
  );
};
