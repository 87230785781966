import styled from "styled-components";

export const TabContainer = styled.div`
  position: relative;

  padding-left: 62px;
  border-top: 1px solid #eaedf3;
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 0 0 #eaedf3;

  display: flex;
  flex-direction: column;

  .mdc-tab-scroller__scroll-content {
    width: 100%;
    justify-content: flex-start;
    flex: 0 0 auto;
  }

  button {
    margin: 0 28px;

    padding: 28px 0 24px;
    height: auto;

    flex-grow: 0;
    font-size: 14px;
    font-weight: normal;
    text-transform: none;
    letter-spacing: normal;

    background-color: white;

    :first-of-type {
      margin-left: 0;
    }

    .mdc-tab__ripple {
      display: none;
    }

    .mdc-tab-indicator__content {
      border-top-width: 4px;
    }
  }
`;
