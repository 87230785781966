import { useParams } from "react-router";
import { useAdminApi } from "../useAdminApi";

interface ParamsT {
  clientId: string;
}
export const useClient = () => {
  const params = useParams<ParamsT>();
  const { clientId } = params;

  const { clients } = useAdminApi();
  const client = clients.find((it) => it.id === clientId);
  return client;
};
