import React, { useRef } from "react";

import { UseFormMethods } from "react-hook-form";
import { TextInput } from "./TextInput";

import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";

export interface PhoneInputProps {
  name: string;
  isInvalid?: boolean;
  register: UseFormMethods["register"];
  setValue: UseFormMethods["setValue"];
}

export const PhoneInput: React.FC<PhoneInputProps> = ({ name, isInvalid, register, setValue }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const intlTelRef = useRef<intlTelInput.Plugin | null>(null);

  const validate = (value: string) => {
    if (intlTelRef.current?.isValidNumber()) {
      setValue(name, intlTelRef.current.getNumber(intlTelInputUtils.numberFormat.E164)); // Doesn't trigger validate again, sets final E.164 value ready for submission
      return true;
    } else {
      return "Not a valid phone number";
    }
  };

  const handlePhoneNumberChange = (e: React.FormEvent<HTMLInputElement>) => {
    const rawValue = e.currentTarget.value;
    setValue(name, rawValue, { shouldValidate: true }); // Set the value for react-hook-form, but don't get E.164 value yet
  };

  const handleCountryCodeChange = () => {
    const countryCode = intlTelRef.current!.getSelectedCountryData().dialCode;
    setValue(name, inputRef.current?.value, { shouldValidate: true }); // Just triggering a revalidate of the number
  };

  const setRef = (e: HTMLInputElement) => {
    // TODO Might be an issue that we aren't handling teardown / recreate for changing element values but not an issue in testing so far
    // Tried a few ways of doing a teardown / recreate and it kept causing issues, many repeated intl-tel-input dom changes, etc
    // Hard to sync with the intl-tel-input API
    if (!inputRef.current && e) {
      inputRef.current = e;
      // @ts-ignore
      import("intl-tel-input/build/js/utils").then(() => {
        intlTelRef.current = intlTelInput(inputRef.current!, { autoPlaceholder: "aggressive", separateDialCode: true });
        inputRef.current!.addEventListener("countrychange", handleCountryCodeChange);
        register(name, { required: "Phone required", validate });
      });
    }
  };

  return <TextInput {...{ name, isInvalid }} ref={setRef} onChange={handlePhoneNumberChange} />;
};
