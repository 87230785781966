import React, { createContext, useContext, useState } from "react";
import useLocalState from "./useLocalState";

interface BreadCrumbProps {
  label: string;
  path: string;
}

interface LayoutContextT {
  isDrawerExpanded: boolean;
  setDrawerExpanded?(v: boolean): void;

  isStandalone: boolean;
  setStandalone?(v: boolean): void;

  breadCrumbs: BreadCrumbProps[];
  setBreadCrumbs?(v: BreadCrumbProps[]): void;

  pageTitle: string;
  pageSubtitle: string;

  setPageTitle?(v: string): void;
  setPageSubtitle?(v: string): void;
}

const defaultValues = {
  isDrawerExpanded: true,
  isStandalone: false,
  breadCrumbs: [],
  pageTitle: "Page Title",
  pageSubtitle: "Page Subtitle",
};

const LayoutContext = createContext<LayoutContextT>(defaultValues);

export const LayoutProvider: React.FC = ({ children }) => {
  const [isDrawerExpanded, setDrawerExpanded] = useLocalState<boolean>(
    "isDrawerExpanded",
    defaultValues.isDrawerExpanded,
  );

  const [isStandalone, setStandalone] = useState<boolean>(false);

  const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumbProps[]>(defaultValues.breadCrumbs);

  const [pageTitle, setPageTitle] = useState<string>(defaultValues.pageTitle);
  const [pageSubtitle, setPageSubtitle] = useState<string>(defaultValues.pageSubtitle);

  return (
    <LayoutContext.Provider
      value={{
        isDrawerExpanded,
        setDrawerExpanded,
        isStandalone,
        setStandalone,
        breadCrumbs,
        setBreadCrumbs,
        pageTitle,
        setPageTitle,
        pageSubtitle,
        setPageSubtitle,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default () => useContext(LayoutContext);
