import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Portal } from "@rmwc/base";

import useMultiTenant, { MultiTenantProvider } from "./hooks/useMultiTenant";

import { FirebaseProvider } from "./hooks/useFirebase";
import { AdminApiProvider } from "./hooks/useAdminApi";
import { dialogQueue } from "./hooks/useDialogQueue";
import { NotificationsProvider, snackbar } from "./hooks/useNotifications";

import { Routes } from "./Routes";

import { RMWCProvider } from "@rmwc/provider";
import { ThemeProvider as RMWCThemeProvider } from "@rmwc/theme";
import "@rmwc/theme/styles";

import { DialogQueue } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import { SnackbarQueue } from "@rmwc/snackbar";
import "@rmwc/snackbar/styles";

import { ThemeProvider } from "styled-components";

import AppDefaultTheme from "./theme.json";

import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import "./stylesheets/reset.css";
import "./stylesheets/index.css";

const TENANT_PREFIX = window.location.hostname.split(".")[0];

dayjs.extend(LocalizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localeData);

const AppComponent: React.FC = () => {
  const { tenantId, tenantInfo, theme } = useMultiTenant();

  useEffect(() => {
    if (!tenantInfo) return;
    window.document.title = tenantInfo.name.charAt(0).toUpperCase() + tenantInfo.name.slice(1);
  }, [tenantInfo]);

  if (!tenantId) return <></>;

  return (
    <FirebaseProvider {...{ tenantId }}>
      <ThemeProvider theme={theme}>
        <RMWCProvider icon={{ basename: "material-icons" }}>
          <RMWCThemeProvider options={{ ...AppDefaultTheme }}>
            <AdminApiProvider>
              <SnackbarQueue timeout={0} messages={snackbar.messages} />
              <DialogQueue preventOutsideDismiss dialogs={dialogQueue.dialogs} />

              <Router>
                <NotificationsProvider>
                  <Routes />
                </NotificationsProvider>
              </Router>

              <Portal />
            </AdminApiProvider>
          </RMWCThemeProvider>
        </RMWCProvider>
      </ThemeProvider>
    </FirebaseProvider>
  );
};

export const App: React.FC = () => (
  <MultiTenantProvider prefix={TENANT_PREFIX === "localhost" ? "foxtrot" : TENANT_PREFIX}>
    <AppComponent />
  </MultiTenantProvider>
);
