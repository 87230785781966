import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import { useFirebase } from "../../hooks/useFirebase";
import useLayout, { LayoutProvider } from "../../hooks/useLayout";
//import useNotifications from "../../hooks/useNotifications";

import { useAdminApi } from "../../hooks/useAdminApi";

import { Button } from "../Button";

import { NavigationDrawer } from "./NavigationDrawer";

import { Avatar } from "@rmwc/avatar";
import "@rmwc/avatar/styles";

import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";

import { MenuItem, MenuOnSelectEventT, SimpleMenu } from "@rmwc/menu";
import "@rmwc/menu/styles";

import { SimpleListItem } from "@rmwc/list";
import "@rmwc/list/styles";

import styled from "styled-components";

import Palette from "../../palette.json";

const Root = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
`;
const Main = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #fbfbfd;
`;
const Header = styled.div`
  padding: 10px 0;
  z-index: 5;

  display: flex;
  align-items: center;

  background-color: #ffffff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06);
`;
const BreadCrumbs = styled.ul`
  flex-grow: 1;
  margin-left: 64px;
  display: flex;

  > li {
    display: flex;
    align-items: center;

    margin: 0;

    a {
      font-size: 14px;
      font-weight: normal;
      color: ${Palette.MediumGrey};
      text-decoration: none;
    }
  }

  > li > .rmwc-icon {
    margin: 0 10px;
    font-weight: bold;
    color: ${Palette.Blue};
  }

  > li:last-of-type {
    a {
      font-weight: 500;
      color: ${Palette.Blue};
    }
  }
`;
const UserActions = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin: 0 8px;
  }

  .rmwc-avatar {
    margin: 0 14px 0 0;
    color: white;
    background-color: ${Palette.Blue};
  }
`;

const Container = styled.div`
  position: relative;
  flex-grow: 1;
  width: 100%;
`;
const Page = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

/* const NotificationMenu = styled(SimpleMenu)`
  min-width: 350px;

  &.mdc-menu-surface--open {
    button .rmwc-icon {
      background-color: black;
    }
  }

  .mdc-list-item {
    padding: 8px 30px;
    border-bottom: 1px solid #eaedf3;

    font-size: 14px;
    line-height: 22px;
    color: #3e3f42;

    .rmwc-icon {
      padding: 8px;
      border-radius: 4px;
      color: #6b6c6f;
      background-color: #eaedf3;
    }

    .mdc-list-item__primary-text {
      margin: -20px 0 -20px 0;
      height: auto;
    }

    .mdc-list-item__secondary-text {
      font-size: 12px;
      color: ${Palette.MediumGrey};
    }
  }
`;
const NotificationMenuTitle = styled(ListGroupSubheader)`
  margin: 0;
  padding: 18px 30px 24px;
  border-bottom: 1px solid #eaedf3;
  font-size: 18px;
  font-weight: 500;
  color: ${Palette.DarkGrey};
`;
 */
const LayoutComponent: React.FC = ({ children }) => {
  const history = useHistory();

  const { user, signOut } = useFirebase();
  const { me } = useAdminApi();

  const { isDrawerExpanded, setDrawerExpanded, isStandalone, breadCrumbs } = useLayout();
  // const { notifications } = useNotifications();

  const quickMenuItems = [
    {
      icon: "addchart",
      label: "Project",
      action: () => history.push("/projects/new"),
    },
    {
      icon: "person_add",
      label: "Customer",
      action: () => history.push("/clients/new"),
    },
    {
      icon: "person_add",
      label: "Technician",
      action: () => history.push("/people/new?role=technician"),
    },
    {
      icon: "person_add",
      label: "Manager",
      action: () => history.push("/people/new?role=manager"),
    },
  ];

  const userMenuItems = [
    {
      label: "Profile",
      action: () => history.push("/profile"),
    },
    {
      label: "Logout",
      action: () => signOut(),
    },
  ];

  /* const onNotificationItemSelected = (ev: MenuOnSelectEventT) => {
    console.info("***", notifications[ev.detail.index]);
  }; */

  const onAddNewUserItemSelected = (ev: MenuOnSelectEventT) => {
    quickMenuItems[ev.detail.index].action();
  };

  const onUserMenuItemSelected = (ev: MenuOnSelectEventT) => {
    userMenuItems[ev.detail.index].action();
  };

  if (isStandalone) {
    return <>{children}</>;
  }

  return (
    <Root>
      <NavigationDrawer expanded={isDrawerExpanded} setExpanded={setDrawerExpanded} />

      {me && (
        <Main>
          <Header>
            <BreadCrumbs>
              {breadCrumbs.map((it, i) => (
                <li key={i}>
                  <Link to={it.path}>{it.label}</Link>
                  {i < breadCrumbs.length - 1 && <Icon icon="chevron_right" />}
                </li>
              ))}
            </BreadCrumbs>

            <UserActions>
              <SimpleMenu
                anchorCorner="bottomStart"
                focusOnOpen={false}
                handle={
                  <Button outlined trailingIcon="unfold_more">
                    Create New
                  </Button>
                }
                onSelect={onAddNewUserItemSelected}
              >
                {quickMenuItems.map((it, i) => (
                  <SimpleListItem key={i} tabIndex={0} role="menuitem" graphic={it.icon}>
                    {it.label}
                  </SimpleListItem>
                ))}
              </SimpleMenu>

              {/* Hiding until notifications are enabled */}
              {/* <NotificationMenu
                anchorCorner="bottomStart"
                focusOnOpen={false}
                handle={
                  <BadgeAnchor>
                    <IconButton icon="notifications_none" style={{ color: "#9ea0a5" }} />
                    {notifications.length > 0 && (
                      <Badge
                        style={{ background: "#1665d8", color: "white" }}
                        inset="0.75rem"
                        label={notifications.length}
                      />
                    )}
                  </BadgeAnchor>
                }
                onSelect={onNotificationItemSelected}
              >
                <NotificationMenuTitle>Notifications</NotificationMenuTitle>

                {notifications.map((it, i) => (
                  <SimpleListItem
                    key={i}
                    role="menuitem"
                    tabIndex={0}
                    text={it.label}
                    graphic={{
                      icon: it.icon,
                      color: it.color,
                      basename: "material-icons-outlined",
                      style: {
                        color: it.color,
                        backgroundColor: it.color + "30",
                      },
                    }}
                  />
                ))}
              </NotificationMenu> */}

              <SimpleMenu
                anchorCorner="bottomStart"
                focusOnOpen={false}
                handle={
                  <Button trailingIcon="unfold_more">
                    {user?.displayName && <Avatar size="large" name={user?.displayName} />}
                    {user?.displayName || "Unknown User"}
                  </Button>
                }
                onSelect={onUserMenuItemSelected}
              >
                {userMenuItems.map((it, i) => (
                  <MenuItem key={i}>{it.label}</MenuItem>
                ))}
              </SimpleMenu>
            </UserActions>
          </Header>

          <Container>
            <Page>{children}</Page>
          </Container>
        </Main>
      )}
    </Root>
  );
};

export const Layout: React.FC = ({ children }) => {
  return (
    <LayoutProvider>
      <LayoutComponent>{children}</LayoutComponent>
    </LayoutProvider>
  );
};
