import React, { useEffect } from "react";

import { Controller } from "react-hook-form";

import { getUserAvatarColor } from "../../common";

import { TaskStatus } from "../../types/admin/globalTypes";

import { useAdminApi } from "../../hooks/useAdminApi";
import { useProject } from "../../hooks/params/useProject";
import { useTask } from "../../hooks/params/useTask";

import { sortByProperty } from "../../hooks/useSorted";

import { Button } from "../Button";
import { Label } from "../Label";
import { Select } from "../Select";
import { DateInput, TextInput } from "../TextInput";
import { FormField, FormRow } from "../CommonForm";
import { TaskFormContentProps } from "./TaskFormDialog";

import { Avatar, AvatarGroup } from "@rmwc/avatar";
import "@rmwc/avatar/styles";

import { MenuItem, MenuOnSelectEventT, SimpleMenu } from "@rmwc/menu";
import "@rmwc/menu/styles";

import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";

import styled from "styled-components";

const AvatarGroupContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row-reverse;
`;

/* const AttachmentUploadArea = styled.div`
  margin: 0 0 8px 0;
  padding: 16px 32px;

  display: flex;
  justify-content: space-around;

  font-size: 14px;
  font-weight: normal;

  border: 1px dashed #e2e5ed;
  border-radius: 4px;

  background-color: #ffffff;
  color: #9ea0a5;
`; */

export const TaskFormInfoContent: React.FC<TaskFormContentProps> = ({ form, style, setActiveTabIndex }) => {
  const { users } = useAdminApi();
  const project = useProject();
  const sorted = users
    .filter((it) => !!project?.users?.items.find((jt) => jt.userId === it.id))
    .sort(sortByProperty("name"));

  const task = useTask();

  const { control, formState, register, setValue, watch } = form;
  const { errors } = formState;

  const statusOpts = [
    TaskStatus.Requested,
    TaskStatus.Upcoming,
    TaskStatus.InProgress,
    TaskStatus.OnHold,
    TaskStatus.Declined,
    TaskStatus.Completed,
  ];

  const assignedTo = watch("assignedTo");

  useEffect(() => {
    const FIELDS = [
      "projectName",
      "parentName",
      "assignedTo",
      "taskNumber",
      "name",
      "status",
      "scheduledDate",
      "dueDate",
    ];

    if (FIELDS.filter((it) => it in errors).length > 0) setActiveTabIndex(0);
  }, [errors]);

  const onUserSelected = (ev: MenuOnSelectEventT) => {
    const user = sorted[ev.detail.index];
    if (!user) return;
    if (assignedTo.find((it) => it === user.id)) return;

    setValue("assignedTo", [...assignedTo, user.id], { shouldDirty: true });
  };

  return (
    <div {...{ style }}>
      <FormRow>
        <FormField style={{ flexGrow: 0 }}>
          <Label htmlFor="projectName">Project</Label>
          <TextInput disabled name="projectName" isInvalid={"projectName" in errors} ref={register} />
        </FormField>

        {task?.parentId !== task?.projectId && (
          <FormField>
            <Label htmlFor="parentName">Parent Task</Label>
            <TextInput disabled name="parentName" isInvalid={"parentName" in errors} ref={register} />
          </FormField>
        )}

        {task?.parentId === task?.projectId && (
          <FormField style={{ flexGrow: 1 }}>
            <Label htmlFor="assignedTo">
              Assigned To
              <AvatarGroupContainer>
                <AvatarGroup dense>
                  {assignedTo.map((it, i) => (
                    <Avatar
                      key={i}
                      size="xsmall"
                      onClick={() =>
                        setValue(
                          "assignedTo",
                          assignedTo.filter((jt) => jt !== it),
                        )
                      }
                      style={{
                        color: "white",
                        backgroundColor: getUserAvatarColor(it),
                      }}
                      name={users.find((jt) => jt.id === it)?.name || ""}
                    />
                  ))}
                </AvatarGroup>
              </AvatarGroupContainer>
            </Label>

            <Controller
              name="assignedTo"
              {...{ control }}
              render={() => (
                <SimpleMenu
                  focusOnOpen
                  anchorCorner="bottomStart"
                  handle={
                    <Button outlined trailingIcon="unfold_more" onClick={(ev) => ev.preventDefault()}>
                      Select User
                    </Button>
                  }
                  onSelect={onUserSelected}
                >
                  {sorted.map((it, i) => (
                    <MenuItem key={i}>{it.name}</MenuItem>
                  ))}
                </SimpleMenu>
              )}
            />
          </FormField>
        )}
      </FormRow>

      <FormRow>
        <FormField>
          <Label htmlFor="taskNumber">Task Number</Label>
          <TextInput name="taskNumber" isInvalid={"taskNumber" in errors} ref={register({ required: true })} />
        </FormField>

        <FormField>
          <Label htmlFor="name">Task Name</Label>
          <TextInput name="name" isInvalid={"name" in errors} ref={register({ required: true })} />
        </FormField>
      </FormRow>

      {/* 
      <FormRow>
        <FormField>
          <Label htmlFor="description">Description</Label>
          <TextInput name="description" ref={register} />
        </FormField>
      </FormRow>
      */}

      <FormRow>
        <FormField>
          <Label htmlFor="status">Status</Label>
          <Controller
            name="status"
            rules={{ required: true }}
            control={control}
            as={<Select enhanced options={statusOpts} />}
          />
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          <Label htmlFor="scheduledDate">Scheduled Date</Label>
          <DateInput name="scheduledDate" isInvalid={"scheduledDate" in errors} ref={register({ required: true })} />
          {/* <Controller
            name="scheduledDate"
            rules={{ required: true }}
            control={control}
            isInvalid={"scheduledDate" in errors}
            as={<TextField type="date" />}
          /> */}
        </FormField>

        <FormField>
          <Label htmlFor="dueDate">Due Date</Label>
          <DateInput name="dueDate" isInvalid={"dueDate" in errors} ref={register({ required: true })} />
          {/* <Controller
            name="dueDate"
            rules={{ required: true }}
            control={control}
            isInvalid={"dueDate" in errors}
            as={<TextField type="date" />}
          /> */}
        </FormField>
      </FormRow>

      {/* 
      <FormRow>
        <FormField>
          <Label htmlFor="attachments">Attachments</Label>

          <AttachmentUploadArea>
            <Icon icon="upload" />
            Drag and drop your files or click to upload
          </AttachmentUploadArea>
        </FormField>
      </FormRow>
      */}
    </div>
  );
};
