import { useParams } from "react-router";
import { useAdminApi } from "../useAdminApi";

interface ParamsT {
  locationId: string;
}
export const useLocationItem = () => {
  const params = useParams<ParamsT>();
  const { locationId } = params;

  const { locations } = useAdminApi();
  return locations.find((it) => it.id === locationId);
};
