import gql from "graphql-tag";

export const UserFields = gql`
  fragment UserFields on User {
    id

    clientId

    firstName
    lastName

    email
    phoneNumber

    name

    isRootAdministrator
    isSystemAdministrator

    clients {
      items {
        id
      }
    }

    payChex {
      id
      orgUnit
    }
  }
`;

export const getMeQuery = gql`
  ${UserFields}

  query GetMeQuery {
    asAdmin {
      getMe {
        ...UserFields
      }
    }
  }
`;

export const listUsersQuery = gql`
  ${UserFields}

  query ListUsers {
    asAdmin {
      listUsers {
        items {
          ...UserFields
        }
      }
    }
  }
`;

export const addUserMutation = gql`
  ${UserFields}

  mutation AddUser(
    $clientId: String = ""
    $projectId: String = ""
    $projectRole: ProjectRole = null
    $email: AWSEmail!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $isSystemAdministrator: Boolean = false
    $payChex: PayChexInput
  ) {
    asAdmin {
      addUser(
        input: {
          clientId: $clientId
          projectId: $projectId
          projectRole: $projectRole
          firstName: $firstName
          lastName: $lastName
          email: $email
          phoneNumber: $phoneNumber
          isSystemAdministrator: $isSystemAdministrator
          payChex: $payChex
        }
      ) {
        ...UserFields
      }
    }
  }
`;

export const updateUserMutation = gql`
  ${UserFields}

  mutation UpdateUser(
    $id: ID!
    $email: AWSEmail!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String
    $payChex: PayChexInput
  ) {
    asAdmin {
      updateUser(
        id: $id
        input: {
          firstName: $firstName
          lastName: $lastName
          email: $email
          phoneNumber: $phoneNumber
          payChex: $payChex
        }
      ) {
        ...UserFields
      }
    }
  }
`;

export const deleteUserMutation = gql`
  mutation DeleteUser($id: ID!) {
    asAdmin {
      deleteUser(id: $id) {
        id
      }
    }
  }
`;
