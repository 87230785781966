import React from "react";

import { useProject } from "../../../hooks/params/useProject";

import { ScheduleChart } from "../../../components/schedule/ScheduleChart";

export const ProjectSchedulePage: React.FC = () => {
  const project = useProject();

  if (!project) return <></>;

  return (
    <>
      <ScheduleChart />
    </>
  );
};
