import React, { useState } from "react";
import { useHistory } from "react-router";

import { AircraftFields } from "../../types/admin/AircraftFields";

import { useAdminApi } from "../../hooks/useAdminApi";
import { useClient } from "../../hooks/params/useClient";

import { dialogQueue } from "../../hooks/useDialogQueue";
import { snackbar } from "../../hooks/useNotifications";

import useFiltered from "../../hooks/useFiltered";
import { usePagenator } from "../../hooks/usePagenator";

import { Button } from "../Button";
import { SearchInput } from "../TextInput";

import { DataCellTools, Table, TableContainer, TableHead, TableTitle, TableToolbarTitle, TitleCount } from "../Table";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import Palette from "../../palette.json";
import { PagenatorControls } from "../PagenatorControls";

interface AircraftListProps {
  items: AircraftFields[];
}

export const AircraftList: React.FC<AircraftListProps> = ({ items }) => {
  const history = useHistory();

  const { deleteAircraft } = useAdminApi();
  const client = useClient();

  const [search, setSearch] = useState<string>("");
  const filtered = useFiltered(items, (it) => !!it.tailNumber?.toLowerCase().includes(search.toLowerCase()));

  const { pageRange, ...controls } = usePagenator(8, filtered.length);

  const onDelete = async (aircraft: AircraftFields) => {
    const hasConfirmed = await dialogQueue.confirm({
      title: "Are you sure?",
      body: `Are you sure you want to delete aircraft: ${aircraft.tailNumber}`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;

    await deleteAircraft({ id: aircraft.id });
    snackbar.notify({ title: "Aircraft removed successfully!" });
  };

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          Aircraft <TitleCount>({items.length})</TitleCount>
        </TableToolbarTitle>

        <SearchInput
          placeholder="Search Aircraft"
          value={search}
          onChange={(ev) => setSearch(ev.currentTarget.value)}
        />

        <Button raised onClick={() => history.push(`/clients/${client?.id}/aircraft/new`)}>
          Add Aircraft
        </Button>
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead cols={["Type", "Tail Number", "Serial Number", ""]} />

          <DataTableBody>
            {items.length === 0 && (
              <DataTableRow>
                <DataTableCell colSpan={4} style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}>
                  - No Aircraft -
                </DataTableCell>
              </DataTableRow>
            )}
            {filtered.slice(...pageRange).map((it, i) => (
              <DataTableRow key={i}>
                <DataTableCell>{it.type}</DataTableCell>
                <DataTableCell>{it.tailNumber}</DataTableCell>
                <DataTableCell>{it.serialNumber}</DataTableCell>
                <DataTableCell>
                  <DataCellTools>
                    <IconButton
                      icon="edit"
                      onClick={() => history.push(`/clients/${client?.id}/aircraft/${it.id}/edit`)}
                    />
                    <IconButton icon="delete" onClick={() => onDelete(it)} />
                  </DataCellTools>
                </DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </Table>

      <PagenatorControls {...controls} />
    </TableContainer>
  );
};
