import React, { useState } from "react";

import GoogleMapReact from "google-map-react";

import { LocationFields } from "../types/admin/LocationFields";

import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";

import styled from "styled-components";

import CONFIG from "../config";

const Root = styled.div`
  position: relative;

  flex-grow: 1;
`;

interface MapMarkerProps {
  lat?: number | null;
  lng?: number | null;

  onClick: () => void;
}

const MapMarker: React.FC<MapMarkerProps> = ({ onClick }) => {
  return (
    <Icon
      icon="place"
      style={{ color: "red", transform: "translate(-50%, -50%)", cursor: "pointer" }}
      onClick={onClick}
    />
  );
};

interface MapViewProps {
  searchRef?: React.RefObject<HTMLInputElement>;

  items: LocationFields[];

  center: {
    lat: number;
    lng: number;
  };

  zoom: number;

  setCenter?(location: { lat: number; lng: number }): void;

  onClick: (lat: number, lng: number, results: any[]) => void;
  onItemClick: (item?: LocationFields) => void;

  onGoogleApiLoaded?(p: any): void;
}

export const MapView: React.FC<MapViewProps> = ({
  searchRef,
  items,
  center,
  zoom,
  setCenter,
  onClick,
  onItemClick,
}) => {
  const [mapsApi, setMapsApi] = useState<any | null>(null);

  const filtered = items
    .filter((it) => it.geometry?.type === "Point" && it.geometry.coordinates[0] && it.geometry.coordinates[1])
    .map((it) => ({ id: it.id, lng: it.geometry?.coordinates[0] || 0, lat: it.geometry?.coordinates[1] || 0 }));

  const onApiLoaded = ({ maps }: any) => {
    setMapsApi(maps);
    if (searchRef?.current) {
      const searchBox = new maps.places.SearchBox(searchRef.current);
      searchBox.addListener("places_changed", () => {
        const [place] = searchBox.getPlaces();
        console.info(place);
        const { lat, lng } = place.geometry.location;
        if (setCenter) setCenter({ lat: lat(), lng: lng() });
      });
    }
  };

  const doClick = (ev: GoogleMapReact.ClickEventValue) => {
    const geocoder = new mapsApi.Geocoder();

    geocoder.geocode(
      {
        latLng: new mapsApi.LatLng(ev.lat, ev.lng),
      },
      (results: any, status: string) => {
        if (status !== "OK") return;

        onClick(
          ev.lat,
          ev.lng,
          results.filter((it: any) => it.types.includes("street_address")),
        );
      },
    );
  };

  return (
    <Root>
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{
          key: CONFIG.firebase.apiKey,
          libraries: ["places", "geocoder"],
        }}
        options={{ mapTypeId: "terrain" }}
        center={center}
        zoom={zoom}
        onClick={doClick}
        {...{ onGoogleApiLoaded: onApiLoaded }}
      >
        {filtered.map((it) => (
          <MapMarker
            key={it.id}
            lat={it.lat}
            lng={it.lng}
            onClick={() => onItemClick(items.find((jt) => jt.id === it.id))}
          />
        ))}
      </GoogleMapReact>
    </Root>
  );
};
