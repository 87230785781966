import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";

import { useForm } from "react-hook-form";

import { useAdminApi } from "../../hooks/useAdminApi";
import { useAircraft } from "../../hooks/params/useAircraft";

import { snackbar } from "../../hooks/useNotifications";

import { Label } from "../Label";
import { TextInput } from "../TextInput";

import { CommonForm, FormField, FormRow } from "../CommonForm";

import { Dialog } from "../Dialog";

import { DialogActions, DialogButton, DialogContent, DialogOnCloseEventT, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import Palette from "../../palette.json";
import { useClient } from "../../hooks/params/useClient";

interface FormValuesT {
  id?: string;
  clientId: string;

  type: string;
  serialNumber: string;
  tailNumber: string;
}

interface AircraftFormDialogProps {
  action: "create" | "update";
}

const HIDDEN_FIELDS = ["clientId"];

export const AircraftFormDialog: React.FC<AircraftFormDialogProps> = ({ action }) => {
  const history = useHistory();
  const { state: locationState } = useLocation<{ referrer: string }>();

  const { addAircraft, updateAircraft } = useAdminApi();
  const aircraft = useAircraft();
  const client = useClient();

  const form = useForm<FormValuesT>({
    mode: "all",
    criteriaMode: "all",
    defaultValues: {
      clientId: aircraft?.clientId || client?.id || "",
      type: aircraft?.type || "",
      serialNumber: aircraft?.serialNumber || "",
      tailNumber: aircraft?.tailNumber || "",
    },
  });

  const { formState, register, setValue } = form;
  const { isDirty, isValid } = formState;

  const title = action === "create" ? "Create New Aircraft" : "Update Aircraft";

  useEffect(() => {
    if (!setValue || !aircraft) return;

    setValue("clientId", client?.id);
    setValue("type", aircraft.type);
    setValue("serialNumber", aircraft.serialNumber);
    setValue("tailNumber", aircraft.tailNumber);
  }, [setValue, aircraft, client]);

  const onSubmit = form.handleSubmit(async (values) => {
    console.info("***", values);

    if (action === "create") {
      const aircraft = await addAircraft(values);
      snackbar.notify({ title: "Aircraft created successfully!" });

      if (aircraft && locationState?.referrer === "/projects/new") {
        history.replace(`/clients/${client?.id}/projects/new?aircraftId=${aircraft.id}`);
      }
    } else if (action === "update") {
      if (!aircraft) return;

      await updateAircraft({ id: aircraft.id, ...values });
      snackbar.notify({ title: "Aircraft updated successfully!" });
    }
  });

  const onClose = (ev: DialogOnCloseEventT) => {
    if (ev.detail.action === "accept") {
      onSubmit();
    }

    if (ev.detail.action === "close") {
      if (locationState?.referrer === "/projects/new") {
        history.replace(`/clients/${client?.id}/projects/new`);
        return;
      }
    }

    history.goBack();
  };

  return (
    <Dialog open preventOutsideDismiss onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <CommonForm onSubmit={onSubmit}>
          {HIDDEN_FIELDS.map((it, i) => (
            <input key={i} type="hidden" name={it} ref={register({ required: true })} />
          ))}
          <FormRow>
            <FormField>
              <Label htmlFor="type">Type</Label>
              <TextInput name="type" ref={register({ required: true })} />
            </FormField>
          </FormRow>

          <FormRow>
            <FormField>
              <Label htmlFor="serialNumber">Serial Number</Label>
              <TextInput name="serialNumber" ref={register({ required: true })} />
            </FormField>
          </FormRow>

          <FormRow>
            <FormField>
              <Label htmlFor="tailNumber">Tail Number</Label>
              <TextInput name="tailNumber" ref={register({ required: true })} />
            </FormField>
          </FormRow>
        </CommonForm>
      </DialogContent>

      <DialogActions>
        <DialogButton action="close" style={{ color: Palette.DarkGrey }}>
          Cancel
        </DialogButton>
        <div style={{ flexGrow: 1 }} />

        <DialogButton raised isDefaultAction action="accept" disabled={!isDirty || !isValid}>
          Save
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
