import gql from "graphql-tag";

export const AttachmentFields = gql`
  fragment AttachmentFields on Attachment {
    id

    entityId
    entityType

    fileName
    fileType

    mimeType

    file {
      url
    }
  }
`;

export const getUploadUrlQuery = gql`
  query GetUploadUrl($mimeType: String!) {
    asAdmin {
      getUploadUrl(mimeType: $mimeType) {
        attachmentId

        url

        region
        bucket
        key
      }
    }
  }
`;

export const addAttachmentMutation = gql`
  ${AttachmentFields}

  mutation AddAttachment($input: CreateAttachmentRequest!) {
    asAdmin {
      addAttachment(input: $input) {
        ...AttachmentFields
      }
    }
  }
`;

export const deleteAttachmentMutation = gql`
  mutation DeleteAttachment($id: ID!) {
    asAdmin {
      deleteAttachment(id: $id) {
        id
      }
    }
  }
`;
