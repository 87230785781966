import React, { useEffect } from "react";

import useLayout from "../../hooks/useLayout";
import { useAdminApi } from "../../hooks/useAdminApi";

import { PageHeader } from "../../components/PageHeader";
import { PageContent, PageMain } from "../../components/PageStyles";

import { ProjectList } from "../../components/projects/ProjectList";

/*
import styled from "styled-components";

import Palette from "../../palette.json";

const InsightBlock = styled.div`
  margin: 0 25px 42px;
  padding: 24px 30px;

  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  border: solid 1px #eaedf3;
  border-radius: 4px;

  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
`;
const InsightLabel = styled.h5`
  font-size: 16px;
  font-weight: 500;
  color: #3e3f42;
`;
const InsightValue = styled.span`
  font-size: 30px;
  line-height: 64px;
`;
 const InsightProperties = styled.div`
  display: flex;
`;
const InsightProperty = styled.div`
  display: flex;
  flex-direction: column;

  :first-of-type {
    flex-grow: 1;
  }

  :nth-of-type(2) {
    align-items: flex-end;

    ${InsightLabel} {
      font-size: 14px;
      font-weight: normal;
      color: #9ea0a5;
    }

    ${InsightValue} {
      font-size: 14px;
      font-weight: normal;
      color: ${Palette.Green};
    }
  }
`; */

export const DashboardPage: React.FC = () => {
  const { me, projects } = useAdminApi();
  const { setBreadCrumbs } = useLayout();

  /* const blocks = [
    { label: "Notifications", value: 5 },
    { label: "Customers", value: clients.length, progress: 0.3 },
    { label: "New Estimates", value: 0 },
    { label: "Revenue", value: "$13,893" },
    { label: "Today's Tasks", value: "10", progress: 0.2, secondaryLabel: "Completed", secondaryValue: "2" },
  ]; */

  useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([
      {
        label: "Dashboard",
        path: "/",
      },
    ]);
  }, [setBreadCrumbs]);

  return (
    <>
      <PageHeader title={`Welcome back, ${me?.firstName}`}>
        {/* Hiding until wired up with real data */}
        {/* {blocks.map((it, i) => (
          <InsightBlock key={i}>
            <InsightProperties>
              <InsightProperty>
                <InsightLabel>{it.label}</InsightLabel>
                <InsightValue>{it.value}</InsightValue>
              </InsightProperty>
              {it.secondaryLabel && (
                <InsightProperty>
                  <InsightLabel>{it.secondaryLabel}</InsightLabel>
                  <InsightValue>{it.secondaryValue}</InsightValue>
                </InsightProperty>
              )}
            </InsightProperties>
            {it.progress && <LinearProgress progress={it.progress} buffer={1} />}
          </InsightBlock>
        ))} */}
      </PageHeader>

      <PageMain>
        <PageContent>
          <ProjectList items={projects} />
        </PageContent>
      </PageMain>
    </>
  );
};
