import dayjs from "dayjs";
import numeral from "numeral";
import rand from "seedrandom";
import Palette from "../palette.json";
import { MarkupType, ProjectRole, TimeSheetEntryStatus } from "../types/admin/globalTypes";
import { TimesheetFields } from "../types/admin/TimesheetFields";

export const USER_ROLE_TO_LABEL = {
  [ProjectRole.technician]: "Technician",
  [ProjectRole.manager]: "Manager",
  [ProjectRole.customer]: "Customer",
};

const enumKeys = <T extends object, K extends keyof T = keyof T>(e: T): K[] =>
  Object.keys(e).filter((k) => Number.isNaN(+k)) as K[];

export const USER_ROLE_OPTIONS = enumKeys(ProjectRole).map((it) => ({
  label: USER_ROLE_TO_LABEL[ProjectRole[it]],
  value: ProjectRole[it],
}));

interface MarkupT {
  markupType?: MarkupType;
  markup?: string;
  quantity?: string;
  cost?: string;
}

export const calculateMarkupCost = (it: MarkupT) => {
  if (!it.markupType || !it.cost || !it.markup || !it.quantity) return 0.0;

  switch (it.markupType) {
    case MarkupType.dollar:
      return (parseFloat(it.cost) + parseFloat(it.markup)) * parseInt(it.quantity);
    case MarkupType.percentage:
      return parseFloat(it.cost) + parseFloat(it.cost) * (parseFloat(it.markup) / 100.0) * parseInt(it.quantity);
  }
};

export const formatPrice = (v: number) => {
  return "$" + numeral(v).format("0.00");
};

const COLORS: string[] = Object.keys(Palette).sort();

function isKeyof<T extends object>(obj: T, possibleKey: keyof any): possibleKey is keyof T {
  return possibleKey in obj;
}

export const getUserAvatarColor = (userId: string) => {
  const rng = rand(userId);
  const key = COLORS[Math.floor(rng.quick() * COLORS.length)];

  if (isKeyof(Palette, key)) {
    return Palette[key];
  }
  return "#ffffff";
};

export const generateTimesheetsCSV = (timesheets: TimesheetFields[]) => {
  const header =
    '"Employee Identifier","Punch 1 Type","Punch 1 Date","Punch 1 Time","Punch 2 Type","Punch 2 Date","Punch 2 Time","Org Unit"';

  const getDateTimeStrings = (dateTime: string) => {
    const parsed = dayjs(dateTime);
    const date = parsed.format("MM/DD/YYYY");
    const time = parsed.format("hh:mm A");
    return [date, time];
  };

  const download = (filename: string, text: string) => {
    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(text));
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const lines: string[] = [header];

  timesheets.forEach((sheet) => {
    const payChex = sheet.user?.payChex;

    if (sheet.status !== TimeSheetEntryStatus.Completed) {
      return;
    }

    const [fromDate, fromTime] = getDateTimeStrings(sheet.fromDT);
    const [toDate, toTime] = getDateTimeStrings(sheet.toDT);

    const line = `"${payChex?.id || ""}","Clock In","${fromDate}","${fromTime}","Clock Out","${toDate}","${toTime}","${
      payChex?.orgUnit || ""
    }"`;
    lines.push(line);
  });

  const text = lines.join("\r\n");
  download("foxtrot-timesheets.csv", text);
};
