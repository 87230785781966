import React, { useEffect } from "react";

import useLayout from "../../hooks/useLayout";

import { PageContent, PageHeading, PageMain } from "../../components/PageStyles";

import { ScheduleChart } from "../../components/schedule/ScheduleChart";

export const SchedulePage: React.FC = () => {
  const { setBreadCrumbs } = useLayout();

  useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([{ label: "Schedule", path: "/schedule" }]);
  }, [setBreadCrumbs]);

  return (
    <>
      <PageHeading>Schedule</PageHeading>

      <PageMain style={{ marginTop: "-54px" }}>
        <PageContent>
          <ScheduleChart />
        </PageContent>
      </PageMain>
    </>
  );
};
