import React, { useState } from "react";
import { useHistory } from "react-router";

import { EntityType, FileType } from "../../types/admin/globalTypes";

import { useAdminApi } from "../../hooks/useAdminApi";
import { useTask } from "../../hooks/params/useTask";
import { useFieldArray, useForm } from "react-hook-form";

import { Label } from "../Label";
import { TextInput } from "../TextInput";

import { Dialog } from "../Dialog";

import { CommonForm, FormField, FormRow } from "../CommonForm";

import { FileUploadInput } from "../FileUploadInput";

import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";

import { CircularProgress } from "@rmwc/circular-progress";
import "@rmwc/circular-progress/styles";

import { DialogActions, DialogButton, DialogContent, DialogOnCloseEventT, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import axios from "axios";
import numeral from "numeral";

import Palette from "../../palette.json";

const fileTypeFromMineType = (mimeType: string): FileType => {
  if (mimeType.toLowerCase().startsWith("video/")) {
    return FileType.Video;
  } else if (mimeType.toLowerCase().startsWith("image/")) {
    return FileType.Image;
  }

  return FileType.Document;
};

export interface FormValuesT {
  uploads: {
    fileName: string;
    fileSize: number;
    mimeType: string;
  }[];
}

export interface AttachmentFormDialogPropsT {
  action: "create" | "update";
}

const HIDDEN_FIELD_NAMES: string[] = [];

const UploadProgress: React.FC<{ progress: number }> = ({ progress }) => {
  return (
    <div
      style={{ width: "40px", height: "40px", display: "flex", justifyContent: "space-around", alignItems: "center" }}
    >
      {progress === 1 ? (
        <Icon style={{ width: "22px", color: Palette.Green }} icon="checkmark" />
      ) : (
        <CircularProgress {...{ progress }} />
      )}
    </div>
  );
};

export const AttachmentFormDialog: React.FC<AttachmentFormDialogPropsT> = ({ action }) => {
  const history = useHistory();

  const parentTask = useTask();

  const { addAttachment, getUploadUrl } = useAdminApi();

  const form = useForm<FormValuesT>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      uploads: [],
    },
  });

  const { control, formState, register } = form;
  const { errors } = formState;

  const uploads = useFieldArray({ control, name: "uploads" });
  const [progressItems, setProgressItems] = useState<{ fileName: string; progress: number }[]>([]);

  const title = action === "create" ? "Add Attachments" : "Update Attachment";

  const onUploadProgress = (fileName: string, loaded: number, total: number) => {
    const fieldIndex = progressItems.findIndex((it) => it.fileName === fileName);
    const progress = (1.0 / total) * loaded;
    console.info(fileName, fieldIndex, progress);
    setProgressItems((p) => p.map((it) => (it.fileName === fileName ? { fileName, progress } : it)));
  };

  const onFileSelected = (files: File[]) => {
    if (!parentTask) return;

    files.forEach(async (file) => {
      const upload = await getUploadUrl(file.type);
      console.info(upload);

      uploads.append({ fileName: file.name, fileSize: file.size, mimeType: file.type });
      setProgressItems((p) => [...p, { fileName: file.name, progress: 0 }]);

      // Debounce for above state refresh ...
      setTimeout(() => {
        axios.put(upload.url, file, {
          headers: { "content-type": file.type },
          onUploadProgress: (ev: ProgressEvent) => onUploadProgress(file.name, ev.loaded, ev.total),
        });
      }, 500);

      const response = await addAttachment({
        input: {
          id: upload.attachmentId,
          entityId: parentTask.id,
          entityType: EntityType.TASK,
          fileName: file.name,
          fileType: fileTypeFromMineType(file.type),
          mimeType: file.type,
          file: {
            bucket: upload.bucket,
            key: upload.key,
            region: upload.region,
          },
        },
      });
      console.info(response);
    });
  };

  const onSubmit = form.handleSubmit(async (values) => {
    console.info("***", values);
  });

  const onClose = (ev: DialogOnCloseEventT) => {
    console.info(ev);
    history.goBack();
  };

  return (
    <Dialog open preventOutsideDismiss onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <CommonForm {...{ onSubmit }}>
          {HIDDEN_FIELD_NAMES.map((it, i) => (
            <input key={i} type="hidden" name={it} ref={register} />
          ))}

          {uploads.fields.map(({ fileName, fileSize, mimeType }, i) => (
            <FormRow key={i}>
              <FormField>
                <Label htmlFor={`uploads.${i}.fileName`}>File name</Label>
                <TextInput
                  disabled
                  name={`uploads.${i}.fileName`}
                  defaultValue={fileName}
                  ref={register({ required: true })}
                />
              </FormField>

              <FormField>
                <Label>Size</Label>
                <TextInput disabled value={numeral(fileSize).format("0.00b")} style={{ width: "5rem" }} />
              </FormField>

              <FormField>
                <Label htmlFor={`uploads.${i}.mimeType`}>Type</Label>
                <TextInput
                  disabled
                  name={`uploads.${i}.mimeType`}
                  defaultValue={mimeType}
                  ref={register({ required: true })}
                />
              </FormField>

              <UploadProgress progress={progressItems.find((it) => it.fileName === fileName)?.progress || 0} />
            </FormRow>
          ))}

          <FileUploadInput onChange={onFileSelected} />
        </CommonForm>
      </DialogContent>

      <DialogActions>
        <div style={{ flexGrow: 1 }} />

        <DialogButton isDefaultAction disabled={!!progressItems.find((it) => it.progress !== 1)} action="accept">
          Close
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
