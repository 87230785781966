import React from "react";
import { NavLink } from "react-router-dom";

import { Drawer, DrawerContent, DrawerHeader } from "@rmwc/drawer";
import "@rmwc/drawer/dist/styles";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import { List, ListGroupSubheader, SimpleListItem } from "@rmwc/list";
import "@rmwc/list/styles";

import styled, { css } from "styled-components";

import RbrAviationLogo from "../../assets/images/rbr_aviation_logo.png";
import useMultiTenant from "../../hooks/useMultiTenant";

const Link = styled(NavLink)`
  text-decoration: none;
`;

const Container = styled.div<{ expanded: boolean; logoUrl: string }>`
  min-width: 300px;
  width: 300px;
  height: 100vh;

  ${(p) =>
    !p.expanded &&
    css`
      min-width: 100px;
      width: 100px;
    `}

  .mdc-drawer {
    z-index: 10;
    background-color: ${(p) => p.theme.background};

    min-width: 300px;
    width: 300px;

    height: 100vh;

    ${(p) =>
      !p.expanded &&
      css`
        min-width: 100px;
        width: 100px;
      `}

    overflow: hidden;
    transition: width 0.2s;

    .mdc-drawer__header {
      position: relative;

      padding: 72px 32px 0 32px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      position: relative;

      padding-top: 72px;
      transition: padding-top 120ms;

      ${(p) =>
        p.expanded &&
        css`
          padding-top: 200px !important;
        `}

      :before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        content: " ";

        opacity: 0;
        transition: opacity 600ms;

        ${(p) =>
          p.expanded &&
          css`
            opacity: 1;
            background-image: url(${p.logoUrl});
            background-size: 200px;
            background-repeat: no-repeat;
            background-position-x: 50%;
            background-position-y: 100%;
          `}
      }

      ${(p) =>
        !p.expanded &&
        css`
          align-items: center;
        `}

      > .mdc-icon-button {
        position: absolute;
        top: 0;

        padding: 20px 16px;

        ${(p) =>
          p.expanded &&
          css`
            right: 0;
            padding: 20px 16px 0 0;
          `}
        color: white;
      }

      > .mdc-drawer__title {
        margin: 0;
        font-size: 36px;
        color: white;
      }

      > .mdc-drawer__subtitle {
        font-size: 28px;
        font-style: italic;
        color: ${(p) => p.theme.secondary};
      }
    }

    .mdc-list-group__subheader {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      color: white;
      opacity: 0.6;
    }

    .mdc-list-item {
      margin: 0;
      padding: 12px 36px;

      height: auto;

      font-size: 14px;
      color: rgba(255, 255, 255, 0.6) !important;

      border-left: 3px solid transparent;
      border-radius: 0;

      .mdc-list-item__graphic {
        color: inherit;
      }

      .mdc-list-item__meta {
        color: inherit;
      }
    }

    ${Link}.active {
      .mdc-list-item {
        color: rgba(255, 255, 255, 1) !important;
        background-color: rgba(255, 255, 255, 0.08);
        border-color: ${(p) => p.theme.primary};
      }
    }
  }
`;

export interface NavigationDrawerProps {
  expanded: boolean;
  setExpanded?(v: boolean): void;
}
export const NavigationDrawer: React.FC<NavigationDrawerProps> = ({ expanded, setExpanded }) => {
  const { tenantInfo } = useMultiTenant();

  const logoUrl = tenantInfo?.logoImageUrl || RbrAviationLogo;

  const items: {
    icon: string;
    label: string;
    path: string;
    meta?: string;
  }[] = [
    {
      icon: "home",
      label: "Dashboard",
      // meta: "expand_more",
      path: "/projects",
    },
    {
      icon: "contact_page",
      label: "Clients",
      path: "/clients",
    },
    {
      icon: "group",
      label: "Schedule",
      path: "/schedule",
    },
    {
      icon: "today",
      label: "Timesheets",
      path: "/timesheets",
    },
    {
      icon: "contact_page",
      label: "People",
      path: "/people",
    },
    {
      icon: "place",
      label: "Locations",
      path: "/locations",
    },
    // {
    //   icon: "assessment",
    //   label: "Reports",
    //   path: "/reports",
    // },
  ];

  return (
    <Container {...{ expanded, logoUrl }}>
      <Drawer dismissible open data-test-id="main-navigation">
        <DrawerHeader>
          <IconButton icon="menu" onClick={() => setExpanded && setExpanded(!expanded)} />
        </DrawerHeader>

        <DrawerContent>
          <List>
            {expanded && <ListGroupSubheader>Main Menu</ListGroupSubheader>}

            {items.map((it, i) => (
              <Link strict key={i} to={it.path}>
                <SimpleListItem graphic={it.icon} metaIcon={expanded && it.meta} text={expanded && it.label} />
              </Link>
            ))}
          </List>
        </DrawerContent>
      </Drawer>
    </Container>
  );
};
