import React, { useEffect } from "react";
import { Route } from "react-router";

import useLayout from "../../hooks/useLayout";

import { useAdminApi } from "../../hooks/useAdminApi";

import { PageHeader } from "../../components/PageHeader";
import { PageContent, PageMain } from "../../components/PageStyles";

import { UserFormDialog } from "../../components/users/UserFormDialog";
import { UserList } from "../../components/users/UserList";

export const PeoplePage: React.FC = () => {
  const { users } = useAdminApi();

  const { setBreadCrumbs } = useLayout();

  useEffect(() => {
    if (!setBreadCrumbs) return;

    setBreadCrumbs([{ label: "People", path: "/people" }]);
  }, [setBreadCrumbs]);

  return (
    <>
      <Route exact path="/people/new">
        <UserFormDialog action="create" />
      </Route>

      <Route exact path="/people/:userId/edit">
        <UserFormDialog action="update" />
      </Route>

      <PageHeader title="People" />

      <PageMain style={{ paddingTop: "32px" }}>
        <PageContent>
          <UserList items={users} />
        </PageContent>
      </PageMain>
    </>
  );
};
