import React from "react";
import { useHistory } from "react-router";

import { MessageFields } from "../../types/admin/MessageFields";

import { useAdminApi } from "../../hooks/useAdminApi";
import { useProject } from "../../hooks/params/useProject";
import { useTask } from "../../hooks/params/useTask";

import { Button } from "../Button";

import { Table, TableContainer, TableHead, TableTitle, TableToolbarTitle, TitleCount } from "../Table";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import dayjs from "dayjs";

import Palette from "../../palette.json";

export interface MessageListPropsT {
  items: MessageFields[];
}

export const MessageList: React.FC<MessageListPropsT> = ({ items }) => {
  const history = useHistory();

  const { users } = useAdminApi();

  const project = useProject();
  const parentTask = useTask();

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          Messages <TitleCount>({parentTask?.messages?.items.length})</TitleCount>
        </TableToolbarTitle>

        <Button raised onClick={() => history.push(`/projects/${project?.id}/tasks/${parentTask?.id}/messages/new`)}>
          New Message
        </Button>
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead cols={["User", "Body", "Posted At"]} />

          <DataTableBody>
            {items.length === 0 && (
              <DataTableRow>
                <DataTableCell colSpan={3} style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}>
                  - No Messages -
                </DataTableCell>
              </DataTableRow>
            )}

            {items.map((it, i) => (
              <DataTableRow key={i}>
                <DataTableCell>{users.find((jt) => jt.id === it.userId)?.name}</DataTableCell>
                <DataTableCell>{it.message}</DataTableCell>
                <DataTableCell>{dayjs(it.postedAt).format("LLL")}</DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
