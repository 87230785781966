import React, { useEffect } from "react";

import { Controller } from "react-hook-form";

import { BillingType } from "../../types/admin/globalTypes";

import { TaskFormContentProps } from "./TaskFormDialog";

import { Label } from "../Label";
import { Select } from "../Select";
import { TextInput } from "../TextInput";

import { FormField, FormRow } from "../CommonForm";

export const TaskFormOptionsContent: React.FC<TaskFormContentProps> = ({ form, style, setActiveTabIndex }) => {
  const { control, formState, register } = form;
  const { errors } = formState;

  const billingTypeOpts = [BillingType.Fixed, BillingType.TANDM, BillingType.TBD];

  useEffect(() => {
    const FIELDS = ["billing", "amount", "estimateManHrs", "hourlyRate"];

    if (FIELDS.filter((it) => it in errors).length > 0) setActiveTabIndex(1);
  }, [errors]);

  return (
    <div {...{ style }}>
      {/* <FormRow>
        <FormField>
          <Label htmlFor="status">Status</Label>
          <Controller
            name="status"
            rules={{ required: true }}
            control={control}
            as={<Select enhanced options={statusOpts} />}
          />
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          <Label htmlFor="scheduledDate">Scheduled Date</Label>
          <Controller
            name="scheduledDate"
            rules={{ required: true }}
            control={control}
            as={<TextField type="date" />}
          />
        </FormField>

        <FormField>
          <Label htmlFor="dueDate">Due Date</Label>
          <Controller name="dueDate" rules={{ required: true }} control={control} as={<TextField type="date" />} />
        </FormField>
      </FormRow> */}

      <FormRow>
        <FormField>
          <Label htmlFor="billing">Billing Type</Label>
          <Controller
            name="billing"
            control={control}
            rules={{ required: true }}
            as={<Select enhanced options={billingTypeOpts} />}
          />
        </FormField>

        <FormField style={{ flexGrow: 0 }}>
          <Label htmlFor="amount">Billing Amount</Label>
          <TextInput name="amount" isInvalid={"amount" in errors} ref={register({ required: true })} />
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          <Label htmlFor="estimateManHrs">Est. Hours</Label>
          <TextInput name="estimateManHrs" isInvalid={"estimateManHrs" in errors} ref={register({ required: true })} />
        </FormField>
        <FormField>
          <Label htmlFor="hourlyRate">Hourly Rate</Label>
          <TextInput name="hourlyRate" isInvalid={"hourlyRate" in errors} ref={register({ required: true })} />
        </FormField>
        {/*         <FormField style={{ flexGrow: 1 }}>
          <Label>&nbsp;</Label>
          <div onClick={() => setValue("inReview", !inReview)}>
            <Controller
              name="inReview"
              control={control}
              render={() => <Switch checked={inReview} label="In Review" />}
            />
          </div>
        </FormField>
 */}{" "}
      </FormRow>
    </div>
  );
};
