import gql from "graphql-tag";

export const ClientFields = gql`
  fragment ClientFields on Client {
    id
    clientType
    name
    address
  }
`;

export const listClientsQuery = gql`
  ${ClientFields}

  query ListClients {
    asAdmin {
      listClients {
        items {
          ...ClientFields
        }
      }
    }
  }
`;

export const addClientMutation = gql`
  ${ClientFields}

  mutation AddClient($name: String = "", $address: String = "", $clientType: ClientType = Company) {
    asAdmin {
      addClient(input: { clientType: $clientType, name: $name, address: $address }) {
        ...ClientFields
      }
    }
  }
`;

export const updateClientMutation = gql`
  ${ClientFields}

  mutation UpdateClient($id: ID!, $address: String!, $clientType: ClientType!, $name: String!) {
    asAdmin {
      updateClient(id: $id, input: { address: $address, clientType: $clientType, name: $name }) {
        ...ClientFields
      }
    }
  }
`;

export const deleteClientMutation = gql`
  mutation DeleteClient($id: ID!) {
    asAdmin {
      deleteClient(id: $id) {
        id
      }
    }
  }
`;
