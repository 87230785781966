import React, { useState } from "react";
import { useHistory } from "react-router";

import { dialogQueue } from "../../hooks/useDialogQueue";
import { snackbar } from "../../hooks/useNotifications";

import { AttachmentFields } from "../../types/admin/AttachmentFields";

import { useAdminApi } from "../../hooks/useAdminApi";
import { useProject } from "../../hooks/params/useProject";
import { useTask } from "../../hooks/params/useTask";

import useSorted, { sortByProperty } from "../../hooks/useSorted";
import useFiltered from "../../hooks/useFiltered";

import { Button } from "../Button";
import { SearchInput } from "../TextInput";

import { DataCellTools, Table, TableContainer, TableHead, TableTitle, TableToolbarTitle, TitleCount } from "../Table";

import { Icon } from "@rmwc/icon";
import "@rmwc/icon/styles";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import Palette from "../../palette.json";

const iconFromMimeType = (mimeType: string): string => {
  if (mimeType.toLowerCase().startsWith("image/")) {
    return "image";
  } else if (mimeType.toLowerCase().startsWith("video/")) {
    return "video";
  }

  return "description";
};

export interface AttachmentListPropsT {
  items: AttachmentFields[];
}

export const AttachmentList: React.FC<AttachmentListPropsT> = ({ items }) => {
  const history = useHistory();

  const { deleteAttachment } = useAdminApi();

  const project = useProject();
  const parentTask = useTask();

  const [search, setSearch] = useState<string>("");

  const sorted = useSorted<AttachmentFields>(items || [], sortByProperty("name"));
  const filtered = useFiltered(sorted, (it) => !!it?.fileName?.toLowerCase().includes(search.toLowerCase()));

  const onDelete = async (item: AttachmentFields) => {
    const hasConfirmed = await dialogQueue.confirm({
      title: "Are you sure?",
      body: `Are you sure you want to delete attachment: ${item.fileName}`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;
    await deleteAttachment(item);
    snackbar.notify({ title: "Attachment removed successfully!" });
  };

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          Attachments <TitleCount>({parentTask?.attachments?.items.length})</TitleCount>
        </TableToolbarTitle>

        <SearchInput
          placeholder="Search by name or type"
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
        />

        <Button raised onClick={() => history.push(`/projects/${project?.id}/tasks/${parentTask?.id}/attachments/new`)}>
          Add Attachment
        </Button>
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead cols={["", "Name", ""]} />

          <DataTableBody>
            {filtered.length === 0 && (
              <DataTableRow>
                <DataTableCell colSpan={3} style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}>
                  - No Attachments -
                </DataTableCell>
              </DataTableRow>
            )}

            {filtered.map((it, i) => (
              <DataTableRow key={i}>
                <DataTableCell style={{ width: "4rem" }}>
                  <Icon style={{ color: Palette.LightGrey }} icon={iconFromMimeType(it.mimeType)} />
                </DataTableCell>

                <DataTableCell>
                  <a href={it.file.url || undefined} target="_blank" rel="noopener noreferrer">
                    {it.fileName}
                  </a>
                </DataTableCell>
                <DataTableCell>
                  <DataCellTools>
                    <IconButton icon="delete" onClick={() => onDelete(it)} />
                  </DataCellTools>
                </DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </Table>
    </TableContainer>
  );
};
