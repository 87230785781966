import React, { useState } from "react";
import { useHistory } from "react-router";

import { TaskStatus } from "../../types/admin/globalTypes";
import { ProjectFields } from "../../types/admin/ProjectFields";

import { useAdminApi } from "../../hooks/useAdminApi";
import { useClient } from "../../hooks/params/useClient";

import { dialogQueue } from "../../hooks/useDialogQueue";
import { snackbar } from "../../hooks/useNotifications";

import useFiltered from "../../hooks/useFiltered";
import { usePagenator } from "../../hooks/usePagenator";
import useSorted, { sortByProperty } from "../../hooks/useSorted";

import { Button } from "../../components/Button";
import { TaskStatusLabel } from "../../components/StatusLabel";

import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";

import {
  DataCellTools,
  Table,
  TableContainer,
  TableHead,
  TableTitle,
  TableToolbarTitle,
  TitleCount,
} from "../../components/Table";

import { PagenatorControls } from "../PagenatorControls";

import { MenuItem, SimpleMenu } from "@rmwc/menu";
import "@rmwc/menu/styles";

import { DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "@rmwc/data-table";
import "@rmwc/data-table/styles";

import dayjs from "dayjs";

import Palette from "../../palette.json";
import { TextInput } from "../TextInput";
import { DeleteProjectVariables } from "../../types/admin/DeleteProject";

interface ProjectListProps {
  items: ProjectFields[];

  hiddenFields?: string[];
}

const COLUMN_LABELS = ["Project Name", "Customer", "Progress", "Hours", "Status", "Due Date", ""];

export const ProjectList: React.FC<ProjectListProps> = ({ items, hiddenFields = [] }) => {
  const history = useHistory();

  const { me } = useAdminApi();

  const { clients, tasks, deleteProject } = useAdminApi();
  const client = useClient();

  const [searchString, setSearchString] = useState<string>("");

  const sorted = useSorted(items, sortByProperty("name"));

  const [filter, setFilter] = useState<number>(0);
  const filtered = useFiltered(sorted, (it) => {
    if (searchString.length > 0) {
      const clientName = clients.find((jt) => it.clientId === jt.id)?.name;
      console.info(clientName);

      if (
        !it.name.toLowerCase().startsWith(searchString.toLowerCase()) &&
        !clientName?.toLowerCase().startsWith(searchString.toLowerCase())
      )
        return false;
    }

    if (filter === 1) {
      return !!it.users?.items.find((jt) => jt.userId === me?.id);
    }

    return true;
  });

  const { pageRange, ...controls } = usePagenator(6, filtered.length);

  const FILTER_OPTS = ["All", "Owned by Me"];

  const onNewProject = () => {
    if (!client) return history.push("/projects/new");
    history.push(`/clients/${client.id}/projects/new`);
  };

  const onDelete = async (project: ProjectFields) => {
    let hasConfirmed = false;

    hasConfirmed = await dialogQueue.confirm({
      title: "Are you sure?",
      body: `Are you sure you want to delete project: ${project.name}`,
      acceptLabel: "Confirm",
      cancelLabel: "Cancel",
    });

    if (!hasConfirmed) return;

    await deleteProject(project as DeleteProjectVariables);
    snackbar.notify({ title: "Project removed successfully!" });
  };

  const onEdit = async (project: ProjectFields) => {
    history.push(`/projects/${project.id}/edit`);
  };

  return (
    <TableContainer>
      <TableTitle>
        <TableToolbarTitle>
          {filter === 1 ? "My Projects" : "All Projects"} <TitleCount>({filtered.length})</TitleCount>
        </TableToolbarTitle>

        <TextInput
          placeholder={`Search by Name${!client ? " or Customer" : ""}`}
          value={searchString}
          onChange={(ev) => setSearchString(ev.target.value)}
        />

        <SimpleMenu
          anchorCorner="bottomStart"
          focusOnOpen={false}
          handle={
            <Button outlined trailingIcon="unfold_more">
              {FILTER_OPTS[filter]}
            </Button>
          }
          onSelect={(ev) => setFilter(ev.detail.index)}
        >
          {FILTER_OPTS.map((it, i) => (
            <MenuItem key={i}>{it}</MenuItem>
          ))}
        </SimpleMenu>

        <Button raised onClick={onNewProject}>
          New Project
        </Button>
      </TableTitle>

      <Table>
        <DataTableContent>
          <TableHead cols={COLUMN_LABELS.filter((it) => !hiddenFields.includes(it))} />

          <DataTableBody>
            {filtered.length === 0 && (
              <DataTableRow>
                <DataTableCell
                  colSpan={COLUMN_LABELS.length - hiddenFields.length}
                  style={{ color: Palette.LightGrey, fontSize: "18px", textAlign: "center" }}
                >
                  - No Projects -
                </DataTableCell>
              </DataTableRow>
            )}
            {filtered.slice(...pageRange).map((it, i) => (
              <DataTableRow key={i} onClick={() => history.push(`/projects/${it.id}`)}>
                {!hiddenFields.includes("Project Name") && <DataTableCell>{it.name}</DataTableCell>}
                {!hiddenFields.includes("Customer") && (
                  <DataTableCell>{clients.find((jt) => jt.id === it.clientId)?.name}</DataTableCell>
                )}
                {!hiddenFields.includes("Progress") && (
                  <DataTableCell>{it.progress?.progressTimePercentage?.toFixed(2)}%</DataTableCell>
                )}
                {!hiddenFields.includes("Status") && (
                  <DataTableCell>
                    <TaskStatusLabel
                      status={
                        tasks.find((jt) => jt.parentId === it.id && jt.status !== TaskStatus.Completed)
                          ? TaskStatus.InProgress
                          : TaskStatus.Completed
                      }
                    >
                      {tasks.find((jt) => jt.parentId === it.id && jt.status !== TaskStatus.Completed)
                        ? "In Progress"
                        : "Complete"}
                    </TaskStatusLabel>
                  </DataTableCell>
                )}
                {!hiddenFields.includes("Due Date") && <DataTableCell>{dayjs(it.endDate).format("l")}</DataTableCell>}
                <DataTableCell>
                  <DataCellTools>
                    <IconButton
                      icon="edit"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        onEdit(it);
                      }}
                    />
                    <IconButton
                      icon="delete"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        onDelete(it);
                      }}
                    />
                  </DataCellTools>
                </DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </Table>

      <PagenatorControls {...controls} />
    </TableContainer>
  );
};
